/* eslint-disable global-require */

const localeData = [
  ...require('react-intl/locale-data/en'),
  ...require('react-intl/locale-data/zh'),
  ...require('react-intl/locale-data/ko'),
  ...require('react-intl/locale-data/ja'),
]

module.exports = {
  localeData,
  languages: [
    { value: 'en-us', text: 'English' },
    { value: 'zh-cn', text: '中文' },
    { value: 'ko-kr', text: '한국어' },
    { value: 'ja-jp', text: '日本語' },
  ],
}
