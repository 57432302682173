module.exports = {
  title: 'CyberMiles App',
  'meta-description': 'Securely manage your digital assets with the CyberMiles mobile blockchain wallet. Store and trade multiple currencies, receive real-time market updates.',
  banner: {
    title: 'CMTウォレット',
    backgroundImage: 'bannerCMT',
    description: 'デジタル資産ウォレット',
    buttons: {
      number: '0',
    },
  },
  imageTextHero: {
    section0: {
      image: {
        gif: 'null',
        path: 'cmtWalletOne',
      },
      title: 'CyberMiles提供のモバイルブロックチェーンウォレット',
      description: {
        richtext: 'null',
        description: '次の方法で入手できます',
      },
      backgroundImage: 'null',
      imageButtons: {
        number: '2',
        button0: {
          icon: 'appStore',
          link: 'https://itunes.apple.com/us/app/id1418378426',
        },
        button1: {
          icon: 'googlePlay',
          link: 'https://play.google.com/store/apps/details?id=io.cybermiles.cmtwallet',
        },
        // button2: {
        //   icon: 'walletQR',
        //   link: '/',
        // },
      },
      buttons: {
        number: '2',
        button0: {
          label: 'Android Download',
          link: 'https://s3.cn-north-1.amazonaws.com.cn/cmtwallet/android/CMT-Wallet-Android.apk',
        },
        button1: {
          label: 'IOS Download',
          link: 'itms-services://?action=download-manifest&url=https://s3.cn-north-1.amazonaws.com.cn/cmtwallet/ios/manifest.plist',
        },
      },
    },
  },
  imageText: {
    section0: {
      image: {
        gif: 'null',
        path: 'cmtWalletTwo',
      },
      title: '安全、便利かつ強力',
      description: {
        richtext: 'null',
        description:
          'CyberMiles AppはCyberMilesモバイルウォレットで、シンプルで安全、そして強力なデジタル資産管理ツールをユーザーに提供します。ユーザーは、ウォレットの作成/インポート、安全なデジタル資産管理、便利なトランザクション実行、リアルタイムの相場確認、そしてCyberMiles Appを使用したCyberMiles DAppsの可能性を自由に体験することができます。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  features: {
    number: '1',
    columns0: {
      title: 'メリットと基本性能',
      description: {
        richText: 'null',
        description:
          'null',
      },
      number: '4',
      columns: '2',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresKey',
            link: 'null',
          },
        },
        title: 'プライベートキーを自己管理し、セキュリティを強化',
        description: {
          richText: 'null',
          description:
            '暗号化されたプライベートキーは、デバイスのローカルサンドボックスシステムに安全に保存されます。資産の紛失や盗難を防止するために、いくつかのウォレットのバックアップ方法が提供されます。',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresPhone',
            link: 'null',
          },
        },
        title: 'シンプルに取引可能',
        description: {
          richText: 'null',
          description: 'ブロックチェーンと同期せずトランザクションを実行します。 使いやすいインターフェースを楽しんでください。',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresWorld',
            link: 'null',
          },
        },
        title: '強力なDAppブラウザ',
        description: {
          richText: 'null',
          description:
            'スマートコントラクトで構築された非中央集権/分散化アプリケーションのブラウザ。BlockTonic/ Tokeneed/ TCandy/ CMTなどのDAppsをサポートします。',
        },
        buttons: {
          number: '0',
        },
      },
      column3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresLock',
            link: 'null',
          },
        },
        title: 'ワンストップでのデジタル資産管理',
        description: {
          richText: 'null',
          description:
            'CMTなどを含むカスタマイズできるデジタル資産リスト。CMTのリアルタイムの市場価格と資産情報の更新。imToken、JAXX、Metamask、CMT CubeからWalletをインポートできます。また、Coinbase、Binance、Huobi、OKex、Bittrex、Kraken、Poloniexなどの世界で大手の取引所と同期した価格情報を得ます。',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: 'CMT CyberMiles Token',
      description: {
        richText: 'true',
        description:
          'CMTの主な目的の1つは、CyberMilesネットワークを維持するために参加者が協力できるようにすることです。 CyberMilesのブロックチェーンは特にeコマースアプリケーション向けに最適化され、CMTには取引決済の機能もあります。CMTは、Huobi、Okex、Binance、その他多くの主要取引所で取引できます。',
      },
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '1',
        button0: {
          link: '/cmt/overview/',
          label: '詳しくみる',
          icon: 'null',
        },
      },
    },
  },
}
