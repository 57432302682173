module.exports = {
  title: 'Marketing Referral Program',
  'meta-description': 'Marketing Referral Program',
  banner: {
    title: 'マーケティング紹介プログラム',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '従来のeコマースでの機会',
      description: {
        richText: 'true',
        description: `<p>口コミマーケティングは最も効果的なマーケティング戦略の1つです。 SNSを利用することで、DropboxやAirbnbのような多くの代表的企業は紹介キャンペーンで急成長を遂げました。</p>
          <p>すでに実績のあるマーケティング戦略にブロックチェーンの機能を追加することで、紹介プログラムは次のレベルに進みます。インセンティブ報酬がブロックチェーンベースのトークンで支払われると、ネットワーク所有権が効果を発揮し、二重の経済的インセンティブを得ることできます。</p>
         `,
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMilesの解決策',
      description: {
        richText: 'true',
        description: `<p>ブロックチェーンベースのビジネスでも、従来のWebベースのアプリケーションでも、CyberMilesのエンタープライズクラスおよび専門家が提供するDPOSブロックチェーンとスマートコントラクトの豊富なライブラリを活用して、独自の紹介プログラムを簡単に作成して公開することができます。</p>
        <p>ユーザーがあなたの商品やサービスを紹介するときは、追加のトークンを受け取るだけでなく、受け取ったトークンへの認識を高め広めることで、以前に受け取ったトークンの価値も上がるでしょう。この二重の経済的インセンティブはあなたの紹介プログラムをとても盛り上げます。</p>`,
      },
      number: '3',
      columns: '3',
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '1',
        button0: {
          label: '連絡先',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_api',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'APIやプラグインと簡単に統合',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_target',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: '複数レベルでの紹介を追跡',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_medal',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: '即時もしくは計画された行動に基づく報酬',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
