module.exports = {
  title: 'CyberMiles APP',
  'meta-description': 'Securely manage your digital assets with the CyberMiles mobile blockchain wallet. Store and trade multiple currencies, receive real-time market updates.',
  banner: {
    title: 'CyberMiles APP',
    backgroundImage: 'bannerCMT',
    description: 'Digital Asset Wallet',
    buttons: {
      number: '0',
    },
  },
  imageTextHero: {
    section0: {
      image: {
        gif: 'null',
        path: 'cmtWalletOne',
      },
      title: 'Mobile Blockchain Wallet from CyberMiles ',
      description: {
        richtext: 'null',
        description: 'You can get it in the following ways',
      },
      backgroundImage: 'null',
      imageButtons: {
        number: '2',
        button0: {
          icon: 'appStore',
          link: 'https://itunes.apple.com/us/app/id1418378426',
        },
        button1: {
          icon: 'googlePlay',
          link: 'https://play.google.com/store/apps/details?id=io.cybermiles.cmtwallet',
        },
        // button2: {
        //   icon: 'walletQR',
        //   link: '/',
        // },
      },
      buttons: {
        number: '2',
        button0: {
          label: 'Android Download',
          link: 'https://s3.cn-north-1.amazonaws.com.cn/cmtwallet/android/CMT-Wallet-Android.apk',
        },
        button1: {
          label: 'IOS Download',
          link: 'itms-services://?action=download-manifest&url=https://s3.cn-north-1.amazonaws.com.cn/cmtwallet/ios/manifest.plist',
        },
      },
    },
  },
  imageText: {
    section0: {
      image: {
        gif: 'null',
        path: 'cmtWalletTwo',
      },
      title: 'Safe, Convenient and Powerful',
      description: {
        richtext: 'null',
        description:
          'CyberMiles APP strives to provide a simple, secure and powerful digital asset management tool for users. Users are able to experience freedom in wallets creation/ importing, secure digital asset management, convenient transaction execution, real-time market updates, and explore the potential of CyberMiles DApps all with CyberMiles.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  features: {
    number: '1',
    columns0: {
      title: 'Benefits and Features',
      description: {
        richText: 'null',
        description:
          'null',
      },
      number: '4',
      columns: '2',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresKey',
            link: 'null',
          },
        },
        title: 'Self-held Private Keys, Enhanced Security',
        description: {
          richText: 'null',
          description:
            'Encrypted Private Keys are stored securely in the device’s local sandbox system. Provides multiple wallet backup options, to prevent loss or theft of assets',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresPhone',
            link: 'null',
          },
        },
        title: 'Simple to Use, Convenient to Transact',
        description: {
          richText: 'null',
          description: 'Execute transactions without the need to synchronize with the blockchain. Enjoy a user-friendly interface.',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresWorld',
            link: 'null',
          },
        },
        title: 'Robust DApp Browser',
        description: {
          richText: 'null',
          description:
            'Decentralized/ distributed applications browser constructed with smart contract technology. Supports DApps like FairPlay/ WeBet/ Declare your love/ CMT Tracking etc..',
        },
        buttons: {
          number: '0',
        },
      },
      column3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresLock',
            link: 'null',
          },
        },
        title: 'One-stop Digital Asset Management',
        description: {
          richText: 'null',
          description:
            'Customizable asset list with assets like CMT and more. Real-time market and asset values updates of your CMT. Import wallet from imToken, JAXX, Metamask, CMT Cube. Price feeds synchronized with global major exchanges like Coinbase, Binance, Huobi, OKex, Bittrex, Kraken, Poloniex.',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: 'CMT CyberMiles Token',
      description: {
        richText: 'true',
        description:
          'One of the main purposes CMT is to incentivize people work together to maintain the CyberMiles network. As CyberMiles public blockchain is optimized specifically for e-commerce applications,  CMT’s other main function is to settle commerce transactions.  CMT is traded on all major exchanges including <a href="https://www.huobi.com/" target="_blank">Huobi</a>, <a href="https://www.okex.com/" target="_blank">Okex</a>, <a href="https://www.binance.com/" target="_blank">Binance</a>, and many more.',
      },
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '1',
        button0: {
          link: '/cmt/overview/',
          label: 'Learn more about it',
          icon: 'null',
        },
      },
    },
  },
}
