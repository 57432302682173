module.exports = {
  title: 'Marketing Referral Program',
  'meta-description': 'Marketing Referral Program',
  banner: {
    title: 'Marketing Referral Program',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: 'Opportunity for Traditional E-commerce',
      description: {
        richText: 'true',
        description: `<p>Word of mouth marketing is one of the most effective marketing tactics. By utilizing the networking power of social media, many iconic companies, like Dropbox and Airbnb achieved hyperbolic growth with referral campaigns.</p>
          <p>Adding the power of blockchain to this already proven marketing strategy takes referral programs to a next level.  When incentive rewards are paid in a limited release blockchain based tokens, it unlocks the network ownership effect for the users, a double economic incentive. </p>
         `,
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'What is CyberMiles solution?',
      description: {
        richText: 'true',
        description: `<p>Whether your's is a blockchain based business or a traditional web-based application, you can easily create and publish your own referral program by leveraging CyberMiles’ enterprise-grade and professionally hosted DPOS blockchain and our extensive library of smart contracts. </p>
        <p>Whenever a user refers your product or service, he/she not only receives additional tokens but increases the awareness of the token hence increasing the value of his/her previously received tokens as well. This double economic incentive supercharges your referral program.</p>
       `,
      },
      number: '3',
      columns: '3',
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '1',
        button0: {
          label: 'Contact Us',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_api',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'Easy integration with APIs and plugins',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_target',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'Track multi-level referrals',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_medal',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'Instant and scheduled action based rewards',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
