module.exports = {
  title: 'CMT概述',
  'meta-description': 'CMT（又名草莓糖） 是原生加密 token 货币，专为整合并使用于CyberMiles网络及网络分布式应用程序而打造。CyberMiles区块链旨在成为所有电子商务交易的公共区块链。',
  script: 'https://files.coinmarketcap.com/static/widget/currency.js',
  banner: {
    title: 'CMT概述',
    backgroundImage: 'bannerCoin',
    description: '原生加密 token 货币',
    buttons: {
      number: '0',
    },
  },
  benefits: {
    number: '1',
    columns0: {
      title: 'CMT技术优势',
      description: {
        richText: 'null',
        description:
          'null',
      },
      number: '4',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'smart',
            link: 'null',
          },
        },
        title: '智能',
        description: {
          richText: 'null',
          description:
            'CyberMiles公链为电商应用程序建立了智能合约模板库',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'fast',
            link: 'null',
          },
        },
        title: '快速',
        description: {
          richText: 'null',
          description: '除了token交易速度之外，CyberMiles虚拟机还可以根据业务需求优化智能合约计算速度。',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'safe',
            link: 'null',
          },
        },
        title: '安全',
        description: {
          richText: 'null',
          description:
            '区块链创造了可扩展性、去中心化和安全性的完美组合。 此外，CyberMiles公链还实施了“事前预防”和“事后恢复”机制作为额外的保护。',
        },
        buttons: {
          number: '0',
        },
      },
      column3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'free',
            link: 'null',
          },
        },
        title: '免费',
        description: {
          richText: 'null',
          description:
            '在不牺牲网络安全的情况下，CyberMiles公链免除了大多数常见操作的交易费。',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },

  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '通过权益投票获得更多的CMT',
      description: {
        richText: 'null',
        description:
          'CyberMiles公链采用创新的DPoS协议，您可以通过权益投票CMT来参与网络治理。 作为交换，您将在每个新创建的区块中收到因系统膨胀和交易费产生的CMT。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '2',
        button0: {          
          link: '/cmt/cmt-cube/',
          label: '通过CMT Cube 权益投票',
          icon: 'null',
        },
        button1: {          
          link: '/cmt/validator/',
          label: '验证人计划',
          icon: 'null',
        },
      },
    },
  },
  coins: {
    number: '1',
    coins0: {
      images: {
        number: '0',
      },
      title: 'CMT 市场表现',
      description: {
        richText: 'null',
        description:
          '我们致力于CMT市场的长期稳定和健康',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
      number: '3',
      columns:'3',
      column0: {
        images: {
          number: '0',
        },
        title: '市值',
        description: {
          richText: 'null',
          description:
            'null',
        },
        buttons: {
          number: '0',
        },
        data: {
          key: "market_cap",
          unit: "USD",
        }
      },
      column1: {
        images: {
          number: '0',
        },
        title: '价格',
        description: {
          richText: 'null',
          description:
            'null',
        },
        buttons: {
          number: '0',
        },
        data: {
          key: "price",
          unit: "CMT/USD",
        }
      },
      column2: {
        images: {
          number: '0',
        },
        title: '24小时交易量',
        description: {
          richText: 'null',
          description:
            'null',
        },
        buttons: {
          number: '0',
        },
        data: {
          key: "volume",
          unit: "USD",
        }
      }
    },
  },
  boxes: {
    number: '1',
    boxes0: {
      title: '购买CMT',
      description: {
        richText: 'null',
        description: 'CMT可在如下交易所购买交易',
      },
      number: '12',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'lastSection',
      box0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'cobinhood',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://cobinhood.com/trade/CMT-BTC">BTC</a>, <a href="https://cobinhood.com/trade/CMT-ETH">ETH</a>, <a href="https://cobinhood.com/trade/CMT-USDT">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'binance',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.binance.com/trade.html?symbol=CMT_BTC">BTC</a>, <a href="https://www.binance.com/trade.html?symbol=CMT_ETH">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'lbank',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.lbank.info/exchange.html?asset=cmt&post=btc">BTC</a>, <a href="https://www.lbank.info/exchange.html?asset=cmt&post=eth">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'huobi',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.hbg.com/zh-cn/cmt_btc/exchange/">BTC</a>, <a href="https://www.hbg.com/zh-cn/cmt_eth/exchange/">ETH</a>, <a href="https://www.huobi.com/zh-hk/exchange/?s=cmt_usdt">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box4: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'coinbene',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.coinbene.com/#/?pairId=36">ETH</a>, <a href="https://www.coinbene.com/#/?pairId=24">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box5: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'dragonex',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://dragonex.io/trade/index/cmt_eth">ETH</a>, <a href="https://dragonex.io/zh-hant/trade/index/cmt_usdt">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box6: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'bibox',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.bibox.com/exchange?coinPair=CMT_BTC">BTC</a>, <a href="https://www.bibox.com/exchange?coinPair=CMT_ETH">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box7: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'koinex',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://koinex.in/exchange/inr/cyber_miles">INR</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box8: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'okex',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.okex.com/spot/trade#product=cmt_btc">BTC</a>, <a href="https://www.okex.com/spot/trade#product=cmt_eth">ETH</a>, <a href="https://www.okex.com/spot/trade#product=cmt_usdt">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box9: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'coincoming',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://trade.coincoming.com/deal/pDealcent?coinid=10038">BTC</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box10: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'bithumb',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://en.bithumb.com/trade/order/CMT">KRW</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box11: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'changenow',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://changenow.io/zh/exchange?amount=1&to=cmt">BTC</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
