module.exports = {
  title: 'Payment Gateway',
  'meta-description': 'Payment Gateway',
  banner: {
    title: '전자지불결제',
    backgroundImage: 'bannerCube',
    description: '새로운 수익 기회 창출',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '전통 전자 상거래에 대한 도전',
      description: {
        richText: 'null',
        description:
          '암호화폐 결제에 대한 분명한 수요가 있으나 기존의 전자상거래 웹 사이트에서는 암호화폐 결제를 수락하기가 쉽지 않습니다. 전통적인 지불결제 회사들은 한두가지 정도의 주류 암호화폐만을 지원하고 있으며 암호화폐를 통화(예 : USD)로 변환할때 높은수수료가 발생하고 있습니다. 그들의 cryptocurrency 지불 방법은 소비자에게 좋지못한 인상을 남기고 있습니다. 이러한 방식은  신용카드와 같은 전통적인 지불방법과 비교했을때 경쟁력이 없습니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMiles 솔루션',
      description: {
        richText: 'null',
        description:
          '사이버 마일즈 결제 게이트웨이는 파트너에 의해 제공되는 SaaS 서비스입니다. 전자 상거래 (e-commerce) 웹 사이트에 내장되어 오늘날의 시장에 있는 거의 모든 암호화폐 (cryptocurrency)로 고객 지불을 수용 할 수 있습니다.',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '연락처',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_coin',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '모든 주류 암호화폐가  적용 됩니다. BTC、ETH、CMT그리고 거의 모든 ERC20 토큰이 포함 됩니다.',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_refresh',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'USD 혹은 기타 법정화폐와 연동된 스테이블 코인이 사용 됩니다.(예 : USDT 및 GUSD)',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_love',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: '완벽하고 투명한 사용자 경험',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  columns: {
    number: '1',
    columns0: {
      title: '유즈 케이스',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '2',
      columns: '2',
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '0',
      },
      images: {
        number: '0',
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'lightInTheBox',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'blocktonic',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
