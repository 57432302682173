module.exports = {
  title: 'Team & Advisors',
  'meta-description': 'The Cybermiles management and advisory team are made up of scientists, developers, and entrepreneurs giving dynamics and years of experience to the company.',
  banner: {
    title: 'チームとアドバイザー',
    backgroundImage: 'bannerGlobe',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  teamMembers: {
    number: '2',
    teamMembers0: {
      title: 'チーム',
      backgroundImage: 'null',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      number: '5',
      teamMember0: {
        image: 'lucas',
        name: 'Lucas Lu, 博士',
        linkedin: 'https://www.linkedin.com/in/sixwings/',
        title: 'Founder',
        description: {
          richtext: 'null',
          description:
            '2005年にSMUで素粒子物理学の博士を取得。CERNに勤務し、ヒッグス粒子の理論的および実験的研究に参加。以前はニューヨーク証券取引所に上場していたLight In the Boxの共同設立者でCTOでもあり、さらにその前はアリババのタオバオモバイルプラットフォームで最初のGMと別の事業部門のGMを務める。2014年に5milesを設立。',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember1: {
        image: 'michael',
        name: 'Michael Yuan, 博士',
        linkedin: 'https://www.linkedin.com/in/myuan/',
        title: '開発者リーダー',
        description: {
          richtext: 'null',
          description:
            'オースティンのテキサス大学で天体物理学において博士を取得。Prentice Hall、Addison-Wesley、およびOReillyが出版したソフトウェア開発に関する5冊の書籍の著者。Firefox、Fedora、JBossなどの大規模なオープンソースプロジェクトで活発なコードコミッターでした。彼はエンタープライズおよびモバイルソフトウェアの専門家であり、米国政府が資金を提供する複数の研究プロジェクトの主任研究員を務めた。',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember2: {
        image: 'tim',
        name: 'Timothy McCallum',
        linkedin: 'https://www.linkedin.com/in/tim-mccallum-2226413a/',
        title: '開発者',
        description: {
          richtext: 'null',
          description:
            '企業向けのブロックチェーン実装の専門家。FinTechプログラミングのスペシャリストで、最近関わったプロジェクトは地方自治体向けの財務データ移行作業でした。2015年にTomorrow MakerのAMPに任命されました。彼の仕事は複雑な問題に対する解決策を見つけることでした。Google Summer of Codeのメンターを務め、2014年にはGoogleに招待され、シリコンバレーで世界中のソフトウェア開発者と共同作業を行いました。',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember3: {
        image: 'alex',
        name: 'Alex Lau',
        linkedin: 'https://www.linkedin.com/in/avengermojo/',
        title: '開発者',
        description: {
          richtext: 'null',
          description:
            '1999年にノーステキサス大学でソフトウェアコンピュータ科学の学位を取得。中国に移る前はNortel Networkで勤務していた。SUSE Linuxの北京と台湾のR＆Dマネージャーであり、Symbio MobileのCTO。SUSEの分散ファイルシステムのコンサルタント、WeBankと5milesのブロックチェーンコンサルタントとして勤務。',
        },
        buttons: {
          number: '0',
        },
      },
      
//       teamMember5: {
//         image: 'garwin',
//         name: 'Garwin Chan',
//         linkedin: 'https://www.linkedin.com/in/garwin-chan-b8378a1/',
//         title: 'CFO',
//         description: {
//           richtext: 'null',
//           description:
//             '　5milesのCFO。以前はFounder H Fund（投資家グループのプライベート・エクイティ部門）のマネージングディレクターを務めていた。それ以前は、世界有数の投資会社であるBain Capitalの副社長を務めた。ハーバード大学を優秀な成績を修めて卒業。そして、2008年にハーバードビジネススクールでMBAを取得。',
//         },
//         buttons: {
//           number: '0',
//         },
//       },
      teamMember4: {
        image: 'mark',
        name: 'Mark Brinkerhoff',
        linkedin: 'https://www.linkedin.com/in/thinkbrink/',
        title: 'VP of Communication',
        description: {
          richtext: 'null',
          description:
            '広報活動、パートナーシップ構築の専門で、消費者とブランドをつなぐストーリー性を備えたスタートアップアドバイザーかつコミュニケーション戦略家。結果を追求する広報活動分野で数十年の経験を持ち、革新的で急速に成長する新興企業、特に消費者テクノロジーのマーケティングとブランドコミュニケーションを務める。',
        },
        buttons: {
          number: '0',
        },
      },
    },
    teamMembers1: {
      title: 'アドバイザー',
      backgroundImage: 'bannerCube',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      number: '5',
      teamMember0: {
        image: 'garwin',
        name: 'Garwin Chan',
        linkedin: 'https://www.linkedin.com/in/garwin-chan-b8378a1/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            '5milesの前CFO。以前はFounder H Fund（投資家グループのプライベート・エクイティ部門）のマネージングディレクターを務めていた。それ以前は、世界有数の投資会社であるBain Capitalの副社長を務めた。ハーバード大学を優秀な成績を修めて卒業。そして、2008年にハーバードビジネススクールでMBAを取得。',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember1: {
        image: 'marc',
        name: 'Marc Fleury',
        linkedin: 'https://en.wikipedia.org/wiki/Marc_Fleury',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'ソフトウェアの自由と分権化のビジネスの先駆者です。 彼はJBoss、オープンソースのJavaアプリケーションサーバーの開発者であり、Red Hat IncのEVPを務めました。また、暗号通貨の初期の理解者かつ投資家でもある。 パリのエコール・ポリテクニークで数学の学位と物理学の博士号を、フランスのENSで論理物理学の修士号を取得。',
        },
        buttons: {
          number: '0',
        },
//       },
//       teamMember2: {
//         image: 'malachi',
//         name: 'Malachi Boyuls',
//         linkedin: 'https://www.linkedin.com/in/malachi-boyuls-5b486b90/',
//         title: 'null',
//         description: {
//           richtext: 'null',
//           description:
//             'Malachi is a partner at St. Augustine Capital Partners, a partnership offering consulting services and strategic advice to clients in industries such as technology, energy, real estate, and financial services. Previously, he was an attorney in Dallas at Gibson, Dunn, & Crutcher LLP, where he practiced in the firm’s regulatory groups, including antitrust, energy, securities and intellectual property. He graduated from New York University School of Law.',
//         },
//         buttons: {
//           number: '0',
//         },
      },
      teamMember2: {
        image: 'george',
        name: 'George Chen',
        linkedin: 'https://www.linkedin.com/in/george-chen-ab9106/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            '　5milesの前CMO。 5milesに入社する前は、中国最大の広告プラットフォームおよびデジタルマーケティング会社であるAllyesの製品開発部門でV.P.を務める。特にデジタルの分野でのマーケティングにおける長年の経験により、Googleの子会社であるDoubleClickの主任エンジニアを務める。ライス大学および中国科学技術大学で物理学の修士号を取得。',
        },
        buttons: {
          number: '0',
        },
      },
      
      teamMember3: {
        image: 'frank',
        name: 'Frank Lee',
        linkedin: 'https://www.linkedin.com/in/frank-lee-873902106/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            '数々の新興企業の創設者。彼は世界で最初のLitecoin ASICマイナーを開発。 彼の会社の2つはMIエコシステムに含まれています。彼はゲーム、VR、AR、IOTなどさまざまな分野の企業に投資し共同設立。Frankは清華大学を卒業し、電気工学の学位を取得。',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember4: {
        image: 'ash',
        name: 'Ash Han',
        linkedin: 'https://www.linkedin.com/in/ashhan/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'エンジェル投資家、ブロックチェーンのエバンジェリスト。政府機関、銀行、金融機関、個人の顧客を抱える韓国最大のブロックチェーン/ DLTコンサルティング会社であるFinectorのCEOを務める。2012年以来、ブロックチェーンの経済とテクノロジーを深く理解したエンジェル投資家、共同創設者、アドバイザー、コミュニティオーガナイザー、作家、講演者として、ブロックチェーン業界に専念。',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  partnerBoxes: {
    number: '2',
    boxes0: {
      title: 'パートナー',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '8',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      box0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'fivemiles',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'blockchainVentures',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'linkvc',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'collinstar',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box4: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'aware',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box5: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'chainedbox',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box6: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'cybervein',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box7: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'hyperpay',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
    },
    boxes1: {
      title: '顧問',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '2',
      columns: '2',
      buttons: {
        number: '0',
      },
      backgroundImage: 'shortLastSection',
      box0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'yeewhy',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
//       box1: {
//         images: {
//           number: '1',
//           image0: {
//             gif: 'null',
//             path: 'deloitte',
//             link: 'null',
//           },
//         },
//         title: 'null',
//         description: {
//           richText: 'true',
//           description: 'null',
//         },
//         buttons: {
//           number: '0',
//         },
//       },
      box1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'lockeLord',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
