module.exports = {
  title: 'Stable Coin',
  'meta-description': 'Stable Coin',
  banner: {
    title: '스테이블 코인',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '전통적인 전자 상거래를위한 도전 과제',
      description: {
        richText: 'null',
        description:
          '전자 상거래는 가장 초기이자 가장 널리 사용되는 암호화폐 사용사례이지만 변동성이 크고 높은 수수료와 낮은속도로 인해 채택에 방해 받고 있습니다. 대부분의 국가에서 소비자에게 상품가치에 대해 안정된 통화로 가격을 책정해야합니다. BTC 및 ETH 네트워크의 확인 시간은 느리고 높은 거래 수수료가 발생하므로 전자 상거래 지불, 특히 소액 결제에 적합하지 않습니다. 전자 상거래에서 암호화폐가 더 널리 채택되려면  법정화폐에 맞설 수있고고속 거래를 지원하며 수수료가 매우 낮은 새로운 종류의 암호화폐가 필요합니다. 그것은 바로 스테이블 코인입니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMiles 솔루션',
      description: {
        richText: 'null',
        description:
          'CyberMiles 블록 체인은 스테이블 코인 발급 및 거래에 최적화되어 있습니다. CyberMiles 검증인은 cross-chain 예금과 스테이블 코인의 환매가 투명하고 유효하다는 보증을 제공합니다. CyberMiles 가상 머신은 스마트 계약 스테이블 코인을 기반으로 전자 상거래 대규모 결제 솔루션에 필요한 성능을 지원합니다.',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '연락처',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_symbol',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            ' 모든 유스 케이스에 대해 GUSD 및 BTC와 고정 된 여러 가지의 stable coin',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_link',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '블록 체인에서 확인 가능한 보증금으로 100 % 지원',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_otc',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'On-chain OTC 거래를 통한 높은 유동성',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
