module.exports = {
  title: '条款',
  'meta-description': 'Cybermiles website terms of use. PLEASE READ THE TERMS OF USE CAREFULLY. It includes your obligations, privacy, intellectual property, governing law and more.',
  banner: {
    title: '条款',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  richTextContent: {
    backgroundImage: 'null',
    content: `<h2>Terms</h2>
    CyberMiles Website terms of use Introduction This website (“
    <strong>Our website</strong>“) is owned and operated by CyberMiles
    Foundation, a company limited by guarantee registered in Hong Kong (“
    <strong>CyberMiles</strong>”, “<strong>we</strong>” or “
    <strong>us</strong>”). Each reference in these terms of use to “our
    website” includes all materials that we make available to you on our
    website. By using our website, you agree to comply with these terms of
    use. Please check this page each time you use our website, as we may
    update these terms from time to time. If you carry out any transaction
    through our website, that transaction may be subject to additional terms
    and conditions which you will be asked to read and accept at the relevant
    time. To the extent of any inconsistency with these terms, the terms of
    the transaction apply to that transaction and any matters connected with
    it. Your obligations You must comply with any instructions we give you
    about how to use our website and must not do anything that interferes with
    or adversely affects the normal operation of the website (including the
    ability of other users to access or use the website). You are responsible
    for ensuring the security of the systems and devices that you use to
    access our website, including through use of appropriate virus-checking
    and other security software. Registration You may need to register with us
    in order to access certain parts of our website. Further details about
    registrations are available in our FAQs, which are available [here]. In
    this case, we may issue you with a user name and password, or other
    appropriate log-in details for your account. You are responsible for
    keeping your log-in details confidential so that they cannot be used
    without your permission. You will be responsible for any use of our
    website by anyone using your log-in details. Rights to suspend or
    terminate access We may suspend, terminate or block your access to all or
    any part of our website at any time and without prior notice to you.
    Intellectual property All of the intellectual property rights in and to
    our website belong to us and our licensors. We may update and change the
    materials available on our website, including by removing materials, at
    any time in our discretion without notice to you. You may download or
    print sections of our website if needed for your own personal use, but
    otherwise you may not reproduce any part of our website without our
    express prior consent. To the extent that our website contains
    functionality that allows you to access or download specific materials
    through our website, your use of that functionality and the downloaded
    materials may be subject to separate terms and conditions which you will
    be asked to read and accept at the relevant time. Website links Our
    website may contain or link to information that has not been devised,
    verified or tested by us or any of our officers, employees or agents.
    These are provided for your information only. We do not take any
    responsibility for that information, nor endorse its accuracy or
    completeness. We do not guarantee that the information, or the provision
    of the hyperlinks to you, does not infringe third party rights. If our
    website contains a link to an external website, we do not endorse,
    recommend, approve, guarantee or introduce any third parties or the
    services and/or products that those third parties may offer. We accept no
    responsibility for them or any of the content available, or for any loss
    or damage that may arise from your use of them. You may provide a link to
    the home page of our website at, on any website you operate, as long as
    you do not suggest any form of association, approval or endorsement on our
    part without our express prior permission and you promptly delete the link
    at our request. Except as set out in this clause, you may not link to our
    website. No financial services activities or advice The information and
    any materials contained in this website should not be considered as an
    offer or solicitation to buy or sell financial instruments, provide
    financial advice, create a trading platform, facilitate or take deposits
    or provide any other financial services of any kind in any jurisdiction.
    The information contained on this website is not intended to provide and
    should not be construed as advice of any kind. You should obtain
    appropriate professional advice when necessary. It does not take into
    account your objectives, financial situation or needs. Liability While we
    will use reasonable care and skill in operating our website, we cannot
    promise that our website will always be available, meet your requirements
    or completely free of faults, errors or compromise from cybersecurity
    events. To the maximum extent permitted by law, except as expressly set
    out in these terms of use, we exclude:
    <ul>
      <li>
        all conditions, representations, warranties and statutory guarantees,
        whether express or implied, in relation to our website; and
      </li>
      <li>
        any liability, whether in contract, tort (including negligence), or
        otherwise, for any indirect or consequential loss, damage or expense
        incurred by you or any other user in connection with our website.
      </li>
    </ul>
    To the extent we are unable by law to exclude any implied condition,
    representation, warranty or statutory guarantee in relation to the
    provision of goods or services through our website, we limit our liability
    for a breach to the re-supply of those goods or services, or payment for
    such re-supply. This is without limitation to any other exclusions or
    restrictions of our or others’ liability in connection with this website.
    Privacy We will manage any personal information that we collect through
    our website in accordance with our Privacy Policy, which you can access 
    <a href="http://www.cybermiles.io//privacy">here</a>. Governing law These
    terms of use are governed by the laws in force in the Hong Kong Special
    Administrative Region of the People’s Republic of China and are subject to
    the exclusive jurisdiction of the courts in that place. Questions? Please 
    <strong>contact us</strong> at 
    <a href="mailto:contact@cybermiles.io">contact@cybermiles.io</a> if you
    have any questions about our website. Copyright © 2017 CyberMiles
    Foundation Ltd. All rights reserved. 
    <a href="http://www.cybermiles.io//privacy">Privacy</a> &amp; 
    <a href="http://www.cybermiles.io//terms">Terms</a>`,
  },
}
