module.exports = {
  title: 'Cybermiles公链|专为电商的区块链',
  'meta-description': 'CyberMiles公链是智能、快速、安全的区块链网络，具有智能合约模板，并且对所有人免费。',
  banner: {
    title: 'CyberMiles',
    backgroundImage: 'bannerCube',
    description: '专为电商的公链',
    buttons: {
      number: '1',
      button0: {
        label: 'CyberMiles公链是什么',
        link: '/',
        icon: 'play',
      },
    },
  },
  imageText: {
    section0: {
      image: {
        gif: 'true',
        path: 'cube',
      },
      title: '电商的最佳区块链解决方案',
      description: {
        richtext: 'null',
        description:
          'CyberMiles公链为电商企业推出了高度定制的解决方案，以便电商企业能够轻松将业务部署在区块链上。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '找到你的用例',
          link: 'ecommerce-solutions/finance/payment-gateway/',
        },
      },
    },
    section1: {
      image: {
        gif: 'true',
        path: 'coin',
      },
      title: '有价值的区块链技术',
      description: {
        richtext: 'null',
        description:
          'CyberMiles公链是智能、快速、安全的区块链网络，并且对所有人免费。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '购买CMT',
          link: 'cmt/overview/',
        },
      },
    },
    section2: {
      image: {
        gif: 'true',
        path: 'lity',
      },
      title: '区块链上的简单开发',
      description: {
        richtext: 'null',
        description: 'CyberMiles公链构建了强有力的开发Dapp的基础架构。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '用CyberMiles开发',
          link: 'developer-portal/developer-hub',
        },
      },
    },
  },
  roadmap: {
    title: '路线图',
    description: {
      richtext: 'null',
      description: 'null',
    },
    backgroundImage: 'lastSection',
    buttons: {
      number: '1',
      button0: {
        label: '查看更多',
        link: '/about-us/roadmap',
      },
    },
    events: {
      number: '5',
      event0: {
        title: 'Q2',
        outline: `5miles数据上链已经在按照计划实施中，电商DApp探索之路已经开始。同时虚拟机也将迎来功能升级，支持合约创建者支付gas 费，这将大幅降低DApp的使用门槛，让更多的无币用户体验区块链技术。根据社区的意见和建议，我们将调整DPoS协议的激励算法，让CMT社区能够更多地从DPoS协议中受惠。`,
        parent: 'true'
      },
      event1: {
        title: '电商DApp',
        list: {
          size: '2',
          item0: '5miles数据上链',
          item1: '智能合约支持稳定币购买商品'
        }
      },
      event2: {
        title: '虚拟机功能升级',
        list: {
          size: '2',
          item0: '安全的随机数支持',
          item1: '支持合约创建者支付 gas 的机制'
        }
      },
      event3: {
        title: 'CyberMiles App',
        list: {
          size: '2',
          item0: '让新的钱包用户不需要买币就可以体验一些DApp',
          item1: '支持权益投票（采纳社区意见，但结果视社区投票情况而定）'
        }
      },
      event4: {
        title: '主链DPoS协议奖励机制调整',
        list: {
          size: '2',
          item0: '让算法简单，易懂',
          item1: '支持 unstake 期间的收益'
        }
      }
    }
  },
  centered: {
    centered0: {
      image: 'null',
      title: '与CyberMiles公链一起合作',
      description: {
        richText: 'null',
        description: '任何有趣的想法？来和我们分享！',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '联系我们',
          link: '/about-us/contact-us',
        },
      },
    },
  },
}
