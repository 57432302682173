module.exports = {
  title: 'User Engagement Solutions',
  'meta-description': 'User Engagement Solutions',
  banner: {
    title: 'User Engagement Solutions',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: 'Challenges for Traditional E-commerce',
      description: {
        richText: 'true',
        description:
          `<p>One of the biggest challenges in today's digital business environment is to keep your user base engaged and interested.  No matter if we are talking about a blockchain based solution or not, audience engagement with your brand and product could make or break your business.</p>
          <p>Blockchain token-based bounty and loyalty programs are powerful ways of gaining traction and growing an early audience. It directly incentivizes desired user behaviors to generate growth.</p>
          <p>The key to your successful growth is execution. Planning, accurately tracking,  and distributing rewards could be an overwhelming and resource intensive task for any organization.</p>`,
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'What is CyberMiles solution?',
      description: {
        richText: 'null',
        description:
          'With our secure and professionally hosted robust DPOS blockchain and our comprehensive smart contract template library, you can quickly build and run effective bounty and loyalty campaigns and build an active community around your brand and product. You can easily incentivize users to promote your brand or create content on your platform and a lot more.',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '1',
        button0: {
          label: 'Contact Us',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_stats',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'Accurately monitor and reward collaboration',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_bookmark',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'Automate daunting tasks',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_api',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'Easy integration with APIs and plugins',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
