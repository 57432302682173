module.exports = {
  title: 'RoadMap',
  'meta-description': 'RoadMap',
  banner: {
    title: '로드맵',
    backgroundImage: 'bannerGlobe',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  roadmap: {
    title: 'null',
    pending: '업데이트 중 입니다.',
    pendingDesc: 'null',
    description: {
      richtext: 'null',
      description:
        'null',
    },
    backgroundImage: 'lastSection',
    buttons: {
      number: '0',
    },
    events: {
      number: '13',
      event0: {
        title: '2 분기',
        outline: `5 마일즈 사용자 데이터 마이그레이션이 계획대로 진행되고 있습니다. 전자 상거래 DApps를 개발하기 시작했습니다. 동시에 기술 팀은 가스 요금 없게 가상 머신 업그레이드를 계획하고있습니다.이러므로 훨씬 더 많은 사용자가 CyberMiles 블록 체인 기술을 경험할 수있게합니다. 커뮤니티의 의견과 제안에 따라 투명성을 높이기 위해 DPoS 프로토콜의 보상 메커니즘을 조정할 것입니다.`,
        parent: 'true'
      },
      event1: {
        title: '전자 상거래 DApp',
        list: {
          size: '2',
          item0: '5 마일즈 데이터 마이그레이션',
          item1: '스테이블 코인 구매를 지원하는 스마트 계약'
        }
      },
      event2: {
        title: '가상 머신 업그레이드',
        list: {
          size: '2',
          item0: '스마트 계약의 난수 보장',
          item1: '계약 소유자가 가스 요금을 지불 할 수있는 옵션'
        }
      },
      event3: {
        title: 'CMT 지갑',
        list: {
          size: '2',
          item0: '새로운 지갑 사용자가 CMT를 먼저 얻지 않고도 DApp를 경험할 수 있습니다',
          item1: '지갑 스테이킹 기능 (커뮤니티 투표로 결정합니다)'
        }
      },
      event4: {
        title: '메인 넷 DPoS 보상 메커니즘 조정',
        list: {
          size: '2',
          item0: '알고리즘을 더 간단하고 이해하기 쉽게 만듭니다',
          item1: '알고리즘 쉽게 변경하고 계류기간 7일동안에도 보상을 줍니다'
        }
      },
      event5: {
        title: '3 분기',
        outline: `전자 상거래 DApps는 이번 분기에 큰 진전을 보게 될 것입니다. 커뮤니티는 사용자가 물건을 사고 팔 수있는 C2C 전자 상거래 플렛폼을 출시 할 예정입니다. 또한 가상 머신은 전자 상거래의 요구에 맞게 업그레이드를 계속할 예정 입니다. 동시에 CyberMiles는 오픈 소스로 계속해서 기술을 제공 할 것입니다. 예를 들어 Uniswap Exchange와 같은 높은 프로필 프로젝트에 코드 기여를 계속할 것입니다.`,
        parent: 'true'
      },
      event6: {
        title: '전자 상거래 DApp',
        list: {
          size: '3',
          item0: '분산 된 에스크로 거래 지원',
          item1: 'CyberMiles 퍼블릭 블록체인에서 전자 상거래 제품을 쉽게 찾을 수 있도록 검색 엔진을 구축합니다',
          item2: 'C2C 전자 상거래 플랫폼'
        }
      },
      event7: {
        title: '가상 머신 업그레이드',
        list: {
          size: '4',
          item0: '스마트 계약 함수에서 복잡한 데이터 형식의 입력 및 출력 허용',
          item1: 'JSON 지원',
          item2: 'Common string library지원',
          item3: '스마트 계약 형식 검증 지원'
        }
      },
      event8: {
        title: '온 - 체인 탈중앙화 거래소 Uniswap',
        list: {
          size: '2',
          item0: 'Uniswap을위한 핵심 개발 자원을 지속적으로 제공합니다',
          item1: 'CyberMiles 블록 체인에서 Uniswap 운영'
        }
      },
      event9: {
        title: '4 분기',
        outline: `이번 분기의 주체는 오픈 소스가됩니다. 이번 분기에 블록체인에 전자 상거래 상가를 만드는 기술 표준을 발표 할 예정입니다. 이 표준을 준수하는 사람은 CyberMiles 블록 체인에 상가를 만들고 원하는 물건을 판매 할 수 있습니다. 또한 Ethereum 2.0 Virtual Machine eWASM에 상당한 공헌을 할 계획입니다. 제 3 자 개발자가 STAKING API를 사용할 수있게하고, CyberMiles App SDK를 게시하고 점차 기술 범위를 확대 할 것입니다.`,
        parent: 'true'
      },
      event10: {
        title: '전자 상거래 DApp',
        list: {
          size: '2',
          item0: '분산 된 전자 상거래 계약 및 DApps 표준 게시',
          item1: '개방형 API를 통해 더 많은 분산 된 전자 상거래 플랫폼을 CyberMiles 공개 블록 체인과 통합 할 수 있습니다'
        }
      },
      event11: {
        title: '가상 머신 업그레이드',
        list: {
          size: '2',
          item0: '플라즈마와 같은 여러 사이드 체인 지원',
          item1: 'Ethereum 2.0 가상 머신 eWASM 표준을 지원'
        }
      },
      event12: {
        title: 'CMT 지갑',
        list: {
          size: '2',
          item0: '외부 제 3 자 지갑 스테이킹 지원, API 제공',
          item1: '타사 전자 상거래 프로그램에서 CMT 및 스테이블 코인을 직접 사용할 수 있도록 CyberMiles App SDK 지원',
        }
      }
    }
  }
}
