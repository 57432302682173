module.exports = {
  title: 'CyberMiles|Blockchain Technology for E-commerce',
  'meta-description':
    'CyberMiles is a smart, fast, secure, and free e-commerce blockchain platform with lightning-fast transaction speed and smart contract templates.',
  banner: {
    title: 'CyberMiles',
    backgroundImage: 'bannerCube',
    description: 'Public Blockchain for E-commerce',
    buttons: {
      number: '1',
      button0: {
        label: 'How CyberMiles Works',
        link: '/',
        icon: 'play',
      },
    },
  },
  imageText: {
    section0: {
      image: {
        gif: 'true',
        path: 'cube',
      },
      title: 'Best Blockchain Solutions for E-commerce',
      description: {
        richtext: 'null',
        description:
          'CyberMiles has built highly customized solutions for the e-commerce companies to deploy their business on the Blockchain with ease.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: 'Find your use cases',
          link: 'ecommerce-solutions/finance/payment-gateway/',
        },
      },
    },
    section1: {
      image: {
        gif: 'true',
        path: 'coin',
      },
      title: 'Valuable Blockchain Technology',
      description: {
        richtext: 'null',
        description:
          'CyberMiles is a Smart, Fast, and Safe blockchain network while staying Free to everyone.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: 'Buy and Sell CMT',
          link: 'cmt/overview/',
        },
      },
    },
    section2: {
      image: {
        gif: 'true',
        path: 'lity',
      },
      title: 'Simple Development on Blockchain',
      description: {
        richtext: 'null',
        description:
          'CyberMiles has built one of the most powerful infrastructure for developing Decentralised applications.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: 'Build with CyberMiles',
          link: 'developer-portal/developer-hub',
        },
      },
    },
  },
  roadmap: {
    title: 'RoadMap',
    description: {
      richtext: 'null',
      description: 'null',
    },
    backgroundImage: 'lastSection',
    buttons: {
      number: '1',
      button0: {
        label: 'View More',
        link: '/about-us/roadmap',
      },
    },
    events: {
      number: '5',
      event0: {
        title: 'Q2',
        outline: `The 5miles user data migration to the CyberMiles blockchain is being implemented as planned. We have started exploring e-commerce DApps. At the same time, the technical team is planning a major virtual machine upgrade to support alternative gas fee mechanisms, which will greatly reduce the DApp barrier of entry and allow far more users to experience the CyberMiles blockchain technology. Based on community’s input and suggestions, we will also adjust the reward mechanism of the DPoS protocol for better transparency.`,
        parent: 'true'
      },
      event1: {
        title: 'E-commerce DApp',
        list: {
          size: '2',
          item0: '5miles data migration',
          item1: 'Smart contract supported stable coin purchases'
        }
      },
      event2: {
        title: 'Virtual machine upgrades',
        list: {
          size: '2',
          item0: 'Secure random numbers in smart contracts',
          item1: 'Option for the contract owner to pay for gas fees'
        }
      },
      event3: {
        title: 'CyberMiles App',
        list: {
          size: '2',
          item0: 'Allow new wallet users to experience some DApps without acquiring CMTs first',
          item1: 'Staking from the wallet apps (the final decision is subject to community voting)'
        }
      },
      event4: {
        title: 'Mainnet DPoS reward mechanism adjustments',
        list: {
          size: '2',
          item0: 'Make the algorithm simpler and easier to understand',
          item1: 'Rewards during the 7-day unstaking period'
        }
      }
    }
  },
  centered: {
    centered0: {
      image: 'null',
      title: 'Start Working With CyberMiles',
      description: {
        richText: 'null',
        description:
          'Any interesting ideas? Learn more or speak with our team member.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: 'Contact Us',
          link: '/about-us/contact-us',
        },
      },
    },
  },
}
