module.exports = {
  title: 'CyberMiles Validator Program',
  'meta-description':
    'We are recruiting validators to run supernodes for CMT rewards in exchange. Supernodes are servers ensuring the security and high performance of the blockchain.',
  banner: {
    title: 'Validator Program',
    backgroundImage: 'bannerCoin',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '4',
    centered0: {
      images: {
        number: '0',
      },
      title: 'Validator',
      description: {
        richText: 'null',
        description:
          'Validators are responsible for maintaining crucial network infrastructure, and making rule changes and governance decisions on behalf of its delegators. Since there are only 19 validators in the CyberMiles system, validators must compete for delegators’ votes (staked CMTs) in order to be hired by providing secure servers running up-to-date software, sufficient computing power and network bandwidth to power the global blockchain network.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
    centered1: {
      images: {
        number: '0',
      },
      title: 'Delegator',
      description: {
        richText: 'null',
        description:
          'Delegators hire, or elect, other CMT holders, or validators, for this task by staking their CMTs to specific validator candidates. The delegators themselves typically cannot or will not run the computer servers that actually power the blockchain network. During the first year of CyberMiles network operation, only CMT Cube owners will be able to delegate, and hence, stake for block awards.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
    centered2: {
      images: {
        number: '0',
      },
      title: 'CyberMiles App',
      description: {
        richText: 'true',
        description:
          `CMT users can use the CyberMiles App to <a href="http://onelink.to/v248ze" target="_blank">stake their CMTs to validators (supernodes)</a> to get block awards(CMTs). Based on the current stake of the whole network, for example, if stake 10,000 CMTs now to a validtor with the compensation rate of 30%, the annual return rate is 15%. This is calculated at the block height 1,948,355.\
          <p>*Under the same conditions, the CMT Cube staking award is 20% higher than the CyberMiles App (CMT Wallet).</p>`
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
    centered3: {
      images: {
        number: '0',
      },
      title: 'CMT Cube',
      description: {
        richText: 'null',
        description:
          'The CMT Cube is a home-based device specifically designed to facilitate validator election during the first year of CyberMiles network operation. It is a hardware device that acts both a CyberMiles App (stores and manages a CMT account), and an easy-to-use user interface, or UI, for staking the CMTs in the wallet to elect validators. Its UI also displays the up-to-date earnings (delegator’s block award) the account receives over time. The hardware wallet at home is much safer than web-based or phone-based software wallets, and hence is ideal for holding and staking significant amounts of CMTs.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '2',
        button0: {
          link: '/cmt/whitepaper/',
          label: 'Cybermiles DPoS Protocol',
          icon: 'null',
        },
        button1: {
          link: 'https://goo.gl/forms/I3cYxGLdJLpX14Hz2',
          label: 'Apply for Genesis Validator',
          icon: 'null',
        },
      },
    },
  },
  accordions: {
    number: '1',
    accordion0: {
      images: {
        number: '0',
      },
      title: 'FAQ',
      description: {
        richText: 'null',
        description: 'null',
      },
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '0',
      },
      number: '11',
      drawer0: {
        title: 'What are the benefits to become a CyberMiles’ Validator?',
        description: {
          richText: 'true',
          description:
            '<p>Delegators pay validators with a portion of their block awards. Each validator’s share of the block award is called the validator’s compensation. A validator’s compensation is determined at the time of staking. It is recorded and enforced by the network itself.</p><p>The compensation rate is set by the validator himself, directly in competition with the others”. Last but not least, validators participate in the network governance.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer1: {
        title: 'What are the requirements to become a Validator?',
        description: {
          richText: 'true',
          description:
            '<p>The validators need to run super nodes, which are professionally operated by network servers to ensure the performance and security of the blockchain network.</p><p>In addition to network servers, upon a new candidate declaration, the network immediately takes 10% of the declared maximum amount of stake from the candidate’s own account, and keep it as stake.</p><p>Since there are only 19 validators in the CyberMiles system, validators must compete for delegators’ votes (staked CMTs) in order to be hired by providing secure servers running up-to-date software, sufficient computing power and network bandwidth to power the global blockchain network.</p><p>However, CyberMiles Foundation will also give an additional certification to the candidates that we judge are trustworthy to become one of the 19 Validators.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer2: {
        title: 'How to be a certified candidate for the Validator election?',
        description: {
          richText: 'true',
          description:
            '<p>A validator candidate declares its candidacy to the network. It will give out 3 pieces of information in the declaration:</p><ol><li>Details of its operation and credentials, including jurisdiction, data center location, security setup, and technical setup. The CyberMiles Foundation will evaluate all validator candidates, and publicly indicate the candidates that meet the Foundation’s standards (i.e., information accuracy, technical competency, and hardware / network setup). However, the token holders are free to stake for any candidate regardless whether it meets the Foundation standard or not.</li><li>The maximum amount of CMTs it is willing to accept as stakes. In order to prevent any single validator from growing too big and therefore risking monopoly, the network protocol punishes delegators who stake in very large validators. (see the “B4. Block award and validator’s compensation” section for details)</li><li>The validator’s compensation rate it requires from delegators. For example, a 40% rate means that 40% of the block awards earned by delegators who staked this validator, will be paid to this validator as compensation.</li></ol><p>The CyberMiles Foundation will review the candidate’s information. If the foundation can verify the accuracy of the information, it will denote on-chain that the candidate is “verified”. CMT holders can stake any validator candidate they like, including the unverified ones, but verified candidates from the foundation give CMT holders more confidence to stake.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer3: {
        title: 'Why did CyberMiles choose staking as a voting mechanism?',
        description: {
          richText: 'true',
          description:
            '<p>Once the validator candidate receives enough stake to make the top 19, it becomes a validator, and all its delegators start to receive block award (about every 10 seconds). The block award includes two parts:</p><ol><li>The system has an annual inflation rate of 8%. This inflation is distributed as newly minted CMTs to delegators.</li><li>The delegators also receive transaction fees paid by heavy users of the blockchain.</li></ol><p>The total block award from the system is first assigned to the delegators proportional to each delegator’s stake. The system then automatically distributes the block award to the validators and delegators based on the validator’s compensation rates.</p><p>From a validator side, he will receive block awards from two sources: the self-staked CMTs by validating blocks and its compensation from delegators for maintaining the network.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer4: {
        title: 'Who are the candidates so far?',
        description: {
          richText: 'true',
          description:
            '<p>Already a dozen of high-profile candidates have announced their interests to become a Validator. To name a few: Susquehanna International Group, Great Voyage Capital, LinkVC, AlphaCoin Fund, Shuanghua Capital, IDG Capital.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer5: {
        title: 'What will happen when I stake and unstake my CMTs?',
        description: {
          richText: 'true',
          description:
            '<p>CMT holders (delegators) stake their CMTs to the validator candidate. It is important to note that staked CMTs cannot be traded, and once staked, a delegator must request unstaking and then wait a week before he can trade them again.</p><p>The waiting period of unstaking is to guard against something called “long range double spend attack”.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer6: {
        title: 'What if some Validators misbehave?',
        description: {
          richText: 'true',
          description:
            '<p>When a validator becomes unavailable or produces results that are different from the rest of the validators, the system will slash and burn 0.1% of its total stake (i.e., the validator itself and all its delegators will lose CMTs) every block (every 10s). After 12 consecutive slashes, the system removes the validator and promotes the next validator candidate as a validator.</p><p>The removed validator will no longer suffer slashing loss, but none of its delegators will earn any block awards either. Its delegator can request to unstake from it, and then re-stake their CMTs after the one week unstake waiting period.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer7: {
        title:
          'How do you prevent a potential attack on your blockchain from Validators (eg. 51% attack) ?',
        description: {
          richText: 'true',
          description:
            '<p>The CyberMiles protocol disincentives any validator from growing too big. If a single validator’s stake grows above 12% of total stake of the network, this validator could cause instability in the network. So, when the network assigns the block award, all its delegators will receive reduced block award for stakes that goes above the 12% threshold. This threshold incentivizes large CMT holders to diversify and stake in multiple validators, and it incentives validators to limit their max accepted stake. Algorithm to compute voting power for each validator is as follows.</p><ol><li>Distribute voting power to validators according to each validator’s stake amount, but capped at 12% for each. For example, if validator A has 5% of the outstanding stake, he gets 5% voting power; if validator B has 20% of the stake, he gets 12% voting power.</li><li>If there is remaining voting power, distribute a second round based on actual stake amount of each validator.</li></ol>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer8: {
        title:
          'In the case that the system rejects a validator, what will happen next?',
        description: {
          richText: 'true',
          description:
            '<p>Beyond the 19 official validators, there are validator candidates whose staked CMTs are less than the 19th validator. They are backup validators. There are always possibilities for the backup to accumulate enough stakes in the future to overtake the 19th validator. An existing validator might also be punished or simply withdraw, or stop functioning as a validator completely. In those cases, the backup becomes a validator, and the previous second candidate in line becomes the backup.</p><p>Backup validators do not earn CMT awards for the CMTs staked in them. They are typically new entrants into the ecosystem or opportunistic players. The Foundation will run multiple backup validator candidates in order to maintain the network stability.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer9: {
        title: 'How many CMT can a CMT Cube hold?',
        description: {
          richText: 'true',
          description:
            '<p>One CMT Cube can hold up to 100,000 CMTs and the minimum amount is 1,000 CMTs.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer10: {
        title: 'Where can I get a CMT Cube?',
        description: {
          richText: 'true',
          description:
            '<p>The CMT Cube can be purchased <a href="https://www.blocktonic.io/item/583" style="color: blue">here</a> at 998 CMT starting on May 31. However, CyberMiles Foundation will refund 100% of the cost within 300 days.</p>',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
