module.exports = {
  title: 'Venus--CMT浏览器插件钱包',
  'meta-description': 'CyberMiles Venus',
  banner: {
    title: 'Venus',
    backgroundImage: 'bannerCMT',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
metamask: {
    number: '1',
    centered0: {
      images: {
        number: '1',
        image0: {
          gif: 'null',
          path: 'metamask',
          link: 'null',
        },
      },
      title: 'CMT浏览器插件钱包',
      description: {
        richText: 'true',
        description:
          `<p>Venus是一个安全的CMT浏览器插件钱包，提供用户界面创建钱包、收款、转账、以及管理不同站点上的身份并签署区块链交易。</p><p>Venus是用于访问支持CyberMiles公链的分布式应用程序或普通Chrome浏览器中的“dApps”的扩展程序！</p><p>Venus是一个桥梁，允许您在浏览器中访问区块链网络。 它允许您直接在浏览器中运行CyberMiles dApp，而无需运行CyberMiles节点。</p><p>Venus将CyberMiles web3 API注入每个网站的javascript上下文，以便dApps可以从区块链中读取。</p><p>您可以在Chrome浏览器中安装Venus插件。 如果您是开发人员，可以立即开始使用Venus进行开发。</p>`,
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          link:
            'https://chrome.google.com/webstore/detail/venus/hmiddckbbijmdkamphkgkelnjjdkicck',
          label: '获取Chrome插件',
          icon: 'null',
        },
      },
    },
  },
  columns: {
    number: '1',
    columns0: {
      number: '3',
      columns: '3',
      title: '安装指南',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      backgroundImage: 'shortLastSection',
      column0: {
        image: {
          gif: 'null',
          path: 'null',
          link: 'null',
        },
        title: '1',
        description: {
          richtext: 'null',
          description: '下载“metamask4cmt.crx”安装文件',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        image: {
          gif: 'null',
          path: 'null',
          link: 'null',
        },
        title:
          '2',
        description: {
          richtext: 'null',
          description: '打开Chrome浏览器“扩展程序”并启用开发者模式',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        image: {
          gif: 'null',
          path: 'null',
          link: 'null',
        },
        title:
          '3',
        description: {
          richtext: 'null',
          description: '拖拽“metamask4cmt.crx文件”到扩展程序界面',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
