export default {
  title: 'Header',
  items: [
    {
      label: 'CMT',
      link: '#',
      children: [
        {
          disabled: 'false',
          label: 'CMT概述',
          link: 'cmt/overview/',
          children: [],
        },
        {
          disabled: 'false',
          label: 'CMT Cube',
          link: 'cmt/cmt-cube/',
          children: [],
        },
        {
          disabled: 'false',
          label: '验证人计划',
          link: 'cmt/validator/',
          children: [],
        },
        {
          disabled: 'false',
          label: '白皮书',
          link: 'cmt/whitepaper/',
          children: [],
        },
      ],
    },
    {
      label: '电商解决方案',
      link: '#',
      children: [
        {
          label: '金融',
          link: '#',
          children: [
            {
              disabled: 'false',
              label: '支付网关',
              link: 'ecommerce-solutions/finance/payment-gateway/',
              children: [],
            },
            {
              disabled: 'false',
              label: '证券化token',
              link: 'ecommerce-solutions/finance/security-token/',
              children: [],
            },
            {
              disabled: 'false',
              label: '稳定币',
              link: 'ecommerce-solutions/finance/stable-coin/',
              children: [],
            },
          ],
        },
        {
          label: '市场营销',
          link: '#',
          children: [
            {
              disabled: 'false',
              label: '营销推荐机制',
              link: 'ecommerce-solutions/marketing/marketing-referral-program/',
              children: [],
            },
            {
              disabled: 'false',
              label: '用户参与解决方案',
              link: 'ecommerce-solutions/marketing/user-engagement-solutions/',
              children: [],
            },
          ],
        },
        // {
        //   label: 'Governance',
        //   link: '#',
        //   children: [
        //     {
        //       disabled: 'true',
        //       label: 'Public Witness',
        //       link: 'ecommerce-solutions/governance/public-witness/',
        //       children: [],
        //     },
        //     {
        //       disabled: 'true',
        //       label: 'Evidence Capture',
        //       link: 'ecommerce-solutions/governance/evidence-capture/',
        //       children: [],
        //     },
        //     {
        //       disabled: 'true',
        //       label: 'Provenance',
        //       link: 'ecommerce-solutions/governance/provenance/',
        //       children: [],
        //     },
        //     {
        //       disabled: 'true',
        //       label: 'Data Marketplace',
        //       link: 'ecommerce-solutions/governance/data-marketplace/',
        //       children: [],
        //     },
        //     {
        //       disabled: 'true',
        //       label: 'Community Governance',
        //       link: 'ecommerce-solutions/governance/community-governance/',
        //       children: [],
        //     },
        //   ],
        // },
      ],
    },
    {
      label: '区块链基础设施',
      link: '#',
      children: [
        {
          disabled: 'false',
          label: 'CMT Tracking',
          link: 'https://www.cmttracking.io/',
          children: [],
        },
        // {
        //   disabled: 'false',
        //   label: 'CMT TestNet',
        //   link: 'https://testnet.cmttracking.io/',
        //   children: [],
        // },
        {
          disabled: 'false',
          label: 'CyberMiles APP',
          link: 'blockchain-infrastructure/cybermiles-app/',
          children: [],
        },
        {
          disabled: 'false',
          label: 'Venus',
          link: 'blockchain-infrastructure/venus/',
          children: [],
        },
        {
          disabled: 'false',
          label: 'Europa',
          link: ' http://europa.cybermiles.io',
          children: [],
        },
      ],
    },
    {
      label: '开发者门户',
      link: '#',
      children: [
        {
          disabled: 'false',
          label: '开发者门户',
          link: 'developer-portal/developer-hub',
          children: [],
        },
        // {
        //   label: 'Documentation',
        //   link: '#',
        //   children: [
        //     {
        //       disabled: 'true',
        //       label: 'Technical Benchmark',
        //       link: 'developer-portal/documentation/technical-benchmark/',
        //       children: [],
        //     },
        //   ],
        // },
      ],
    },
    {
      label: '关于我们',
      link: '#',
      children: [
        {
          disabled: 'false',
          label: '团队',
          link: 'about-us/team/',
          children: [],
        },
        // {
        //   disabled: 'true',
        //   label: 'FAQ',
        //   link: 'about-us/faq/',
        //   children: [],
        // },
        {
          disabled: 'false',
          label: '博客',
          link: 'https://blog.cybermiles.io/categories/zh/',
          children: [],
        },
        {
          disabled: 'false',
          label: '路线图',
          link: 'about-us/roadmap/',
          children: [],
        },
        {
          disabled: 'false',
          label: '联系我们',
          link: 'about-us/contact-us/',
          children: [],
        },
      ],
    },
  ],
}
