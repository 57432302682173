module.exports = {
  title: 'Venus',
  'meta-description': 'Venus is an extension for accessing CyberMiles enabled distributed applications, or "Dapps" in your normal Chrome browser! Check to learn more!',
  banner: {
    title: 'Venus',
    backgroundImage: 'bannerCMT',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
metamask: {
    number: '1',
    centered0: {
      images: {
        number: '1',
        image0: {
          gif: 'null',
          path: 'metamask',
          link: 'null',
        },
      },
      title: 'Bring CyberMiles public blockchain to your browser',
      description: {
        richText: 'true',
        description:
          `<p>Venus is an extension for accessing CyberMiles enabled distributed applications, or "Dapps" in your normal Chrome browser! It injects the CyberMiles web3 API into every website's javascript context, so that dapps can read from the blockchain.</p><p>Venus includes a secure identity vault, providing a user interface to manage your identities on different sites and sign blockchain transactions.</p><p>You can install the Venus add-on in Chrome browser. If you’re a developer, you can start developing with Venus today.</p>`,
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          link:
            'https://chrome.google.com/webstore/detail/venus/hmiddckbbijmdkamphkgkelnjjdkicck',
          label: 'Get Chrome Extension',
          icon: 'null',
        },
      },
    },
  },
  columns: {
    number: '1',
    columns0: {
      number: '3',
      columns: '3',
      title: 'Installation Guide',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      backgroundImage: 'shortLastSection',
      column0: {
        image: {
          gif: 'null',
          path: 'null',
          link: 'null',
        },
        title: '1',
        description: {
          richtext: 'null',
          description: 'Download the “metamask4cmt.crx” file',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        image: {
          gif: 'null',
          path: 'null',
          link: 'null',
        },
        title:
          '2',
        description: {
          richtext: 'null',
          description: 'Open the Chrome browser “Extensions” and enable the developer mode',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        image: {
          gif: 'null',
          path: 'null',
          link: 'null',
        },
        title:
          '3',
        description: {
          richtext: 'null',
          description: 'Drag and drop the “metamask4cmt.crx” file to the extension interface',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
