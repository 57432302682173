module.exports = {
  title: 'CMT Cube',
  'meta-description': 'CMT Cube is the official CyberMiles Token (CMT) mining machine authorized to stake CMTs to elect validators. You can purchase the mining hardware with CMTs.',
  banner: {
    title: 'CMT Cube',
    backgroundImage: 'bannerCoin',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  imageTextHero: {
    section0: {
      image: {
        gif: 'null',
        path: 'cmtCube',
      },
      title: 'CMT Cubeの準備完了！',
      description: {
        richtext: 'null',
        description: 'CyberMiles Mainnetが正常に起動しました',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: 'CMT Cubeの準備完了！',
          link: 'https://wj.qq.com/s/2769560/681a',
        },
      },
      imageButtons: {
        number: '0',
      },
    },
  },
  features: {
    number: '1',
    columns0: {
      title: '特徴',
      description: {
        richText: 'null',
        description:
          'null',
      },
      number: '4',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresCube',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'null',
          description:
            'CMTをステークすることによって、CyberMilesバリデータを選出することができます。',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresRise',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'CMTを保護するためのハードウェアセキュリティ機能',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresDB',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'null',
          description:
            'CMTによる支払いでのみ購入可能',
        },
        buttons: {
          number: '0',
        },
      },
      column3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresBattery',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'null',
          description:
            '低電力で使いやすいUI',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: 'CMT Cube ユーザーマニュアル',
      description: {
        richText: 'null',
        description:
          'null',
      },
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '2',
        button0: {
          link: 'https://prismic-io.s3.amazonaws.com/cybermiles%2F35a7d776-323d-4dd8-9596-6093f649aa46_cmt+cube%E8%AF%B4%E6%98%8E%E4%B9%A6-%E8%8B%B1%E6%96%87.pdf',
          label: 'ハードウェアユーザーマニュアル',
          icon: 'null',
        },
        button1: {
          link: 'https://prismic-io.s3.amazonaws.com/cybermiles%2Fcc1807e4-0070-4096-9273-5768b81ba6c6_cmt+cube+user+manual+++cmt+cube%E4%BD%BF%E7%94%A8%E8%AF%B4%E6%98%8E%E4%B9%A6.pdf',
          label: 'ソフトウェアユーザーマニュアル',
          icon: 'null',
        },
      },
    },
  },
}
