module.exports = {
  title: 'Security Token',
  'meta-description': 'Security Token',
  banner: {
    title: '시큐리티 토큰',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '전통적인 전자 상거래의 도전 과제',
      description: {
        richText: 'null',
        description:
          '모든 상업적 운영에서 주요 과제, 특히 빠르게 진행되는 전자 상거래의 경우에는 현금 흐름 관리가 운영을 유지하고 성장시킵니다. 유동성은 보다 효율적인 현금 흐름 관리를 위한 핵심 도구입니다. 그러나 월 스트리트를 통한 전통적인 증권화는 모든 단계에서 비용이 많이 들고 느린 인간의 개입이 필요하기 때문에 비싸고 비효율적입니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMiles는 어떻게 해결하는가?',
      description: {
        richText: 'null',
        description:
          'CyberMiles 블록 체인을 사용하면 전자 상거래 비즈니스에서 현금 흐름을 관리하고 운영 자본을 늘리는 시큐리티 토큰을 쉽게 발행 할 수 있습니다.',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '연락처',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_shield',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '모든 주류 발행 기준을 지킵니다. 예ERC1400、ERC1440、ST-20、R-Token、S3등',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_ofn',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'OFN거래소와의 긴밀한 협력 관계',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_rise',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '전자 상거래 관련  현금 유동성 증권화에 최적화 됨.',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
