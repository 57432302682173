module.exports = {
  title: 'CMT-The CyberMiles Token',
  'meta-description': 'CMT is the native crypto token currency being created to be integrated with and used on the CyberMiles and decentralized applications on the network. CyberMiles blockchain aims to be the public blockchain for all e-commerce transactions.',
  script: 'https://files.coinmarketcap.com/static/widget/currency.js',
  banner: {
    title: 'CyberMiles Token',
    backgroundImage: 'bannerCoin',
    description: 'The native crypto token currency',
    buttons: {
      number: '0',
    },
  },
  benefits: {
    number: '1',
    columns0: {
      title: 'Benefits of CMT Technology',
      description: {
        richText: 'null',
        description:
          'null',
      },
      number: '4',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'smart',
            link: 'null',
          },
        },
        title: 'SMART',
        description: {
          richText: 'null',
          description:
            'CyberMiles has built a library of smart contract templates for e-commerce applications.',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'fast',
            link: 'null',
          },
        },
        title: 'FAST',
        description: {
          richText: 'null',
          description: 'On top of coin transaction speed, our Virtual Machine allows Smart Contracts to be calculated with an optimized speed for business needs.',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'safe',
            link: 'null',
          },
        },
        title: 'SAFE',
        description: {
          richText: 'null',
          description:
            'The blockchain has created a perfect combination of scalability, decentralization and safety. Also, CyberMiles has implemented a “pre-defense” and “post recovery” mechanism as an additional protection.',
        },
        buttons: {
          number: '0',
        },
      },
      column3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'free',
            link: 'null',
          },
        },
        title: 'FREE',
        description: {
          richText: 'null',
          description:
            'CyberMiles waives transaction fees for most common operations. It achieves this without sacrificing security of the network.',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },

  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: 'Start Earning CMT Through Delegating ',
      description: {
        richText: 'null',
        description:
          'CyberMiles runs a DPoS protocol, in which you can stake your CMTs to participate in the network’s governance. In exchange, you will receive CMTs minted through systematic inflation and transaction fees in every new block created. ',
      },
      backgroundImage: 'null',
      buttons: {
        number: '2',
        button0: {          
          link: '/cmt/cmt-cube/',
          label: 'Delegate By CMT Cube',
          icon: 'null',
        },
        button1: {          
          link: '/cmt/validator/',
          label: 'Validator Program',
          icon: 'null',
        },
      },
    },
  },
  coins: {
    number: '1',
    coins0: {
      images: {
        number: '0',
      },
      title: 'CMT Market Performance',
      description: {
        richText: 'null',
        description:
          'We are committed to the long term health and stability of CMT markets.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
      number: '3',
      columns:'3',
      column0: {
        images: {
          number: '0',
        },
        title: 'Market Cap',
        description: {
          richText: 'null',
          description:
            'null',
        },
        buttons: {
          number: '0',
        },
        data: {
          key: "market_cap",
          unit: "USD",
        }
      },
      column1: {
        images: {
          number: '0',
        },
        title: 'Price',
        description: {
          richText: 'null',
          description:
            'null',
        },
        buttons: {
          number: '0',
        },
        data: {
          key: "price",
          unit: "CMT/USD",
        }
      },
      column2: {
        images: {
          number: '0',
        },
        title: 'Volume (24h)',
        description: {
          richText: 'null',
          description:
            'null',
        },
        buttons: {
          number: '0',
        },
        data: {
          key: "volume",
          unit: "USD",
        }
      }
    },
  },
  boxes: {
    number: '1',
    boxes0: {
      title: 'Purchase CMT',
      description: {
        richText: 'null',
        description: 'CMT is available at the following exchanges:',
      },
      number: '12',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'lastSection',
      box0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'cobinhood',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://cobinhood.com/trade/CMT-BTC">BTC</a>, <a href="https://cobinhood.com/trade/CMT-ETH">ETH</a>, <a href="https://cobinhood.com/trade/CMT-USDT">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'binance',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.binance.com/trade.html?symbol=CMT_BTC">BTC</a>, <a href="https://www.binance.com/trade.html?symbol=CMT_ETH">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'lbank',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.lbank.info/exchange.html?asset=cmt&post=btc">BTC</a>, <a href="https://www.lbank.info/exchange.html?asset=cmt&post=eth">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'huobi',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.hbg.com/zh-cn/cmt_btc/exchange/">BTC</a>, <a href="https://www.hbg.com/zh-cn/cmt_eth/exchange/">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box4: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'coinbene',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.coinbene.com/#/?pairId=36">ETH</a>, <a href="https://www.coinbene.com/#/?pairId=24">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box5: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'dragonex',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://dragonex.io/trade/index/cmt_eth">ETH</a>, <a href="https://dragonex.io/zh-hant/trade/index/cmt_usdt">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box6: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'bibox',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.bibox.com/exchange?coinPair=CMT_BTC">BTC</a>, <a href="https://www.bibox.com/exchange?coinPair=CMT_ETH">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box7: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'koinex',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://koinex.in/exchange/inr/cyber_miles">INR</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box8: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'okex',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.okex.com/spot/trade#product=cmt_btc">BTC</a>, <a href="https://www.okex.com/spot/trade#product=cmt_eth">ETH</a>, <a href="https://www.okex.com/spot/trade#product=cmt_usdt">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box9: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'coincoming',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://trade.coincoming.com/deal/pDealcent?coinid=10038">BTC</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box10: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'bithumb',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://en.bithumb.com/trade/order/CMT">KRW</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box11: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'changenow',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://changenow.io/exchange?amount=1&to=cmt">BTC</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
