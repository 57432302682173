module.exports = {
  title: 'CyberMiles App',
  'meta-description': 'Securely manage your digital assets with the CyberMiles mobile blockchain wallet. Store and trade multiple currencies, receive real-time market updates.',
  banner: {
    title: 'CMT 월렛',
    backgroundImage: 'bannerCMT',
    description: '디지털 자산 월렛',
    buttons: {
      number: '0',
    },
  },
  imageTextHero: {
    section0: {
      image: {
        gif: 'null',
        path: 'cmtWalletOne',
      },
      title: '모바일 블록체인 월렛',
      description: {
        richtext: 'null',
        description: '다운 받기',
      },
      backgroundImage: 'null',
      imageButtons: {
        number: '2',
        button0: {
          icon: 'appStore',
          link: 'https://itunes.apple.com/us/app/id1418378426',
        },
        button1: {
          icon: 'googlePlay',
          link: 'https://play.google.com/store/apps/details?id=io.cybermiles.cmtwallet',
        },
        // button2: {
        //   icon: 'walletQR',
        //   link: '/',
        // },
      },
      buttons: {
        number: '2',
        button0: {
          label: 'Android Download',
          link: 'https://s3.cn-north-1.amazonaws.com.cn/cmtwallet/android/CMT-Wallet-Android.apk',
        },
        button1: {
          label: 'IOS Download',
          link: 'itms-services://?action=download-manifest&url=https://s3.cn-north-1.amazonaws.com.cn/cmtwallet/ios/manifest.plist',
        },
      },
    },
  },
  imageText: {
    section0: {
      image: {
        gif: 'null',
        path: 'cmtWalletTwo',
      },
      title: '안전하고 편리하고 강력하다',
      description: {
        richtext: 'null',
        description:
          'CMT 월렛은 CyberMiles 모바일 라이트 지갑으로 간단하고 안전하며 강력한 디지털 자산 관리 도구를 사용자에게 제공하기 위해 노력하고 있습니다. 사용자는 지갑 생성 / 가져 오기, 안전한 디지털 자산 관리, 편리한 트랜잭션 실행, 실시간 시장 업데이트 등의 자유를 경험할 수 있으며 CMT 월렛을 통해 CyberMiles DApps의 잠재력을 체험 할 수 있습니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  features: {
    number: '1',
    columns0: {
      title: ' 장점 및 특징',
      description: {
        richText: 'null',
        description:
          'null',
      },
      number: '4',
      columns: '2',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresKey',
            link: 'null',
          },
        },
        title: '유저가 직접 프라이빗 키를 갖고 있으므로 안정성이 강화 됩니다',
        description: {
          richText: 'null',
          description:
            '암호화 된 프라이빗 키는 장치의 로컬 샌드 박스 시스템에 안전하게 저장됩니다. 자산의 분실이나 도난을 방지하기 위해 여러 월렛 백업 옵션도 제공됩니다.',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresPhone',
            link: 'null',
          },
        },
        title: '쉽게 사용하며, 편하게 거래 할 수 있다',
        description: {
          richText: 'null',
          description: '거래 속도가 빠르며, gas 비용 없습니다 ',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresWorld',
            link: 'null',
          },
        },
        title: '강력한 DApp 브라우저',
        description: {
          richText: 'null',
          description:
            '스마트 계약 기술로 구축 된 탈중앙화 / 분산화 애플리케이션 브라우저. BlockTonic / Tokeneed / TCandy / CMT등과 같은 DApps를 지원합니다.',
        },
        buttons: {
          number: '0',
        },
      },
      column3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresLock',
            link: 'null',
          },
        },
        title: '원스톱 디지털 자산 관리',
        description: {
          richText: 'null',
          description:
            'CMT같은 원스톱 디지털 자산 관리 리스트를 가질 수 있습니다. CMT 실시간 시장 가격이 업데이트 됩니다.imToken，JAXX，Metamask，CMT Cube에서 가져오기 기능이 있습니다. 가격은 Coinbase，Binance，Huobi，OKex，Bittrex，Kraken，Poloniex등의 거래소와 동기화 됩니다.',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: 'CMT CyberMiles Token',
      description: {
        richText: 'true',
        description:
          'CMT가 CyberMiles 네트워크를 유지하기 위해 사람들을 장려하는것은 주요 목적 중의 하나 입니다.CyberMiles 블록 체인은 전자 상거래 응용 프로그램을 위해 특별히 최적화되어 있으므로 CMT의 주요 기능은 전자 상거래 거래 문제를 해결하는 것입니다. CMT는 Huobi, Okex, Binance 등 많은  거래소에서 거래되고 있습니다.',
      },
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '1',
        button0: {
          link: '/cmt/overview/',
          label: '더 알아보기',
          icon: 'null',
        },
      },
    },
  },
}
