module.exports = {
  title: 'Team & Advisors',
  'meta-description': 'The Cybermiles management and advisory team are made up of scientists, developers, and entrepreneurs giving dynamics and years of experience to the company.',
  banner: {
    title: '팀 & 어드바이저 소개',
    backgroundImage: 'bannerGlobe',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  teamMembers: {
    number: '2',
    teamMembers0: {
      title: '팀 & 어드바이저 소개',
      backgroundImage: 'null',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      number: '5',
      teamMember0: {
        image: 'lucas',
        name: '루카스 루, 박사',
        linkedin: 'https://www.linkedin.com/in/sixwings/',
        title: '설립자',
        description: {
          richtext: 'null',
          description:
            'Lucas는 2005 년 SMU에서 Particle Physics로 PHD 학위를 받았습니다. CERN에서 Higgs Particle의 이론 및 실험 연구에 참여했습니다. 루카스 박사는 이전에 뉴욕 증권 거래소 (NYSE)에 공개 된 회사의 라이트 인 더 박스 (Light In Inbox)의 공동 설립자이자 CTO였으며, 그 이전에는 루비스가 알리바바의 타오 모바일 (Taobao) 모바일 플랫폼의 첫 번째 GM이었고 다른 Alibaba 사업의 GM이었습니다.루카스는 2014 년 에 5miles를 설립했습니다.5miles는 미국의 탑10 전자 상거래 플랫폼 입니다.2017년8월에 5xlab를 설립했으며 CyberMiles 프로젝트를 시작했습니다.',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember1: {
        image: 'michael',
        name: '마이클 유안, 박사',
        linkedin: 'https://www.linkedin.com/in/myuan/',
        title: '최고 과학자',
        description: {
          richtext: 'null',
          description:
            'Michael은 텍사스 오스틴 대학 (University of Texas)에서 천체 물리학 (Astrophysics) 박사 학위를 받았습니다. 그는 Prentice Hall, Addison-Wesley 및 O’Reilly에 의해 출판 된 소프트웨어 개발에 관한 다섯 권의 책을 저술 한 저자입니다. Michael은 Firefox, Fedora, JBoss 등과 같은 대규모 오픈 소스 프로젝트의 적극적인 코드 커미터였습니다. 그는 대규모 사업 및 모바일 소프트웨어 전문가이며 미국 정부가 자금을 지원하는 여러 연구 프로젝트의 수석 연구원이었습니다.',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember2: {
        image: 'tim',
        name: '티모시 맥칼럼',
        linkedin: 'https://www.linkedin.com/in/tim-mccallum-2226413a/',
        title: '핵심 개발자',
        description: {
          richtext: 'null',
          description:
            'Timothy McCallum은 비즈니스를위한 블록 체인 구현을 전문으로합니다. Fintech 프로그래밍 전문가인 Timothy의 가장 최근 프로젝트는 지방 정부를위한 금융 데이터 이송 작업이었습니다. Timothy는 2015년에 AMP Tomorrow Maker로 선정되었습니다. 그의 작업은 복잡한 문제에 대한 특별한 해결책을 모색하는데 중점을 둡니다. Timothy는 Google Summer of Code의 저명한 스승이며 2014 년 Google의 고객이었으며, 실리콘 밸리에서 전 세계 소프트웨어 개발자와 공동 작업을하면서 시간을 보냈습니다.',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember3: {
        image: 'alex',
        name: '알렉스 라우',
        linkedin: 'https://www.linkedin.com/in/avengermojo/',
        title: '핵심 개발자',
        description: {
          richtext: 'null',
          description:
            'Avengers MoJo는 Alex의 온라인 별명입니다. 그는 1999 년 북 텍사스 대학에서 소프트웨어 컴퓨터 과학 학위를 취득했습니다. Alex는 중국으로 옮기기 전에 Nortel Network에서 근무했습니다. 그는 베이징과 대만의 SUSE Linux의 R & D 관리자였으며 Symbio Mobile의 CTO였습니다. 그는 SUSE의 ceph 분산 파일 시스템 컨설턴트 및 WeBank 및 5miles의 Blockchain 컨설턴트로 일했습니다.',
        },
        buttons: {
          number: '0',
        },
      },
      
//       teamMember5: {
//         image: 'garwin',
//         name: '가윈 찬',
//         linkedin: 'https://www.linkedin.com/in/garwin-chan-b8378a1/',
//         title: '재무책임자',
//         description: {
//           richtext: 'null',
//           description:
//             'Garwin은 현재 5miles의 CFO입니다. 이전에는 F Hounder (창업자 그룹의 사모 펀드)의 전무 이사였습니다. 그 전에는 세계적인 투자 회사인 Bain Capital의 부사장이었습니다. Garwin은 2003 년 Harvard College에서 우등으로 졸업했으며 2008 년 Harvard Business School에서 MBA 학위를 받았습니다.',
//         },
//         buttons: {
//           number: '0',
//         },
//       },
      teamMember4: {
        image: 'mark',
        name: '마크 브린컬호프',
        linkedin: 'https://www.linkedin.com/in/thinkbrink/',
        title: '부사장',
        description: {
          richtext: 'null',
          description:
            '마크 브린컬호프는 지역 사회와 홍보에 대한 현대적인 접근 방식, 고객과 브랜드를 연결하는 파트너십 구축 및 스토리 텔링에 대한 열정을 가진 창업 자문 및 커뮤니케이션 전략가입니다. 결과 주도 PR 분야에서 15 년 이상의 경력을 쌓은 Brinkerhoff는 혁신적이고 빠르게 성장하는 신생 업체, 특히 신흥 기술 분야의 비즈니스 마케팅 및 브랜드 커뮤니케이션 분야의 선두 주자가되었습니다.',
        },
        buttons: {
          number: '0',
        },
      },
    },
    teamMembers1: {
      title: '고문',
      backgroundImage: 'bannerCube',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      number: '5',
      teamMember0: {
        image: 'garwin',
        name: '가윈 찬',
        linkedin: 'https://www.linkedin.com/in/garwin-chan-b8378a1/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'Garwin은 5miles 전(前） CFO입니다. 이전에는 F Hounder (창업자 그룹의 사모 펀드)의 전무 이사였습니다. 그 전에는 세계적인 투자 회사인 Bain Capital의 부사장이었습니다. Garwin은 2003 년 Harvard College에서 우등으로 졸업했으며 2008 년 Harvard Business School에서 MBA 학위를 받았습니다.',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember1: {
        image: 'marc',
        name: '마크 플레리',
        linkedin: 'https://en.wikipedia.org/wiki/Marc_Fleury',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'Dr. Fleury는 소프트웨어 자유와 탈중앙화 사업의 선구자입니다. 오픈 소스 Java 애플리케이션 서버인 JBoss의 제작자이며 Red Hat Inc.의 ex-EVP입니다. 그는 초기 암포화폐의 사상 리더이며 투자자이기도합니다. Marc은 파리의 École Polytechnique의 물리학 박사 학위와  École Normale Supérieure의 이론 물리학 석사 학위를 취득했습니다.',
        },
        buttons: {
          number: '0',
        },
      },
//       teamMember2: {
//         image: 'malachi',
//         name: '말라치 보이얼스',
//         linkedin: 'https://www.linkedin.com/in/malachi-boyuls-5b486b90/',
//         title: 'null',
//         description: {
//           richtext: 'null',
//           description:
//             'Malachi는 St.Augustus Capital Partners의 파트너로, 기술, 에너지, 부동산, 금융 서비스와 같은 산업 분야의 고객에게 컨설팅 서비스와 전략적 조언을 제공합니다. 이전에 그는 Dallas at Gibson, Dunn, & Crutcher LLP 에서 변호사로 재직했으며, 그곳에서 독점 금지, 에너지, 증권, 지적 재산권 등 회사의 규제 그룹에서 변호사로 일했으며, 뉴욕 대학에서 법학과를 졸업했습니다.',
//         },
//         buttons: {
//           number: '0',
//         },
//       },
      teamMember2: {
        image: 'george',
        name: '조지 첸',
        linkedin: 'https://www.linkedin.com/in/george-chen-ab9106/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'George는 현재 5miles 의(前） CMO입니다. 5miles에 가입하기 전에 그는 중국에서 가장 큰 광고 플랫폼 및 디지털 마케팅 대행사인 Alleys에서 V.P였습니다. 제품 개발 담당 수십 년에 걸친 마케팅 경험, 특히 디지털 분야에서 그는 Google의 자회사인 DoubleClick의 수석 엔지니어였습니다. Chen은 라이스 대학교 (Rice University)에서 물리학 석사 학위를, 중국 과학 기술 대학교 (University of Science and Technology)에서 학사 학위를 취득했습니다.',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember3: {
        image: 'frank',
        name: '프랜크 리',
        linkedin: 'https://www.linkedin.com/in/frank-lee-873902106/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            '몇몇 신생 기업과 엔젤 투자자의 설립자 입니다. 세계 최초의 Litecoin ASIC 마이너를 성공적으로 개발했습니다. 두 회사는 MI 생태계에 포함됩니다.  게임, VR, AR, IOT등 다양한 분야의 투자 및 공동 창업하였으며, Tsinghua University에서 전기 공학과를 졸업하였습니다.',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember4: {
        image: 'ash',
        name: '애쉬 한',
        linkedin: 'https://www.linkedin.com/in/ashhan/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            '애쉬 한은 엔젤 후원자이며 블록 체인의 전도사입니다. 그는 또한 정부 기관, 은행, 금융 기관 등 개인 가입자를 보유한 국내 최대의 블록 체인인 Finector의 CEO이기도 합니다. 그는 2012년부터 엔젤 투자자, 공동 창업자, 고문, 지역 공동체 조직, 저자, 블록 체인의 경제와 기술에 대한 깊은 이해를 가진 대중 연설가로 블록 체인 산업에 전념해 왔습니다.',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  partnerBoxes: {
    number: '2',
    boxes0: {
      title: 'Partners',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '8',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      box0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'fivemiles',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'blockchainVentures',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'linkvc',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'collinstar',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box4: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'aware',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box5: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'chainedbox',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box6: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'cybervein',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box7: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'hyperpay',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
    },
    boxes1: {
      title: 'Counsel',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '2',
      columns: '2',
      buttons: {
        number: '0',
      },
      backgroundImage: 'shortLastSection',
      box0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'yeewhy',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
//       box1: {
//         images: {
//           number: '1',
//           image0: {
//             gif: 'null',
//             path: 'deloitte',
//             link: 'null',
//           },
//         },
//         title: 'null',
//         description: {
//           richText: 'true',
//           description: 'null',
//         },
//         buttons: {
//           number: '0',
//         },
//       },
      box1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'lockeLord',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
