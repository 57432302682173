module.exports = {
  title: 'Meetups',
  'meta-description': 'CyberMiles Meetups',
  banner: {
    title: 'Meetups',
    backgroundImage: 'bannerGlobe',
    description:
      'null',
    buttons: {
      number: '0',
    }
  },
}
