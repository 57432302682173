module.exports = {
  title: "Technical Benchmark",
  "meta-description": "Welcome to technical benchmark",
  banner: {
    title: 'CyberMiles Technical Benchmark',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    centered0: {
      title: "Challenges for Traditional E-commerce",
      description: "While there is a clear demand, it is difficult for traditional e-commerce web sites to accept cryptocurrency payments. Traditional payment processing companies supports one or two mainstream cryptocurrencies and require high fees for converting into and out of fiat currencies (e.g., USDs). The cryptocurrency payment user experience is also poor for consumers, making them uncompetitive against traditional payment methods such as credit cards.", 
      button: {
        label: "null",
      }      
    },
    centered1: {
      title: "CyberMiles Solution",
      description: "The CyberMiles payment gateway is a SaaS service offered by our partners. It can be embedded into any e-commerce web site to seamlessly accept customer payments in almost any cryptocurrency in today’s market, including payments in USD-pegged stable coins.",      
      button: {
        label: "null",
      } 
    }
  },
  columns: {
    title: "Technology",
    description: "null",
    number: "3",
    column0: {
      title: `CyberMiles’ programming language, Lity, and the CyberMiles Virtual Machine (CVM) are powerful blockchain infrastructure technologies.`,
      icon: "bitcoin"  
    },
    column1: {
      title: `The table below shows the performance differences between the CVM and the Ethereum Virtual Machine (EVM) – in various application scenarios, under the same condition. You can also see how CVM performance allows it to complete mission critical tasks that were impossible on Ethereum.`,
      icon: "blockchain",
    },
    column2: {
      title: `CyberMiles technology, coupled with its DPoS consensus mechanism, enable you to run business applications much faster and cheaper.`,
      icon: "support",   
    },
  },
  description: {
    p1: `CyberMiles’ programming language, Lity, and the CyberMiles Virtual Machine (CVM) are powerful blockchain infrastructure technologies.`,
    p2: `The table below shows the performance differences between the CVM and the Ethereum Virtual Machine (EVM) – in various application scenarios, under the same condition. You can also see how CVM performance allows it to complete mission critical tasks that were impossible on Ethereum.`,
    p3: `CyberMiles technology, coupled with its DPoS consensus mechanism, enable you to run business applications much faster and cheaper.`,
  },
  table: {
    col1: {
      h3: `Processing Time Computation`,
      rows: [`Addition`, `Multiplication`, `Bubble Sorting`, `Scrypt`],
    },
    col2: {
      h3: `Quantities`,
      rows: [`Addition`, `Multiplication`, `Bubble Sorting`, `Scrypt`],
    },
    col3: {
      h3: `Ethereum/EVM`,
      rows: [`Addition`, `Multiplication`, `Bubble Sorting`, `Scrypt`],
    },
    col4: {
      h3: `CyberMiles/CVM`,
      rows: [`Addition`, `Multiplication`, `Bubble Sorting`, `Scrypt`],
    },
    col5: {
      h3: `Ratio`,
      rows: [`Addition`, `Multiplication`, `Bubble Sorting`, `Scrypt`],
    },
  },
  social: {
    facebook: `www.facebook.com`,
    twitter: `www.facebook.com`,
    linkedIn: `www.facebook.com`,
    reddit: `www.facebook.com`,
  },
  tabs: {
    title: "CVM vs EVM",
    tab0: {
      tab: `Addition`,
      h3: `CVM vs EVM for Arithmetic Additions`,
      video: `https://www.youtube.com/embed/4SeOFLmU4bg`,
      videoDescription: `The CVM performs 20 million additions in 3.26 seconds while the EVM takes 35.3 seconds for the same amount of computation. The virtual machine startup time is estimated to be 0.21s in this test.`,
      conclusion: {
        h3: `Conclusion`,
        p1: `The CVM is 11.5 times faster than the EVM, when it comes arithmetic additions.`,
        p2: `In addition, the CyberMiles DPoS consensus is at at least 100 times faster than Ethereum PoW. That means CyberMiles can process 100x more transactions per second, and each transaction can carry 11.5x more computation load.`,
      },
      comparison: {
        description: `CVM can be 1150 times faster than EVM for additions.`,
        evmButton: `https://gist.github.com/juntao/0249a9bf7bd332ad01959973398f6a33#`,
        cvmButton: `https://gist.github.com/juntao/77022bf5c27550d7175897db69f8a8a2`,
      },
    },
    tab1: {
      tab: `Multiplication`,
      h3: `CVM vs EVM for Arithmetic Additions`,
      video: `https://www.youtube.com/embed/4SeOFLmU4bg`,
      videoDescription: `The CVM performs 20 million additions in 3.26 seconds while the EVM takes 35.3 seconds for the same amount of computation. The virtual machine startup time is estimated to be 0.21s in this test.`,
      conclusion: {
        h3: `Conclusion`,
        p1: `The CVM is 11.5 times faster than the EVM, when it comes arithmetic additions.`,
        p2: `In addition, the CyberMiles DPoS consensus is at at least 100 times faster than Ethereum PoW. That means CyberMiles can process 100x more transactions per second, and each transaction can carry 11.5x more computation load.`,
      },
      comparison: {
        description: `CVM can be 1150 times faster than EVM for additions.`,
        evmButton: `https://gist.github.com/juntao/0249a9bf7bd332ad01959973398f6a33#`,
        cvmButton: `https://gist.github.com/juntao/77022bf5c27550d7175897db69f8a8a2`,
      },
    },
    tab2: {
      tab: `Bubble Sort`,
      h3: `CVM vs EVM for Arithmetic Additions`,
      video: `https://www.youtube.com/embed/4SeOFLmU4bg`,
      videoDescription: `The CVM performs 20 million additions in 3.26 seconds while the EVM takes 35.3 seconds for the same amount of computation. The virtual machine startup time is estimated to be 0.21s in this test.`,
      conclusion: {
        h3: `Conclusion`,
        p1: `The CVM is 11.5 times faster than the EVM, when it comes arithmetic additions.`,
        p2: `In addition, the CyberMiles DPoS consensus is at at least 100 times faster than Ethereum PoW. That means CyberMiles can process 100x more transactions per second, and each transaction can carry 11.5x more computation load.`,
      },
      comparison: {
        description: `CVM can be 1150 times faster than EVM for additions.`,
        evmButton: `https://gist.github.com/juntao/0249a9bf7bd332ad01959973398f6a33#`,
        cvmButton: `https://gist.github.com/juntao/77022bf5c27550d7175897db69f8a8a2`,
      },
    },
    tab3: {
      tab: `Scrypt`,
      h3: `CVM vs EVM for Arithmetic Additions`,
      video: `https://www.youtube.com/embed/4SeOFLmU4bg`,
      videoDescription: `The CVM performs 20 million additions in 3.26 seconds while the EVM takes 35.3 seconds for the same amount of computation. The virtual machine startup time is estimated to be 0.21s in this test.`,
      conclusion: {
        h3: `Conclusion`,
        p1: `The CVM is 11.5 times faster than the EVM, when it comes arithmetic additions.`,
        p2: `In addition, the CyberMiles DPoS consensus is at at least 100 times faster than Ethereum PoW. That means CyberMiles can process 100x more transactions per second, and each transaction can carry 11.5x more computation load.`,
      },
      comparison: {
        description: `CVM can be 1150 times faster than EVM for additions.`,
        evmButton: `https://gist.github.com/juntao/0249a9bf7bd332ad01959973398f6a33#`,
        cvmButton: `https://gist.github.com/juntao/77022bf5c27550d7175897db69f8a8a2`,
      },
    },
  },
}
