module.exports = {
  title: 'CyberMiles|Blockchain technology for E-commerce',
  'meta-description':
    'CyberMiles is a smart, fast, secure, and free e-commerce blockchain platform with lightning-fast transaction speed and smart contract templates.',
  banner: {
    title: 'CyberMiles',
    backgroundImage: 'bannerCube',
    description: 'Eコマースのためのブロックチェーン',
    buttons: {
      number: '1',
      button0: {
        label: 'CyberMilesとは何か',
        link: '/',
        icon: 'play',
      },
    },
  },
  imageText: {
    section0: {
      image: {
        gif: 'true',
        path: 'cube',
      },
      title: 'Eコマースに最適化されたブロックチェーン',
      description: {
        richtext: 'null',
        description:
          'CyberMilesは、eコマース企業がブロックチェーン上にビジネスを簡単に展開できるよう、高度にカスタマイズされたソリューションを構築しました。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: 'ユースケース',
          link: 'ecommerce-solutions/finance/payment-gateway/',
        },
      },
    },
    section1: {
      image: {
        gif: 'true',
        path: 'coin',
      },
      title: '可能性に満ちたブロックチェーン技術',
      description: {
        richtext: 'null',
        description:
          'CyberMilesはスマートで、高速で安全なブロックチェーンネットワークです。そして、すべてのユーザーが無料で利用できます。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: 'CMTを購入',
          link: 'cmt/overview/',
        },
      },
    },
    section2: {
      image: {
        gif: 'true',
        path: 'lity',
      },
      title: 'ブロックチェーン上で簡単な開発',
      description: {
        richtext: 'null',
        description:
          'CyberMilesはDApp開発のための強力なインフラを構築しました。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: ' CyberMilesで開発する',
          link: 'developer-portal/developer-hub',
        },
      },
    },
  },
  roadmap: {
    title: 'ロードマップ',
    description: {
      richtext: 'null',
      description: 'null',
    },
    backgroundImage: 'lastSection',
    buttons: {
      number: '1',
      button0: {
        label: 'さらに見る',
        link: '/about-us/roadmap',
      },
    },
    events: {
      number: '5',
      event0: {
        title: 'Q2',
        outline: `The 5miles user data migration to the CyberMiles blockchain is being implemented as planned. We have started exploring e-commerce DApps. At the same time, the technical team is planning a major virtual machine upgrade to support alternative gas fee mechanisms, which will greatly reduce the DApp barrier of entry and allow far more users to experience the CyberMiles blockchain technology. Based on community’s input and suggestions, we will also adjust the reward mechanism of the DPoS protocol for better transparency.`,
        parent: 'true'
      },
      event1: {
        title: 'E-commerce DApp',
        list: {
          size: '2',
          item0: '5miles data migration',
          item1: 'Smart contract supported stable coin purchases'
        }
      },
      event2: {
        title: 'Virtual machine upgrades',
        list: {
          size: '2',
          item0: 'Secure random numbers in smart contracts',
          item1: 'Option for the contract owner to pay for gas fees'
        }
      },
      event3: {
        title: 'CyberMiles App',
        list: {
          size: '2',
          item0: 'Allow new wallet users to experience some DApps without acquiring CMTs first',
          item1: 'Staking from the wallet apps (the final decision is subject to community voting)'
        }
      },
      event4: {
        title: 'Mainnet DPoS reward mechanism adjustments',
        list: {
          size: '2',
          item0: 'Make the algorithm simpler and easier to understand',
          item1: 'Rewards during the 7-day unstaking period'
        }
      }
    }
  },
  centered: {
    centered0: {
      image: 'null',
      title: 'CyberMilesと共同で事業を行う',
      description: {
        richText: 'null',
        description:
          '良いアイデアをお持ちなら、私たちと共有しましょう！',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '連絡先',
          link: '/about-us/contact-us',
        },
      },
    },
  },
}
