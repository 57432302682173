module.exports = {
  title: 'Medium Posts',
  'meta-description': 'Medium Posts',
  banner: {
    title: 'Medium Posts',
    backgroundImage: 'bannerCube',
    description:
      'null',
    buttons: {
      number: '0',
    }
  },
}
