module.exports = {
  title: 'CyberMiles开发者门户',
  'meta-description': 'CyberMiles公链是一种安全且免费的区块链软件协议，使开发人员能够智能免费地构建去中心化应用程序（DApp）。',
  banner: {
    title: '开发者门户',
    backgroundImage: 'bannerLity',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '开发者中心',
      description: {
        richText: 'null',
        description:
          'CyberMiles是一种安全且免费的区块链软件协议，使开发人员能够智能免费地构建去中心化应用程序（DApp）。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '入门',
          link: 'https://travis.readthedocs.io/en/latest/getting-started.html',
        },
      },
    },
  },
  indexMenu: {
    number: '2',
    menu0: {
      label: '参考和SDK',
      number: '5',
      backgroundImage: 'null',
      links: {
        link0: {
          label: 'CyberMiles getting started',
          link: 'https://travis.readthedocs.io/en/latest/getting-started.html',
        },
        link1: {
          label: 'Lity getting started',
          link: 'https://www.litylang.org/getting_started/',
        },
        link2: {
          label: 'Smart contract hello world',
          link: 'https://blog.csdn.net/weixin_44597631/article/details/88352850',
        },
        link3: {
          label: 'Web3-cmt',
          link: 'https://cybermiles.github.io/web3-cmt.js/api/#example',
        },
        link4: {
          label: 'Lity or Solidity',
          link: 'https://www.litylang.org/',
        },
      },
    },
    menu1: {
      label: '工具',
      number: '5',
      links: {
        link0: {
          label: 'Venus',
          link: '/blockchain-infrastructure/venus/',
        },
        link1: {
          label: 'Europa',
          link: 'http://europa.cybermiles.io',
        },
        link2: {
          label: 'CMT Testnet Faucet',
          link: 'http://travis-faucet.cybermiles.io',
        },
        link3: {
          label: 'CyberMiles App SDK',
          link:
            'https://travis.readthedocs.io/en/latest/cmtwallet-dapp-sdk-doc.html',
        },
        link4: {
          label: 'Saturn',
          link:
            'https://github.com/CyberMiles/truffle/blob/lity/README.md',
        },
//         link4: {
//           label: 'CMT Wallet for Testnet',
//           link:
//             '/',
//         },
      },
    },
  },
  learningResources: {
    title: '学习资源',
    backgroundImage: 'null',
    articles: {
      title: '文章',
      buttons: {
        label: '更多',
        link: '/about-us/blog/',
      },
    },
    videos: {
      title: '视屏',
      buttons: {
        label: '更多',
        link: '/about-us/videos/',
      },
    },
    meetups: {
      title: '线下活动',
      buttons: {
        label: '更多',
        link: '/about-us/meetups/',
      },
    },
  },
  steps: {
    number: '1',
    steps0: {
      number: '4',
      title: '学习路径',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      step0: {
        step: '1',
        image: {
          gif: 'null',
          path: 'step1',
          link: 'null',
        },
        title: '理解CyberMiles区块链',
        description: {
          richtext: 'null',
          description:
            '要开始使用CyberMiles 区块链，第一步是部署您自己的节点。 启动节点的最简单最快捷的方法是使用快照。 我们强烈建议您运行自己的Travis节点，但您也可以从CyberMiles 基金会提供的节点直接访问RPC服务。',
        },
        buttons: {
          number: '0',
        },
      },
      step1: {
        step: '2',
        image: {
          gif: 'null',
          path: 'step2',
          link: 'null',
        },
        title: '编写智能合约',
        description: {
          richtext: 'null',
          description:
            '要在CyberMiles区块链上建立您的第一份智能合约，了解Lity至关重要。 Lity是Solidity语言的超集，更具可扩展性，高性能和安全性。',
        },
        buttons: {
          number: '0',
        },
      },
      step2: {
        step: '3',
        image: {
          gif: 'null',
          path: 'step3',
          link: 'null',
        },
        title: '建立一个简单的DApp',
        description: {
          richtext: 'null',
          description:
            '请使用Chrome 浏览器并使用Metamask for CMT和Europa，来在CyberMiles区块链上部署您的第一个DApp。 它允许您直接在浏览器中运行CyberMiles DApp，而无需运行CyberMiles节点。',
        },
        buttons: {
          number: '0',
        },
      },
      step3: {
        step: '4',
        image: {
          gif: 'null',
          path: 'step4',
          link: 'null',
        },
        title: '制作移动DApp',
        description: {
          richtext: 'null',
          description:
            '要在CyberMiles区块链上构建移动Dapp，请首先浏览CyberMiles App。 CyberMiles App 中的DApp浏览器与Metamask for CMT完全兼容，可直接将DApp迁移到CyberMiles App，甚至无需编写任何代码。',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  iconColumns: {
    number: '1',
    columns0: {
      title: '开发者社区',
      description: {
        richText: 'null',
        description:
          '与1000+开发者一起探讨',
      },
      backgroundImage: 'shortLastSection',
      number: '3',
      columns: '3',
      buttons: {
        number: '0',
      },
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'telegram',
            link: 'https://t.me/cybermilestoken',
          },
        },
        title: 'null',
        description: {
          richText: 'null',
          description:
            'Telegram',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'github',
            link: 'https://github.com/cybermiles',
          },
        },
        title: 'null',
        description: {
          richText: 'null',
          description:
            'Github',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'forum',
            link: 'https://forum.cybermiles.io/',
          },
        },
        title: 'null',
        description: {
          richText: 'null',
          description:
            'Forum',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
