module.exports = {
  title: 'Contact Us',
  'meta-description': 'Do you need any help with troubleshooting or mining? Want to talk to experts from Cybermiles support team? Contact us, we are happy to help.',
  banner: {
    title: '연락처',
    backgroundImage: 'bannerGlobe',
    description: "연락해 주세요",
    buttons: {
      number: '0',
    },
  },
  contact: {
    number: '1',
    columns0: {
      title: 'null',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '4',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      column0: {
        images: {
          number: '0',
        },
        title: '일반 문의',
        description: {
          richText: 'null',
          description: '이메일 주소 ',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'contact@cybermiles.io',
            link: 'mailto:contact@cybermiles.io',
            icon: 'null',
          },
        },
      },
      column1: {
        images: {
          number: '0',
        },
        title: '비즈니스 문의 및 합작',
        description: {
          richText: 'null',
          description: '이메일 주소',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'business@cybermiles.io.',
            link: 'mailto:business@cybermiles.io',
            icon: 'null',
          },
        },
      },
      column2: {
        images: {
          number: '0',
        },
        title: '개발자 지원',
        description: {
          richText: 'null',
          description: '이메일 주소',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'forum.cybermiles.io',
            link: 'https://forum.cybermiles.io/',
            icon: 'null',
          },
        },
      },
      column3: {
        images: {
          number: '0',
        },
        title: '미디어 관련 문의',
        description: {
          richText: 'null',
          description: '이메일주소',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'media@cybermiles.io',
            link: 'mailto:media@cybermiles.io',
            icon: 'null',
          },
        },
      },
    },
  },
  social: {
    number: '1',
    columns0: {
      title: '참여합시다',
      description: {
        richText: 'null',
        description: 'CyberMiles 커뮤니티에 가입',
      },
      number: '12',
      columns: '6',
      buttons: {
        number: '0',
      },
      backgroundImage: 'shortLastSection',
      column0: {
        images: {
          number: '0',
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'twitter',
            link: 'https://twitter.com/cybermiles',
            icon: 'twitter',
          },
        },
      },
      column1: {
        images: {
          number: '0',
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'facebook',
            link: 'https://www.facebook.com/cybermiles',
            icon: 'facebook',
          },
        },
      },
      column2: {
        images: {
          number: '0',
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'weibo',
            link: 'https://www.weibo.com/Cybermiles',
            icon: 'weibo',
          },
        },
      },
      column3: {
        images: {
          number: '0',
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'wechat',
            link: '/',
            icon: 'wechat',
          },
        },
      },
      column4: {
        images: {
          number: '0',
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'telegram',
            link: 'https://t.me/cybermilestoken',
            icon: 'telegram',
          },
        },
      },
      column5: {
        images: {
          number: '0',
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'kakaotalk',
            link: 'https://open.kakao.com/o/gl9gPJI',
            icon: 'kakao_talk',
          },
        },
      },
      column6: {
        images: {
          number: '0',
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'github',
            link: 'https://github.com/cybermiles',
            icon: 'github',
          },
        },
      },
      column7: {
        images: {
          number: '0',
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'reddit',
            link: 'https://www.reddit.com/r/CyberMiles/',
            icon: 'reddit',
          },
        },
      },
      column8: {
        images: {
          number: '0',
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'youtube',
            link: 'https://www.youtube.com/channel/UCgok7sGPWbxKAkz2ts9etNg/featured',
            icon: 'youtube',
          },
        },
      },
      column9: {
        images: {
          number: '0',
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'linkedin',
            link: 'https://www.linkedin.com/company/cybermiles/',
            icon: 'linkedin',
          },
        },
      },
      column10: {
        images: {
          number: '0',
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'medium',
            link: 'https://medium.com/cybermiles',
            icon: 'medium',
          },
        },
      },
      column11: {
        images: {
          number: '0',
        },
        title: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '1',
          button0: {
            label: 'naver blog',
            link: 'https://blog.naver.com/cybermileskr',
            icon: 'blog',
          },
        },
      },
    },
  },
}
