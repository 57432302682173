module.exports = {
  title: 'Validator Program',
  'meta-description':
    'We are recruiting validators to run supernodes for CMT rewards in exchange. Supernodes are servers ensuring the security and high performance of the blockchain.',
  banner: {
    title: 'バリデータプログラム',
    backgroundImage: 'bannerCoin',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '4',
    centered0: {
      images: {
        number: '0',
      },
      title: 'バリデータ',
      description: {
        richText: 'null',
        description:
          'バリデータは、重要なネットワークインフラを維持し、ルールを変更し、デリゲータに代わってガバナンスでの決定を行う責任があります。 CyberMilesシステムは19バリデータに限定され、最新のソフトウェアを実行する安全なサーバー、グローバルなブロックチェーンネットワークを動作させる十分な計算能力とネットワーク回線容量を提供することによって採用されるので、バリデータはデリゲータからの投票（CMTステーク）を競うことになります。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
    centered1: {
      images: {
        number: '0',
      },
      title: 'デリゲータ',
      description: {
        richText: 'null',
        description:
          'デリゲータは、自分のCMTを特定のバリデータ候補に出資することによって、他のCMT保有者やバリデータを採用・選択します。デリゲータ自身は通常、実際にブロックチェーンネットワークを作動させているコンピュータサーバーを実行することはありません。 CyberMilesネットワーク運用の初年度は、CMT Cubeの所有者のみがデリゲートすることができ、ブロック報酬を獲得できます。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
    centered2: {
      images: {
        number: '0',
      },
      title: 'CyberMiles App',
      description: {
        richText: 'true',
        description:
          `CMTユーザーは<a href="http://onelink.to/v248ze" target="_blank">CyberMiles App</a>を使用してバリデータ（スーパーノード）にCMTをステークすることでブロック報酬（CMT）を取得できます。 ネットワーク全体の現在のステーク量に基づいて、たとえば、補償率が30％のバリデータに10,000CMTをステークすれば、年利は15％になります。 これは、block heightが1,948,355に達すると計算されます。\
          <p>*同じ条件下で、CMT Cubeのステーキング報酬は CyberMiles App (CMT Wallet) よりも20％高くなります。</p>`
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
    centered3: {
      images: {
        number: '0',
      },
      title: 'CMT Cube',
      description: {
        richText: 'null',
        description:
          'CMT Cubeは、CyberMilesネットワーク運用の初年度にバリデータの選択を簡単にできるように特別に設計された汎用機です。CMTウォレット（CMTアカウントの保存と管理）と、バリデータ選択のためCMTをステークしやすいUIの両方を備えたハードウェアデバイスです。そのUIには、アカウントが受け取る最新の報酬（デリゲートのブロック報酬）も表示されます。 自宅で保管できるハードウェアウォレットは、オンラインやアプリなどのソフトウェアウォレットよりもはるかに安全なので、大量のCMTを保管するのに最適です。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '2',
        button0: {
          link: '/cmt/whitepaper/',
          label: 'CYBERMILES DPOS プロトコル',
          icon: 'null',
        },
        button1: {
          link: 'https://goo.gl/forms/I3cYxGLdJLpX14Hz2',
          label: 'GENESIS VALIDATORに申し込む',
          icon: 'null',
        },
      },
    },
  },
  accordions: {
    number: '1',
    accordion0: {
      images: {
        number: '0',
      },
      title: 'FAQ',
      description: {
        richText: 'null',
        description: 'null',
      },
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '0',
      },
      number: '11',
      drawer0: {
        title: 'CyberMilesのバリデータになるメリットはなんですか？',
        description: {
          richText: 'true',
          description:
            '<p>デリゲータは、ブロック報酬の一部をバリデータに支払います。 ブロック報酬に対する各バリデータの割合は、バリデータ補償と呼びます。 バリデータの報酬はステーキング時点で決定し、ネットワーク自体に記録され分配されます。</p><p>補償率は、他者と直接競合するので、バリデータ自身で設定します。そして、バリデータはネットワークガバナンスに参加できるということも忘れないでください。</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer1: {
        title: 'バリデータになるための条件は？',
        description: {
          richText: 'true',
          description:
            '<p>バリデータはスーパーノードを実行する必要があり、スーパーノードはブロックチェーンネットワークのパフォーマンスとセキュリティを確保するため、ネットワークサーバーによる専門的な運用が必要とされます。</p><p>ネットワークサーバーに加え、新しい候補者の立候補時に、ネットワークは候補者自身のアカウントから宣言されたステーク量の最大10％を受理し、ステーキングを開始します。</p><p>CyberMilesシステムのバリデータは19に限定されており、バリデータは最新のソフトウェアを実行する安全なサーバー、十分な計算能力、およびグローバルなブロックチェーンネットワークを動かすネットワーク帯域幅を提供することが求められます。</p><p>しかし、CyberMiles財団は19のバリデータのうちの1つになりうる候補者にも追加の認証を与えます。</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer2: {
        title: 'バリデータ選挙の候補者になるためには？',
        description: {
          richText: 'true',
          description:
            '<p>バリデータ候補者は、立候補する事をネットワーク上に宣言します。 宣言には3つの情報が含まれます：</p><ol><li>運用や管轄、データセンターの場所、セキュリティ設定、技術的な設定の情報を含む詳細情報。 CyberMiles財団はすべてのバリデータ候補を評価し、財団の基準（すなわち、情報の正確性、技術的能力、およびハードウェア/ネットワークの設定）を満たす候補者を発表します。 ただし、トークン保有者は、財団の基準を満たしているかどうかにかかわらず、どの候補者に対しても自由にステークすることができます。</li><li>ステークできるCMTの数には上限があります。1つのバリデータが大きくなりすぎて独占が発生するのを防ぐために、ネットワークプロトコルは非常に大きなバリデータを使用するデリゲータを罰します。 （詳細は、「B4.ブロック報酬とバリデータの報酬」をご覧ください）</li><li>デリゲータから要求されるバリデータの補償率。 例えば、40％というレートは、このバリデータに対してステークしたデリゲータのブロック報酬の40％が報酬としてこのバリデータに支払われることを意味します。</li></ol><p>CyberMiles財団が候補者の情報を確認します。財団が情報の正確性を確認できれば、候補者が「認証済み」であることをチェーン上に示します。CMTホルダーは、未認証のものも含め、自分が好きなバリデータ候補に対してステークすることができます。そして、財団の承認済み候補はCMTホルダーにステーキングにふさわしいという安心感があります。 </p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer3: {
        title: 'なぜCyberMilesは投票メカニズムにステーキングを採用したのですか？',
        description: {
          richText: 'true',
          description:
            '<p>バリデータ候補が上位19になるために十分なステーク量を受け取ると、バリデータになることができます。そして、それらのデリゲータはブロック報酬を受け取り始めます（約10秒ごとに）。 ブロック報酬には2種類あります：</p><ol><li>システムの年間インフレ率は8％です。 このインフレは、マイニングされたCMTとしてデリゲータに分配されます。</li><li>デリゲータは、ブロックチェーンのヘビーユーザーによって支払われた取引手数料も受け取ることができます。</li></ol><p>システムからの総ブロック報酬は、最初にデリゲータのステーク量に比例してデリゲータに割り当てられます。 システムは、バリデータへの補償率に基づいて、ブロック報酬をバリデータとデリゲータに自動的に分配します。</p><p>そして、バリデータ側の2つのルートからブロック報酬を受け取ることができます。1つ目はブロックを検証することによるステーキング報酬と、2つ目はネットワーク維持によるデリゲータからの補償です。</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer4: {
        title: 'これまでの候補者は誰ですか？?',
        description: {
          richText: 'true',
          description:
            '<p>すでに数十の知名度の高い候補者が、バリデータへの関心を表明しています。例えば、Susquehanna International Group、Great Voyage Capital、LinkVC、AlphaCoin Fund、Shuanghua Capital、IDG Capitalなどです。</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer5: {
        title: 'CMTをステークして解除するとどうなりますか？',
        description: {
          richText: 'true',
          description:
            '<p>CMTホルダー（デリゲータ）はCMTをバリデータ候補にステークします。 ステークされたCMTは取引することができず、一度ステークすると解除申請をしないと取引を行えません。再び取引できるようになるまで1週間待つ必要があります。</p><p>ステーク解除の待ち時間は、「ロングレンジ攻撃・二重支払い攻撃」などから守るためです。</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer6: {
        title: '一部のバリデータが誤動作した場合はどうなりますか？',
        description: {
          richText: 'true',
          description:
            '<p> バリデータが利用できなくなるか、他のバリデータと異なる結果を生成すると、システムはブロックごとに（10秒ごとに）総ステーク量の0.1％をバーンします。（つまり、バリデータ自身とデリゲータはCMTを失います）。12回連続でスラッシュを起こすと、システムはバリデータを削除し、次の候補をバリデータとしてプロモートします。</p><p>削除されたバリデータはスラッシュロスを被ることはありませんが、そのいずれのデリゲータもブロック報酬を獲得できません。そのデリゲータはステーク解除を申請し、そして1週間のステーク解除待ち時間の後にCMTを再びステークすることができます。</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer7: {
        title:
          'どのようにしてバリデータなどからのブロックチェーンへの潜在的な攻撃（例えば51％攻撃）を防ぎますか？',
        description: {
          richText: 'true',
          description:
            '<p>CyberMilesプロトコルは、バリデータによる独占を防ぎます。1つのバリデータのステーク量がネットワークの総ステーク量の12％を超えると、このバリデータによってネットワークが不安定になる可能性があります。 そのため、ネットワークがブロック報酬を割り当てる際に、12%の閾値を越えるデリゲータのステークに対してブロック報酬を減らします。 この閾値は、大規模なCMT保有者が複数のバリデータを多様化し、関心を持たせることを促進し、バリデータ達に最大ステーク量を制限することを促します。各バリデータの投票権を計算するアルゴリズムは次のとおりです。</p><ol><li>各バリデータのステーク量に従って投票権を割り当てますが、それぞれの上限は12％です。 例えば、バリデータAがステーク量の5％を保有している場合、彼は5％の投票権を得ます。 バリデータBがステーク量の20％を保有している場合、彼は12％の投票権を得ます。</li><li>投票権が残っている場合は、各バリデータの実際のステーク量に基づいて2回目の割り当てを行います。</li></ol>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer8: {
        title:
          'システムがバリデータを拒否した場合、どうなりますか？',
        description: {
          richText: 'true',
          description:
            '<p>19の公式バリデータの他に、ステークしているCMT量が19番目のバリデータより少ないバリデータ候補がいます。 それらはバックアップバリデータです。 バックアップが19番目のバリデータを追い越すのに十分なステーク量を保有する可能性は将来的に高いでしょう。既存のバリデータが罰せられるか、または削除された場合、バリデータとしての機能を完全に停止します。 そのような場合、バックアップはバリデータに昇格し、前から2番目にいた候補がバックアップに昇格します。</p><p>バックアップバリデータは、ステーキングによるCMTの報酬を獲得できません。 彼らは通常、エコシステムへの新規参入者または日和見主義者です。財団はネットワークの安定性を維持するために複数のバックアップバリデータ候補を稼働させています。</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer9: {
        title: 'CMT Cubeで保有できるCMTの量は？',
        description: {
          richText: 'true',
          description:
            '<p>1つのCMT Cubeで最小1,000CMT、最大100,000CMTを保有することができます。</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer10: {
        title: 'CMT Cubeはどこで手に入りますか？',
        description: {
          richText: 'true',
          description:
            '<p>CMT Cubeは <a href="https://www.blocktonic.io/item/583" style="color: blue">こちらで</a> 5/31に998CMTで販売されます。そして、300日以内に代金の100％をCyberMiles財団が払い戻します。</p>',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
