module.exports = {
  title: 'CyberMiles团队 & 顾问',
  'meta-description': '团队 & 顾问',
  banner: {
    title: '团队 & 顾问',
    backgroundImage: 'bannerGlobe',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  teamMembers: {
    number: '2',
    teamMembers0: {
      title: '团队',
      backgroundImage: 'null',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      number: '5',
      teamMember0: {
        image: 'lucas',
        name: 'Lucas Lu, PhD',
        linkedin: 'https://www.linkedin.com/in/sixwings/',
        title: '创始人',
        description: {
          richtext: 'null',
          description:
            'Lucas Lu于2005年获美国南卫里公会大学物理学博士学位，曾任于欧洲核子研究组（CERN），参与希格斯粒子的理论和实验研究。他是纽约交易中心上市公司兰亭集势的联合创始人，曾任首席技术官。此前，他任职于阿里巴巴，是手机淘宝首任总经理、华数淘宝（合资）公司总经理。他于2014年创立5miles。',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember1: {
        image: 'michael',
        name: 'Michael Yuan, PhD',
        linkedin: 'https://www.linkedin.com/in/myuan/',
        title: 'Chief Scientist',
        description: {
          richtext: 'null',
          description:
            'Michael Yuan拥有美国德州大学奥斯汀分校天体物理学博士学位，并撰写了5本关于软件开发的书籍，由美国普伦蒂斯·霍尔出版社、艾迪生-韦斯利出版公司及奥莱利出版公司出版。他积极为大型开源项目贡献编码，如火狐浏览器、Fedora和JBoss等项目。他是企业和移动软件专家，曾任多项美国政府支持的研究项目的负责人。',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember2: {
        image: 'tim',
        name: 'Timothy McCallum',
        linkedin: 'https://www.linkedin.com/in/tim-mccallum-2226413a/',
        title: 'Core Developer',
        description: {
          richtext: 'null',
          description:
            'Timothy McCallum专门研究区块链在商业中的实现。他是金融科技编程专家，最新的项目是帮助地方政府进行金融数据迁移。2015年，他获得AMP明日基金奖。他的工作中心是为复杂的问题找到不同寻常的解决方案。Timothy McCallum是谷歌开源项目Summer of Code的著名导师。2014年曾作为谷歌的嘉宾，在硅谷与来自世界各地的软件开发人员合作。',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember3: {
        image: 'alex',
        name: 'Alex Lau',
        linkedin: 'https://www.linkedin.com/in/avengermojo/',
        title: 'Core Developer',
        description: {
          richtext: 'null',
          description:
            'Alex Lau的网名为AvengerMoJo。他于1999年获得美国北德克萨斯大学软件计算机科学学位，曾就职于加拿大北电网络有限公司，后移居中国，任SUSE Linux系统北京/台湾研发经理及Symbio Mobile公司首席技术官。Alex Lau为SUSE的ceph分布式文件系统提供长期咨询服务，还担任微众银行的区块链顾问。',
        },
        buttons: {
          number: '0',
        },
      },
      
//       teamMember5: {
//         image: 'garwin',
//         name: 'Garwin Chan',
//         linkedin: 'https://www.linkedin.com/in/garwin-chan-b8378a1/',
//         title: 'CFO',
//         description: {
//           richtext: 'null',
//           description:
//             'Garwin Chan目前担任5miles的首席财务官。他曾是方正和生投资有限责任公司(北大方正集团下的私募投资基金公司)的董事总经理。此前，他曾任全球领先的投资公司贝恩资本副总裁。Garwin Chan在2003年以优异的成绩毕业于哈佛大学，并于2008年获得哈佛商学院MBA学位。',
//         },
//         buttons: {
//           number: '0',
//         },
//       },
      teamMember4: {
        image: 'mark',
        name: 'Mark Brinkerhoff',
        linkedin: 'https://www.linkedin.com/in/thinkbrink/',
        title: 'VP of Communication',
        description: {
          richtext: 'null',
          description:
            'Mark Brinkerhoff是一名创业顾问和公关策略家，以当代的方式处理公共关系，热衷建立伙伴关系，讲述故事，将消费者和品牌联系起来。他拥有十几年的以结果为导向的公关经验，已经成为业务驱动营销和品牌传播的领导者，专注于服务速发展的初创公司，特别是消费技术行业。',
        },
        buttons: {
          number: '0',
        },
      },
    },
    teamMembers1: {
      title: '顾问',
      backgroundImage: 'bannerCube',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      number: '5',
      teamMember0: {
        image: 'garwin',
        name: 'Garwin Chan',
        linkedin: 'https://www.linkedin.com/in/garwin-chan-b8378a1/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'Garwin Chan此前担任5miles的首席财务官。他曾是方正和生投资有限责任公司(北大方正集团下的私募投资基金公司)的董事总经理。此前，他曾任全球领先的投资公司贝恩资本副总裁。Garwin Chan在2003年以优异的成绩毕业于哈佛大学，并于2008年获得哈佛商学院MBA学位。',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember1: {
        image: 'marc',
        name: 'Marc Fleury',
        linkedin: 'https://en.wikipedia.org/wiki/Marc_Fleury',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'Marc Fleury博士是软件自由和商业去中心化的先驱。他是开源Java应用服务器JBoss的创始人、开源解决方案供应商Red Hat Inc.的前执行副总裁，还是加密货币的早期思想领袖和投资人。Marc拥有巴黎理工学院(Ecole Polytechnique)的数学学位和物理学博士学位，以及巴黎高等师范学院(Ecole Normale Superieure)的理论物理学硕士学位。',
        },
        buttons: {
          number: '0',
        },
      },
//       teamMember2: {
//         image: 'malachi',
//         name: 'Malachi Boyuls',
//         linkedin: 'https://www.linkedin.com/in/malachi-boyuls-5b486b90/',
//         title: 'null',
//         description: {
//           richtext: 'null',
//           description:
//             'Malachi Boyuls是美国德州圣奥古斯丁资本合伙人公司的合伙人。该公司为技术、能源、房地产和金融服务等行业客户提供咨询服务和战略咨询。此前，他曾就职达拉斯的律师事务所Gibson, Dunn & Crutcher LLP，在公司管理机构方面得以锻炼，其内容包括反垄断、能源、证券和知识产权。他毕业于纽约大学法学院。',
//         },
//         buttons: {
//           number: '0',
//         },
//       },
      teamMember2: {
        image: 'george',
        name: 'George Chen',
        linkedin: 'https://www.linkedin.com/in/george-chen-ab9106/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'George Chen 曾任5miles 的首席营销官。他曾任好耶集团的产品开发高级副总裁，该集团是中国最大的广告平台和数字营销机构。凭借几十年的市场营销经验，尤其是数字营销领域的经验，他曾任谷歌子公司DoubleClick的首席工程师。他拥有中国科学技术大学文学学士学位和美国莱斯大学物理学硕士学位。',
        },
        buttons: {
          number: '0',
        },
      },
      
      teamMember3: {
        image: 'frank',
        name: 'Frank Lee',
        linkedin: 'https://www.linkedin.com/in/frank-lee-873902106/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'Frank Lee是多家创业公司的创始人，同时也是天使投资人，成功地开发了全球第一个Litecoin ASIC矿工。他投资和联合创办的公司涵盖多个领域，包括游戏、虚拟现实、增强现实、物联网等。创办的公司中有两家是MI生态系统的参与者。他毕业于清华大学电气工程专业。',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember4: {
        image: 'ash',
        name: 'Ash Han',
        linkedin: 'https://www.linkedin.com/in/ashhan/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'Ash Han是一名天使投资人，也是区块链的布道者。他是Finector的CEO。该公司是韩国最大的区块链、分布式账簿技术咨询公司。其用户包括政府机构、银行、金融机构及个人等。自2012年以来，Ash Han以天使投资人、联合创始人、顾问、社区组织者、作家和公共演说家等身份投身于区块链行业，对区块链经济和技术有深厚的理解。',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  partnerBoxes: {
    number: '2',
    boxes0: {
      title: '合作伙伴',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '8',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      box0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'fivemiles',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'blockchainVentures',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'linkvc',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'collinstar',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box4: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'aware',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box5: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'chainedbox',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box6: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'cybervein',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box7: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'hyperpay',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
    },
    boxes1: {
      title: '顾问',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '2',
      columns: '2',
      buttons: {
        number: '0',
      },
      backgroundImage: 'shortLastSection',
      box0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'yeewhy',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
//       box1: {
//         images: {
//           number: '1',
//           image0: {
//             gif: 'null',
//             path: 'deloitte',
//             link: 'null',
//           },
//         },
//         title: 'null',
//         description: {
//           richText: 'true',
//           description: 'null',
//         },
//         buttons: {
//           number: '0',
//         },
//       },
      box1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'lockeLord',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
