module.exports = {
  title: 'Footer',
  'meta-description': 'Footer',
  address:
    "CyberMiles Foundation Limited - 13/F Gloucester Tower, The Landmark, 15 Queen's Road Central, Hong Kong",
  copyright: 'Copyright © 2018. All rights reserved',
  subscribe: {
    label: '購読する',
    title: 'CyberMilesの最新ニュースを受け取る'
  },
  links: {
    contact: {
      label: '連絡先',
      link: '/about-us/contact-us',
    },
    privacy: {
      label: 'プライバシー',
      link: '/privacy',
    },
    terms: {
      label: '利用規約',
      link: '/terms',
    },
  },
  iconsTitle: "公式コミュニティプラットフォーム"
}
