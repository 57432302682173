module.exports = {
  title: 'CyberMiles|Blockchain technology for E-commerce',
  'meta-description':
    'CyberMiles is a smart, fast, secure, and free e-commerce blockchain platform with lightning-fast transaction speed and smart contract templates.',
  banner: {
    title: 'CyberMiles',
    backgroundImage: 'bannerCube',
    description: '전자 상거래 블록체인',
    buttons: {
      number: '1',
      button0: {
        label: 'CyberMiles가 무엇인가요?',
        link: '/',
        icon: 'play',
      },
    },
  },
  imageText: {
    section0: {
      image: {
        gif: 'true',
        path: 'cube',
      },
      title: '전자 상거래의 베스트 솔루션',
      description: {
        richtext: 'null',
        description:
          'CyberMiles는 전자 상거래 기업들이 블록체인에 비즈니스를 쉽게 배포 할 수 있도록 고도로 맞춤화된 솔루션을 구축했습니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '유즈 케이스',
          link: 'ecommerce-solutions/finance/payment-gateway/',
        },
      },
    },
    section1: {
      image: {
        gif: 'true',
        path: 'coin',
      },
      title: '가치있는 블록체인 기술',
      description: {
        richtext: 'null',
        description:
          ' CyberMiles 는 스마트하고, 빠르고 안전한 블록체인 네트워크 입니다. 모든 유저에게 무료 입니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: 'CMT 구매',
          link: 'cmt/overview/',
        },
      },
    },
    section2: {
      image: {
        gif: 'true',
        path: 'lity',
      },
      title: '블록체인에 간단하게 개발하기',
      description: {
        richtext: 'null',
        description:
          'CyberMiles는 디앱 개발을 위해 강력한 인프라를 구축했습니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '사이버마일즈로 개발',
          link: 'developer-portal/developer-hub',
        },
      },
    },
  },
  roadmap: {
    title: '로드맵',
    description: {
      richtext: 'null',
      description: 'null',
    },
    backgroundImage: 'lastSection',
    buttons: {
      number: '1',
      button0: {
        label: '더 알아보기',
        link: '/about-us/roadmap',
      },
    },
    events: {
      number: '5',
      event0: {
        title: '2 분기',
        outline: `5 마일즈 사용자 데이터 마이그레이션이 계획대로 진행되고 있습니다. 전자 상거래 DApps를 개발하기 시작했습니다. 동시에 기술 팀은 가스 요금 없게 가상 머신 업그레이드를 계획하고있습니다.이러므로 훨씬 더 많은 사용자가 CyberMiles 블록 체인 기술을 경험할 수있게합니다. 커뮤니티의 의견과 제안에 따라 투명성을 높이기 위해 DPoS 프로토콜의 보상 메커니즘을 조정할 것입니다.`,
        parent: 'true'
      },
      event1: {
        title: '전자 상거래 DApp',
        list: {
          size: '2',
          item0: '5 마일즈 데이터 마이그레이션',
          item1: '스테이블 코인 구매를 지원하는 스마트 계약'
        }
      },
      event2: {
        title: '가상 머신 업그레이드',
        list: {
          size: '2',
          item0: '스마트 계약의 난수 보장',
          item1: '계약 소유자가 가스 요금을 지불 할 수있는 옵션'
        }
      },
      event3: {
        title: 'CMT 지갑',
        list: {
          size: '2',
          item0: '새로운 지갑 사용자가 CMT를 먼저 얻지 않고도 DApp를 경험할 수 있습니다',
          item1: '지갑 스테이킹 기능 (커뮤니티 투표로 결정합니다)'
        }
      },
      event4: {
        title: '메인 넷 DPoS 보상 메커니즘 조정',
        list: {
          size: '2',
          item0: '알고리즘을 더 간단하고 이해하기 쉽게 만듭니다',
          item1: '알고리즘 쉽게 변경하고 계류기간 7일동안에도 보상을 줍니다'
        }
      }
    }
  },
  centered: {
    centered0: {
      image: 'null',
      title: 'CyberMiles 와 함께 시작합시다 ',
      description: {
        richText: 'null',
        description:
          '좋은 아이디어 있으세요?저희와 공유해주세요!',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '연락처',
          link: '/about-us/contact-us',
        },
      },
    },
  },
}
