module.exports = {
  title: 'Stable Coin',
  'meta-description': 'Stable Coin',
  banner: {
    title: 'Stable Coin',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: 'Challenges for Traditional E-commerce',
      description: {
        richText: 'null',
        description:
          'While e-commerce is the earliest and most popular cryptocurrency use case, its adoption is hampered by the wild fluctuation, high fees, and slow speed of cryptocurrencies. In most countries, consumers require merchandise to be priced in fiat currency. At the same time, the high transaction fees and slow confirmation time of the BTC and ETH networks have made them unsuitable for e-commerce payments, especially micropayments. To fully realize the promise of cryptocurrencies in e-commerce, we need a new class of cryptocurrencies that are pegged against fiat currencies, supports high speed transactions, and have very low fees. Those are stable coins.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'What is CyberMiles solution?',
      description: {
        richText: 'null',
        description:
          'The CyberMiles blockchain is optimized for stable coin issuance and transactions. The CyberMiles validators provide assurance that cross-chain deposits and redemption of stable coins are transparent and valid. The CyberMiles virtual machine supports the performance needed for e-commerce scale payment solutions based on smart contract stable coins.',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: 'Contact Us',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_symbol',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'Multiple stable coins pegged against GUSD and BTC for every use case',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_link',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '100% backed by verifiable deposits on the blockchain',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_otc',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'High liquidity via on-chain OTC exchanges',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
