module.exports = {
  title: 'Payment Gateway',
  'meta-description': 'Payment Gateway',
  banner: {
    title: '決済手段',
    backgroundImage: 'bannerCube',
    description: '新たな収益機会の創出',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '従来のeコマースへの挑戦',
      description: {
        richText: 'null',
        description:
          '多くの要望がある一方で、従来のeコマースサイトが暗号通貨の支払いサポートするのは困難です。従来の支払い処理会社は、1つまたは2つの主要な暗号通貨をサポートしているが、法定通貨への交換には高い手数料が必要です（例：USD）。暗号通貨支払いは、クレジットカードのような従来の支払い方法ほど魅力的ではありません。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMilesの解決策',
      description: {
        richText: 'null',
        description:
          'CyberMilesの決済手段は、パートナーであるSaaSサービスを利用します。米ドル建てのステーブルコインでの支払いを含め、ほとんどすべての暗号通貨での顧客の支払いをシームレスに受け入れるために、あらゆるeコマースサイトに埋め込むことができます。',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '連絡先',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_coin',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'BTC、ETH、CMT、および事実上すべてのERC20トークンを含む、一般的なすべての暗号通貨を受け入れます。',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_refresh',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'USDTやGUSDのような法定通貨にペッグされたステーブルコインを受け入れます',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_love',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'シームレスで透明性の高いユーザー体験',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  columns: {
    number: '1',
    columns0: {
      title: '事例',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '2',
      columns: '2',
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '0',
      },
      images: {
        number: '0',
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'lightInTheBox',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'blocktonic',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
