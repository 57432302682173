module.exports = {
  title: 'Stable Coin',
  'meta-description': 'Stable Coin',
  banner: {
    title: 'ステーブルコイン',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '従来のeコマースへの挑戦',
      description: {
        richText: 'null',
        description:
          'eコマースは最も手軽かつ人気のある暗号通貨の使用方法ですが、その採用は暗号通貨の激しい変動、高い手数料、遅い取引スピードによって妨げられています。 ほとんどの国では、商品を法定通貨で価格設定する必要があります。同じく、高い取引手数料とBTCおよびETHネットワークでの長い確認時間は、eコマースでの支払い、特に少額決済には適していません。 eコマースにおける暗号通貨の契約を完全に実現するために、我々は法定通貨と固定され、高速取引をサポートし、そして非常に低い手数料を有する新しいレベルの暗号通貨を必要としています。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMilesの解決策',
      description: {
        richText: 'null',
        description:
          'CyberMilesブロックチェーンはステーブルコインの発行と取引に最適です。CyberMilesバリデータは、クロスチェーンデポジットとステーブルコインの買い戻しが透明かつ有効であることを保証します。CyberMiles仮想マシンは、スマートコントラクトを有するステーブルコインに基づくeコマースでの決済ソリューションに必要な機能をサポートします。',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '連絡先',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_symbol',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'すべてのユースケースにおいてGUSDとBTCにペッグされた多様なステーブルコイン',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_link',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'ブロックチェーンで検証可能な入金を100％保証',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_otc',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'チェーン上のOTC取引所による高い流動性',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
