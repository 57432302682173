module.exports = {
  title: 'User Engagement Solutions',
  'meta-description': 'User Engagement Solutions',
  banner: {
    title: 'ユーザー契約の解決策',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '従来のeコマースへの挑戦',
      description: {
        richText: 'true',
        description:
          `<p>現在のデジタルビジネス環境における最大の課題の1つは、ユーザーを積極的に参加させ、関心を持たせることです。 私たちがブロックチェーンベースのソリューションをしていることに関係なく、あなたのブランドや製品への関与はあなたのビジネスを再構築することができます。</p>
          <p>ブロックチェーンのトークに基づく賞金およびロイヤリティプログラムは、コミュニティを活発化し、アーリーアダプターを増やす強力な方法です。 それはプロジェクトの成長に必要なユーザーを直接獲得することに繋がります。成長の秘訣はエグゼキューションです。 報酬を計画し、正確に追跡し、配布することは、どの組織にとっても圧倒的なリソース集約型の作業になり得ます。</p>`,
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMilesの解決策',
      description: {
        richText: 'null',
        description:
          '安全かつ高度に提供されている強固なDPOSブロックチェーンと包括的なスマートコントラクトテンプレートライブラリを使用すると、効果的な賞金キャンペーンとロイヤリティキャンペーンをすばやく構築して実行できます。そして、ブランドと製品に関する活発なコミュニティを構築することができます。また、ブランドを宣伝したりプラットフォーム上でコンテンツを作成したりすることをユーザーに簡単に促すことができます。',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '1',
        button0: {
          label: '連絡先',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_stats',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '連携を模索し報酬を与える',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_bookmark',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '困難な作業を自動化する',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_api',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'APIやプラグインと簡単に統合',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
