module.exports = {
  title: 'Security Token',
  'meta-description': 'Security Token',
  banner: {
    title: 'セキュリティトークン',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '従来のeコマースへの挑戦',
      description: {
        richText: 'null',
        description:
          'eコマースに特に当てはまりますが、すべての商業活動における大きな課題は、活動を維持し成長させるための資金収支の管理方法です。証券化は、効率的な資金収支のための重要な手段です。しかし、ウォール街で行われている従来の証券化は、費用と時間もかかり多くの人間が関わるので非効率的です。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMilesの解決策',
      description: {
        richText: 'null',
        description:
          'CyberMilesブロックチェーンを使用することで、eコマース企業向けのセキュリティトークンを発行し資金収支を管理でき、運営資金が調達しやすくなります。',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '連絡先',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_shield',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'ERC 1400、ERC 1440、ST-20、Rトークン、S3など、一般的なすべてのトークンの規格をサポート',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_ofn',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'OFN（OpenFinance）などの取引所との密接な連携',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_rise',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'eコマース関連の資金収支の証券化に最適化',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
