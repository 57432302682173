module.exports = {
  title: '隐私',
  'meta-description': 'We know you care about how your personal information is used and shared, and we take your privacy seriously. PLEASE READ THE PRIVACY POLICY CAREFULLY.',
  banner: {
    title: '隐私',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  richTextContent: {
    backgroundImage: 'null',
    content: `<h2>Privacy</h2>
    <strong>CyberMiles Privacy Policy</strong>
    This Privacy Policy is issued by CyberMiles Foundation, a company limited
    by guarantee registered in Hong Kong (“<strong>CyberMiles</strong>”, “
    <strong>we</strong>” or “<strong>us</strong>”). You have arrived at
    cybermiles.io and/or are otherwise using our Products and Services
    (defined below). We know you care about how your personal information is
    used and shared, and we take your privacy seriously. We are committed to
    protecting the privacy, confidentiality and security of the personal
    information we hold by complying with the requirements under applicable
    laws and regulations. We are equally committed to ensuring that all our
    employees and agents uphold these obligations. Please read the following
    to learn more about our privacy policy (“<strong>Privacy</strong>
    <strong>Policy</strong>”).
    <strong>What does this Privacy Policy cover?</strong>
    This Privacy Policy covers our treatment of information, including
    Personal Information (defined below) that we gather when you access or use
    any online service location that posts a link to this Policy which also
    applies to your use of our website(s), mobile applications or any other
    products and services provided by us (“
    <strong>Products and Services</strong>”). This Privacy Policy does not
    apply to the practices of companies we do not own or control, or people
    that we do not manage. We gather various types of information from our
    users, as explained in more detail below. We use this information for you
    to interact with our Products and Services, including to personalise,
    provide, and improve our Products and Services, to allow you to set up a
    user account and profile, to contact you and allow other users to contact
    you, to fulfil your requests for certain products and services, and to
    analyse how you use the Products and Services. In certain cases, we may
    also share some information with third parties as described below. By
    using our website, providing personal information and/or using any of our
    Products and Services, you acknowledge that:
    <ol type="a">
      <li>
        we are not responsible for your own disclosure of your information to
        third parties through our Products and Services (for example, if you
        send your own contact information to another user through any platform
        operated by us);
      </li>
      <li>
        you, and you alone, are responsible for the content of any profile or
        registration you create and its accuracy.
      </li>
      <li>
        you consent to this Privacy Policy, as updated from time to time;
      </li>
      <li>
        if you have provided information to us relating to any other person,
        you:
        <ul>
          <li>have a right to provide that information;</li>
          <li>
            have provided a copy of this Privacy Policy, as updated from time
            to time, to that person; and
          </li>
          <li>each such person has agreed to those terms.</li>
        </ul>
      </li>
    </ol>
    In such cases, references in this Privacy Policy to terms such as “you”
    and “your” also refer to such persons. Your use of our Products and
    Services is at all times subject to the 
    <a href="http://www.cybermiles.io//terms/">Terms of Use</a>, which
    incorporate this Policy. Any terms not defined in this Privacy Policy have
    the meaning given to them in the Terms of Use.
    <strong>What information does CyberMiles collect?</strong>
    The type of information we may collect from you includes:
    <ol type="a">
      <li>
        personal information (“<strong>Personal</strong> 
        <strong>Information</strong>”), including without limitation:
        <ul>
          <li>
            personally, identifiable information, such as your name and date
            of birth;
          </li>
          <li>
            contact information, such as your email address, telephone number
            and postal address;
          </li>
          <li>
            social media handles and other social media profile information
            that you make available to us or to the public;
          </li>
          <li>
            third-party account credentials (such as your Facebook login
            credentials, Google login credentials)
          </li>
          <li>
            a user’s IP address when the user accesses or uses our Products
            and Services;
          </li>
        </ul>
      </li>
      <li>
        demographic information such as information regarding your gender,
        nationality and occupation (“<strong>Demographic</strong> 
        <strong>Information</strong>”);
      </li>
      <li>
        other information (“<strong>Other Information</strong>”) including
        without limitation:
        <ul>
          <li>your public Ethereum key and/or similar information;</li>
          <li>
            <em>
              financial information, such as credit card, bank account or
              other payment details;
            </em>
          </li>
          <li>
            <em>
              usernames and passwords that you create when registering for an
              account with us;
            </em>
          </li>
          <li>
            <em>
              details of any Products and Services that we provide to you;
            </em>
          </li>
          <li>
            <em>
              records of our communications with you, including any messages
              you send us;
            </em>
          </li>
        </ul>
      </li>
    </ol>
    paragraphs (a), (b) and (c), together “<strong>Information</strong>”. We
    may collection Information through various forms and in various places
    within our Products and Services. To the extent we combine Demographic
    Information and/or Other Information with your Personal Information we
    collect from you, we will treat the combined data as Personal Information
    under this Policy.
    <strong>How does CyberMiles collect your Information?</strong>
    We collect information about you in the following ways: Information you
    provide to us directly, including without limitation to:
    <ul>
      <li>
        when you register for an account on our website, when you update your
        account details, when you open an email from us, or request or use our
        Products and Services;
      </li>
      <li>when you submit a query or request to us;</li>
    </ul>
    <ol type="a">
      <li>
        device information – when you access our Products and Services via
        your mobile device or computer, we collect data about you including
        unique identification numbers associated with mobile devices or
        through its API (including, for example, a Unique ID for Advertisers
        (“
        <strong>IDFA</strong>”), Unique ID for Vendors (“<strong>IDFV</strong>
        ”), Google Ad ID, or Windows Advertising ID), mobile carrier, device
        type, model and manufacturer, mobile device operating system brand and
        model, battery life, and, depending on the user’s mobile device
        settings, the geographical location data, including GPS coordinates
        (e.g. latitude and/or longitude) or similar information regarding the
        location of the mobile device (“<strong>User Data</strong>”) that
        enables us to provide you with information, advertisements, or offers
        specific to your location and interests.
      </li>
      <li>
        Information collected automatically – whenever you interact with our
        Products and Services, we automatically receive and record Information
        on our server logs from your browser or device, which may include your
        IP address, device identification, “cookie” information, browser
        and/or device type, and the page or feature you requested;
      </li>
      <li>
        from third parties who are entitled to disclose that information – you
        may have the option of registering an account and logging into or
        otherwise linking to that account using a third-party account such as
        Facebook, Google or other account provided by a third-party site or
        application. If you choose to provide or log into your account using
        your credentials for a third-party platform, we may have access to
        certain information from that site, such as your name, contacts or
        friend’s lists, account information, public profile, and email
        address.
      </li>
    </ol>
    Consequently, if you provide your third-party account credentials, you
    acknowledge that the above-identified information, as well as other
    information for that account (collectively, “
    <strong>Third Party Account Information</strong>”) may be transmitted into
    your account with us if you authorise such transmissions. You further
    acknowledge that Third Party Account Information transmitted to us is
    governed by this Policy. When using some features of our Products and
    Services, certain information may be required from you to register an
    account with us.  Also, we may, from time to time, combine information
    from other sources with Information we collect from you in order to
    enhance our ability to serve you, prevent fraudulent transactions, tailor
    our content to you, offer you opportunities to purchase products or
    services that we believe may be of interest to you, and for any other
    purposes described in this Policy; In some cases, we may be required by
    law to collect certain types of personal information about you. Where we
    collect personal information from you, we will generally do so ourselves.
    However, in some cases we may collect personal information from a third
    party, such as through your representatives, contractors who provide
    services to us, or third parties who refer you to us because they think
    you may be interested in our Products and Services.
    <strong>What does CyberMiles use your Information for?</strong>
    We use the Information we collect about you for the following purposes:
    <ol type="a">
      <li>to verify your identity when you are dealing with us;</li>
      <li>
        to determine your eligibility for any of our Products and Services;
      </li>
      <li>to answer your queries and requests;</li>
      <li>
        to notify you of any changes to the terms or services and Terms of
        Use;
      </li>
      <li>to email you about your use of the Products and Services;</li>
      <li>
        to enable us to provide our Products and Services and personalise your
        experience by better responding to your individual needs;
      </li>
      <li>
        to improve our website(s) based on your information and feedback;
      </li>
      <li>to process transactions;</li>
      <li>
        to send periodic emails for keeping you informed of our activities,
        such as (the email address you provide for order processing, may be
        used to send you information and updates pertaining to your order, in
        addition to receiving, occasional company news, updates, related
        product or service information, etc.);
      </li>
      <li>
        to comply with our legal and regulatory obligations and manage related
        risks;
      </li>
      <li>
        to manage and resolve any legal or commercial complaints or issues;
      </li>
      <li>to carry out market analysis and research; and/or</li>
      <li>
        to carry out planning and forecasting activities and other internal
        business processes.
      </li>
    </ol>
    You are free to opt out of receiving email communications from us by
    navigating to the settings page within our Products and Services. However,
    you may be unable to opt out of some administrative communications that
    are reasonably necessary for us to provide our Products and Services to
    you, such as order confirmations or service notifications.
    <em>Direct marketing</em>
    There may be instances where we will use your Information to send you
    marketing materials, for example, we may send promotional email offers to
    you about our Products and Services that we think you may be interested
    (including in some cases products and services that are provided by a
    third party). We may not use your Personal Information unless we have
    received your consent. You can opt-out of receiving marketing
    communications from us by contacting us at contact@cybermiles.io. We may
    use your following Personal Information for the purpose of direct
    marketing:
    <ol type="a">
      <li>identifying information, such as your name;</li>
      <li>
        contact information, such as your e-mail address, mailing address or
        phone number; and/or
      </li>
      <li>
        products and services portfolio information and demographic data held
        by us from time to time.
      </li>
    </ol>
    We may use your Personal Information to market the following products
    and/or services to you, in each case subject to our legal and regulatory
    obligations:
    <ol type="a">
      <li>purchasing and/or trading digital assets;</li>
      <li>
        providing, managing or accessing mobile wallets for holding digital
        assets; and
      </li>
      <li>other products or services related to digital assets.</li>
    </ol>
    If we use your Personal Information in any direct marketing
    communications, you have the right to request that we provide you with the
    source of that Personal Information. There is no fee for requesting this
    information.  We will provide you with the source of the Personal
    Information, unless it is impracticable or unreasonable to do so. Please
    indicate your consent to receiving information relating to the above by
    contacting us at 
    <a href="mailto:contact@cybermiles.io">contact@cybermiles.io</a> or by
    registering your interest and providing your contact details through our
    website. We may also use and disclose your Information for other purposes
    in accordance with your requests or instructions.
    <strong>Cookies</strong>
    Through cookies we place on your mobile device or browser, we may collect
    information about your online activity after you leave or log out from our
    Products and Services. Just like any other usage information we collect,
    this information allows us to improve our Products and Services and to
    customise your online experience, and otherwise as described in this
    Policy. Cookies are unique identifiers that we transfer to your device to
    enable our systems to recognise your device and to improve your overall
    user experience. For instance, cookies allow us to preserve our users’
    preferences and record session information, such as what pages a visit or
    items the user looks at, recognise the user’s browser or device type, and
    determine which pages and/or features are visited and by how many people.
    You may be able to change the preferences on your browser or device to
    prevent or limit your device’s acceptance of cookies, but this may prevent
    you from using some features or functionality of our Products and
    Services. Your browser may offer you a “Do Not Track” option which
    allows you to signal to operators of websites and web applications and
    services (including behavioural advertising services) that you do not wish
    such operators to track certain of your online activities over time and
    across different websites. Currently our Products and Services do not
    monitor, support or take any action with respect to “Do Not Track”
    requests, signals or mechanisms, which means that we may collect
    information about your online activity both while you are using and after
    you have finished using our website(s), Products and Services.
    <strong>Is your Information secure?</strong>
    Protecting your privacy is important to us, which is why we endeavour to
    protect the security of your privacy and personal information using
    commercially reasonable security measures to protect the confidentiality
    and security of the Information that we hold, and we update these from
    time to time to address new and emerging security threats that we become
    aware of. We also take steps to monitor access to and modification of your
    Information by our staff members, and ensure that they are aware of and
    are properly trained in their obligations for managing your data privacy.
    No website or Internet transmission is completely secure. Therefore, we
    cannot guarantee that your Information will never be hacked or that your
    Information is free from unauthorised access, data loss, or other
    breaches. In other words, your use of CyberMiles’ Products and Services is
    at your own risk. We encourage you to exercise caution when using our
    Products and Services by keeping your password safe and not sharing it
    with other individuals.
    <strong>
      How do we store your Information and how long do we keep it?
    </strong>
    We generally store the Information that we collect in electronic
    databases, some of which may be held on our behalf by third party data
    storage providers. Sometimes we also keep hard copy records of your
    Information in physical storage facilities. Your Personal Information will
    not be kept longer than required. We may retain your Personal Information
    for a period of 12 months. We may be required (by law or otherwise) to
    retain your account information or any other information associated with
    it and not delete it (or to keep this information for a certain period of
    time, in which case we are only be able to comply with your deletion
    request only after we have fulfilled such requirements). Some information
    may remain in our records after your deletion of such information from
    your account. We may use any aggregated data derived from or incorporating
    your Information after you update or delete it, but not in a manner that
    would identify you personally.
    <strong>Sharing Information</strong>
    We do not sell or trade your Personal Information to any third parties. We
    may share your Information with third parties as described in this
    section:
    <ol type="a">
      <li>
        your representatives, advisers and others you have authorised to
        interact with us on your behalf;
      </li>
      <li>
        our staff members who need the information to discharge their duties;
      </li>
      <li>
        our affiliated entities within the same corporate group – in certain
        situations, businesses or third-party websites we are affiliated with
        may sell or provide products or services to you through or in
        connection with the Products and Services (either alone or jointly
        with us). You can recognise when an affiliated entity is associated
        with such a transaction or service, and we will share your Information
        with that affiliated entity only to the extent that it is related to
        such Products and Services or their related transaction.Please
        remember that the manner in which third party services use, store and
        disclose your information is governed solely by the policies of such
        third-party service providers. We have no control over the policies
        and practices of third party websites or businesses as to privacy or
        anything else, so if you choose to take part in any transaction or
        service relating to a website of an affiliated entity or business,
        please review all such business’ or websites’ policies.
      </li>
      <li>
        our agents and service providers that assist us in operating our
        website, providing Products and Services to you, or otherwise
        conducting our business, for example, we may:
        <ul>
          <li>
            employ other companies and people to perform certain tasks or
            services on our behalf, such as hosting services, and may share
            your Information with them to provide Products and Services to
            you; for example, we may use a payment processing company or
            financial institution to receive and process your credit card
            transactions or payments for us;
          </li>
          <li>
            employ companies to call or email you to in order to provide
            better services to you, such as to welcome you when you sign up
            with us, to notify you of your transaction information, to remind
            you of your account inactivity, to provide codes for you to refer
            your friends to our Products and Services, or to notify you of an
            offer that you may be interested in. By providing the relevant
            communication means to us, you agree that we may, to the extent
            permitted by applicable laws and regulations, contact you for the
            above-mentioned purposes.
          </li>
        </ul>
        Unless we tell you differently, our agents do not have any right to
        use the Information we share with them beyond what is necessary to
        assist us;
      </li>
      <li>
        prospective purchasers or successors of all or part of our business or
        shares in our company or a related entity – We may choose to buy or
        sell assets, and may share and/or transfer user information, including
        the Information, in connection with the evaluation of and entry into
        such transactions. Also, if we (or our assets) are acquired, or if we
        go out of business, enter bankruptcy, or go through some other change
        of control, Information could be one of the assets transferred to or
        acquired by a third party. We may also share your and of your
        Information, including usage information with our patent, subsidiaries
        and affiliates;
      </li>
      <li>
        government authorities who ask us to disclose that information in
        order to:
        <ul>
          <li>
            comply with laws and regulations, court orders, or government
            requests;
          </li>
          <li>
            enforce or apply our 
            <a href="http://www.cybermiles.io//terms/">Terms of Use</a> and
            other agreements, guidelines, policies, and regulations;
          </li>
          <li>
            assist in any investigations or legal proceedings (including to
            respond to claims of alleged violations of any third-party
            rights); and/or
          </li>
          <li>
            protect the rights, property, or safety of 5miles, our employees,
            our users, or others; and
          </li>
        </ul>
      </li>
      <li>
        professional advisers who we engage to provide advice on our business.
      </li>
    </ol>
    We may share your User Data with our third-party partners. In some
    situations, transferees to whom we disclose your Information may be
    located overseas. Further, we may have servers located overseas. The
    jurisdictions in which these people and/or servers are likely to be
    located include the United States, Japan, and China. There may not be in
    place data protection laws which are substantially similar to, or serve
    the same purposes as the applicable data privacy laws in Hong Kong. This
    means your personal information may not be protected to the same or
    similar level in Hong Kong.
    <em>De-identifying your Information</em>
    We may de-identify your Information (so that you are not identified) and
    provide that Information to our business partners and/or affiliated
    entities. We may also provide aggregate usage information to our business
    partners and/or affiliated entities (or allow them to collect that
    Information from you), who may use such Information to understand how
    often and in what ways people use our Products and Services, so that they,
    too, can provide you with an optimal online experience. However, we never
    disclose aggregate usage or de-identified information to a business
    partner and/affiliated entities (or allow them to collect such
    information) in a manner that would personally identify you. For example,
    we use Google Analytics to grow our business, to improve or develop our
    services, to monitor and analyse use of our Products and Services, for our
    technical administration, to increase the functionality and
    user-friendliness of our Products and Services, and to verify that users
    have the authorisation needed for us to process their requests. To learn
    more about the privacy policy of Google Analytics, visit 
    <a
      href="http://www.google.com/intl/en/analytics/privacyoverview.html"
      target="_blank"
      rel="noopener"
    >
      http://www.google.com/intl/en/analytics/privacyoverview.html
    </a>
    . You can opt out of the Google Analytics by visiting 
    <a
      href="http://tools.google.com/dlpage/gaoptout"
      target="_blank"
      rel="noopener"
    >
      http://tools.google.com/dlpage/gaoptout
    </a>
    .<strong>Accessing and modifying your Information</strong>
    If any Information you provided to us has changed, you should inform us.
    You are responsible for maintaining the accuracy of the Information you
    submit to us, such as your contact information.  You may be able to access
    most of your Information via the Products and Services, for example, the
    Information you provided during the account registration process (i.e.
    your name, email address, password, etc.) can be accessed by logging into
    your account and navigating to account settings. Through your account
    settings, you may access, and, in some cases, edit or delete the following
    information you have provided to us:
    <ul>
      <li>your nickname;</li>
      <li>avatar photo;</li>
      <li>phone number;</li>
      <li>email address; and</li>
      <li>digital wallet information</li>
    </ul>
    You may be able to add, update, or delete Information. However, we may
    maintain a copy of the unrevised information in our records. This means
    your Information is not permanently removed from the system. In any event,
    you may request deletion of your account by contacting us by email at
    contatct@cybermiles.io. To protect the integrity and security of the
    Information we hold, we may ask that you follow a defined access
    procedure, which may include steps to verify your identity. In certain
    cases, we may charge you an administration fee for providing you with
    access to the Information you have asked for, but we will inform you of
    this before proceeding. There may be cases where we are unable to provide
    the information you request, such as where it would interfere with the
    privacy of others or result in a breach of confidentiality. In these
    cases, we will let you know why we cannot comply with your request. Even
    if you do not request access to and/or correct your personal information
    held by us, if we are satisfied that, having regard to the reasons for
    which we hold your personal information, that personal information is
    inaccurate, incomplete, out-of-date, irrelevant or misleading, we may take
    reasonable steps to correct that information.
    <strong>Your choices about our collection of your Information</strong>
    You can always opt not to disclose Information to us. However, without the
    Information, we may not be able to provide you with our Products and
    Services (or with some of the features and functionality offered by our
    Products and Services) or to respond to queries or request that you submit
    to us.
    <strong>Third party links and tracking</strong>
    Occasionally, at our discretion, we may include links to third party
    products or services on our website. These third-party sites have separate
    and independent privacy policies, we do not verify their content. If you
    click on a link to a third-party website or service, a third party may
    transmit cookies to you. This Policy does not cover your use of a
    third-party website or service or the use of cookies by any third parties,
    and we are not responsible for their privacy policies and practices. You
    should review the applicable third-party privacy policies before using
    their website or services. Please be aware that third parties may continue
    to track your online activities even after you have left our Products and
    Services, and those third parties may not honour “Do Not Track” requests
    you have set using your browser or device.
    <strong>Children and CyberMiles</strong>
    Our Products and Services are intended to be used by adults, as defined
    under any applicable law in each specific case. If you are under 18 years
    old, you are not authorised to use our Products and Services. We do not
    knowingly collect or solicit personal information from anyone under the
    age of 18<em>. </em>If you are under 18, please do not attempt to register
    for the Products and Services or send any Information about yourself to
    us. If we discover that we have collected Information from a child under
    age 18, we will delete that Information as quickly as possible. If you
    believe that a child under 18 may have provided us Information, please
    contact us at contact@cybermiles.io.
    <strong>Will we change this Policy?</strong>
    We are constantly trying to improve our Products and Services. We may also
    change this Policy from time to time, to take into account changes to our
    standard practices and procedures or where necessary to comply with new
    laws and regulations. While the latest version of this Privacy Policy will
    always be available on our website at: cybermiles.io, we may alert you to
    changes by placing a notice on the website at cybermiles.io, by sending
    you an email, and/or by some other means. If you have opted not to receive
    notice emails from us (or you have not provided us with your email
    address), those notices will still govern your use of the Products and
    Services, and you are still responsible for reading and understanding
    them. If you use the Products and Services after any changes to this
    Policy have been posted, that means you agree to all of the changes.
    Additionally, we may use Google Analytics to grow our business, to improve
    our Products and Services, for our technical administration, to increase
    the functionality and user-friendliness of our Products and Services, and
    to verify that users have the authorisation needed for us to process their
    requests. To learn more about the privacy of Google Analytics, visit: 
    <a
      href="http://www.google.com/intl/en/analytics/privacyoverview.html"
      target="_blank"
      rel="noopener"
    >
      http://www.google.com/intl/en/analytics/privacyoverview.html
    </a>
    You can opt out of the Google Analytics by visiting: 
    <a
      href="http://tools.google.com/dlpage/gaoptout"
      target="_blank"
      rel="noopener"
    >
      http://tools.google.com/dlpage/gaoptout
    </a>
    <strong>What should I do if I have a concern?</strong>
    We try to meet the highest standards in order to protect your data
    privacy. However, if you are concerned about the way in which we are
    managing your Information and think we may have breached any applicable
    privacy laws, or any other relevant obligation, please contact our privacy
    compliance team using the contact details set out below. We will make a
    record of your complaint and refer it to our internal complaint resolution
    department for further investigation. We will deal with the matter as soon
    as we can, and keep you informed of the progress of our investigation. If
    we have not responded to you within a reasonable time or if you feel that
    your complaint has not been resolved to your satisfaction, you are
    entitled to make a complaint to the Hong Kong Privacy Commissioner for
    Personal Data.
    <strong>What if I have questions about this Policy?</strong>
    If you have any questions or concerns regarding our Privacy Policy or
    become award of misuse of the Products and Services by any person, please
    send our Data Privacy Officer a detailed message at contact@cybermiles.io.`,
  },
}
