module.exports = {
  title: 'Payment Gateway',
  'meta-description': 'Payment Gateway',
  banner: {
    title: 'Payment Gateway',
    backgroundImage: 'bannerCube',
    description: 'Tap into new revenue opportunites',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: 'Challenges for Traditional E-commerce',
      description: {
        richText: 'null',
        description:
          'While there is a clear demand, it is difficult for traditional e-commerce websites to accept cryptocurrency payments. Traditional payment processing companies supports one or two mainstream cryptocurrencies and require high fees for converting into and out of fiat currencies (e.g., USDs). The cryptocurrency payment user experience is also poor for consumers, making them uncompetitive against traditional payment methods such as credit cards.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'What is CyberMiles solution?',
      description: {
        richText: 'null',
        description:
          'The CyberMiles payment gateway is a SaaS service offered by our partners. It can be embedded into any e-commerce web site to seamlessly accept customer payments in almost any cryptocurrency in today’s market, including payments in USD-pegged stable coins.',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: 'Contact Us',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_coin',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'Accept all popular cryptocurrencies, including BTC, ETH, CMT, and virtually all ERC20 tokens.',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_refresh',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'Accept stable coins pegged to USD and other fiat currencies, such as USDT and GUSD.',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_love',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'Seamless and transparent user experience.',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  columns: {
    number: '1',
    columns0: {
      title: 'Case Studies',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '2',
      columns: '2',
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '0',
      },
      images: {
        number: '0',
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'lightInTheBox',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'blocktonic',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
