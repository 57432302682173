module.exports = {
  title: '用户参与解决方案',
  'meta-description': '用户参与解决方案',
  banner: {
    title: '用户参与解决方案',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '传统电商的挑战',
      description: {
        richText: 'true',
        description:
          `<p>当今数字商业环境面临的最大挑战之一是如何保持用户群的参与度和兴趣。无论我们是否在谈论基于区块链的解决方案，用户与品牌和产品进行互动都会影响到公司业务。</p>
          <p>基于区块链token的赏金和忠诚度计划是获得牵引力和增加早期用户的有力方式。它将直接激励能够带来增长的用户行为。</p>
          <p>成功增长的关键是执行。规划，准确跟踪和分发奖励对任何组织来说都是一项压倒性的，需要大量资源的任务。</p>`,
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMiles解决方案',
      description: {
        richText: 'null',
        description:
          '凭借我们安全且专业托管的、强大的DPoS区块链和全面的智能合约模板库，您可以快速构建和运行有效的赏金和忠诚度活动，围绕您的品牌和产品构建活跃的社区。 由此，可以轻松地激励用户在平台上宣传您的品牌或创建内容等等。',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '1',
        button0: {
          label: '联系我们',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_stats',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '精准检测和激励协作',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_bookmark',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '自动执行艰巨的任务',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_api',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '易于集成API和插件',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
