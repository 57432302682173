module.exports = {
  title: '支付网关',
  'meta-description': '支付网关',
  banner: {
    title: '支付网关',
    backgroundImage: 'bannerCube',
    description: '挖掘新的收入机会',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '传统电商的挑战',
      description: {
        richText: 'null',
        description:
          '尽管需求明确，但传统的电子商务网站接受加密货币支付是一件非常困难的事情。 传统的支付处理公司只能支持一种或两种主流加密货币。此外，将加密货币转换成美元等法定货币，需要支付高额费用。 加密货币的支付体验对消费者来说也很差劲，与传统的支付方式（如信用卡）相比，并没有竞争力。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMiles 解决方案',
      description: {
        richText: 'null',
        description:
          'CyberMiles支付网关是由合作伙伴提供的SaaS服务。 它可以嵌入到任何电子商务网站，使消费者能够使用市场上任意一种加密货币在该网站无缝付款，包括与美元挂钩的稳定币。',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '联系我们',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_coin',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '接受所有流行的加密货币，包括BTC、ETH、CMT和几乎所有的ERC20 代币。',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_refresh',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: '接受所有美元和其他法定货币挂钩的稳定币，如USDT和GUSD。',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_love',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: '流畅透明的用户体验',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  columns: {
    number: '1',
    columns0: {
      title: '案例：',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '2',
      columns: '2',
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '0',
      },
      images: {
        number: '0',
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'lightInTheBox',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'blocktonic',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
