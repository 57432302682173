module.exports = {
  title: 'Validator Program',
  'meta-description':
    'We are recruiting validators to run supernodes for CMT rewards in exchange. Supernodes are servers ensuring the security and high performance of the blockchain.',
  banner: {
    title: '검증인 프로그램',
    backgroundImage: 'bannerCoin',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '4',
    centered0: {
      images: {
        number: '0',
      },
      title: '검증인',
      description: {
        richText: 'null',
        description:
          '검증인들은 위임자를 대신하여 중요한 네트워크 인프라를 유지 관리하고 규칙 수정 및 거버넌스 결정을 실행합니다. 사이버마일즈 시스템에는 오직 19명의 검증인들만 존재할 수 있기 때문에  글로벌 블록체인 네트워크가 유지되기 위한  최신 소프트웨어, 충분한 컴퓨팅 성능 및 네트워크 대역폭을 실행하는 보안 서버를 제공함으로써 위임자 투표 (CMT 스테이크)를 받을수 있도록 서로 경쟁합니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
    centered1: {
      images: {
        number: '0',
      },
      title: '위임자',
      description: {
        richText: 'null',
        description:
          '위임자는 CMT를 검증인 후보에게 맡김으로써 네트워크 유지를 위한 검증인을 고용하거나 선출합니다. 위임자는 일반적으로 블록 체인 네트워크를 실제로 구동하는 컴퓨터 서버를 실행할 수 없고 실행하지 않을 것입니다. 사이버마일즈 네트워크 운영 첫해동안 CMT 큐브 소유자 만이 블록 지급을 위임 할 수 있고 그에 따른 여러가지 행사가 가능합니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
    centered2: {
      images: {
        number: '0',
      },
      title: 'CyberMiles App',
      description: {
        richText: 'true',
        description:
          `CMT 사용자들은 <a href="http://onelink.to/v248ze" target="_blank">CyberMiles App</a>으로 검증인에 스테이킹 하면 블록 상금을 얻을 수 있습니다.현제 전체 네이트워크의 지분을 기준으로 보았을 때,보상 비율 30% 스테이킹 기간이 1년의 경우에,연간 수익률은 15% 입니다.(block height 1,948,355기준.)\
          <p>같은 조건에 큐브로 스테이킹 수익은 월렛 스테이킹 수익 보다 20% 더 높습니다</p>`
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
    centered3: {
      images: {
        number: '0',
      },
      title: 'CMT 큐브',
      description: {
        richText: 'null',
        description:
          'CMT 큐브는 사이버마일즈 네트워크 운영 첫해 동안 검증인 선거를 용이하게 하기 위해 특별히 고안된 가정용 기기입니다. CMT 지갑 (CMT 계정 저장 및 관리)과 쓰기 쉬운 사용자 인터페이스(UI)를 고루 갖춘 하드웨어 장치로 지갑의 CMT를 통해서 검증인을 선택합니다. UI에는 시간이 지남에 따라 계정에서받는 최신 수입 (위임자의 블록 보상)도 표시됩니다. 집에서 사용하는 하드웨어 지갑은 웹 기반 또는 전화 기반 소프트웨어 지갑보다 훨씬 안전하므로 많은 양의 CMT를 보관하고 스테이크하기에 이상적입니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '2',
        button0: {
          link: '/cmt/whitepaper/',
          label: 'Cybermiles DPoS 프로토콜',
          icon: 'null',
        },
        button1: {
          link: 'https://goo.gl/forms/I3cYxGLdJLpX14Hz2',
          label: 'Genesis Validator 신청',
          icon: 'null',
        },
      },
    },
  },
  accordions: {
    number: '1',
    accordion0: {
      images: {
        number: '0',
      },
      title: '자주묻는 질문',
      description: {
        richText: 'null',
        description: 'null',
      },
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '0',
      },
      number: '11',
      drawer0: {
        title: '사이버마일스의 검증인이 되면 어떤 혜택이 있나요?',
        description: {
          richText: 'true',
          description:
            '<p>위임자들은 검증인에게 블록 보상의 일부를 지급합니다. 블록 보상의 검증인 지분이 검증인 보상입니다. 검증인의 보상은 스테이크를 할 때 결정되며 네트워크에 의해 자체적으로 기록되고 시행됩니다.</p><p>보상 비율은 검증인 자신이 다른 검증인들과의 경쟁을 고려하여 직접 설정합니다. 검증인들은 또한 네트워크 거버넌스에 참여합니다.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer1: {
        title: '검증인이 되기 위한 요구 사항은 무엇입니까?',
        description: {
          richText: 'true',
          description:
            '<p>검증인은 블록체인 네트워크의 성능과 보안을 보장하기 위해 네트워크 서버가 전문적으로 유지되는 수퍼 노드를 운영해야합니다.</p><p>네트워크 서버관리 외에도 새로운 후보 선언시 네트워크는 후보자의 계좌에서 신고 된 최대 금액의 10 %를 즉시 취하여 지분으로 유지하게 됩니다.</p><p>사이버마일즈 시스템에는 19 명의 검증인만 존재하기 때문에 검증인들은 글로벌 블록체인 네트워크를 유지 하기 위한 최신 소프트웨어, 충분한 컴퓨팅 성능 및 네트워크 대역폭을 실행하는 보안 서버를 제공함으로써 위임자 투표 (스테이크 CMT)를 두고 경쟁합니다.  사이버 마일즈 재단 (CyberMiles Foundation)은 또한 19 명의 검증인 중 하나가 될 수 있다고 판단되는 후보자에게 추가 인증을 제공합니다.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer2: {
        title: '검증인 선거에서 공인된 후보가 되는 방법은 무엇입니까?',
        description: {
          richText: 'true',
          description:
            '<p>검증인 후보는 네트워크에 자신의 입후보를 선언합니다. 이 선언에서 3가지 정보를 제공합니다:</p><ol><li>관할 구역, 데이터 센터 위치, 보안 설정 및 기술 설정을 포함하는 운영 및 자격 증명에 대한 세부 정보를 제공합니다. 사이버 마일즈 재단은 모든 검증인 후보자를 평가하고 재단의 기준 (정보 정확도, 기술 역량 및 하드웨어 / 네트워크 설정)을 충족하는 후보자를 공개적으로 알립니다. 그러나 위임자는 재단 기준에 부합하는지 여부와 상관없이 모든 후보자들 중에서 자유롭게 선택하여 투표(stake) 할 수 있습니다.</li><li>스테이크로 받아 들일 수있는 CMT의 최대량. 하나의 검증인이 너무 커져서 생기는 독점 위험을 방지하기 위해 네트워크 프로토콜은 아주 큰 검증인에게 투표하는 위임자에게 페널티를 줍니다.(자세한 내용은 “B4. 블록 지급 및 검증인 보상” 부분의 상세 사항 참조)</li><li>위임자에게 요구하는 검증인의 보상 비율.  예를 들어, 40% 비율은 이 검증인에게 투표권을 행사한 위임자들이 획득한 블록 지급의 40%를 의미하며, 이 검증인에게 보상으로 지급됩니다.</li></ol><p>사이버마일스 재단은 후보자의 정보를 검토합니다. 재단이 해당 정보의 정확성을 검증할 수 있다면, 후보자가 “검증됨”을 표시합니다. CMT 보유자는 검증되지 않은 후보를 포함하여 마음에 드는  검증인 후보에게 투표권을 행사할 수 있지만, 재단에 의해 검증된 후보자는 CMT 보유자가 보다 확신을 갖고 투표할 수 있게 해줍니다.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer3: {
        title: '검증인과 대리인이 스테이크로 획득할 수 있는 액수는 얼마입니까?',
        description: {
          richText: 'true',
          description:
            '<p>검증인 후보가 상위 19위 안에 들기에 충분한 스테이크를 받으면, 검증인이 되며, 모든 해당 대리인들이 블록 지급(대략 10초마다)을 받기 시작합니다. 블록 지급에는 두 부분이 포함됩니다:</p><ol><li>시스템의 연간 인플레이션 비율은 8%입니다. 이 인플레이션은 새롭게 발행된 CMT로 대리인들에게 배분됩니다.</li><li>또한 대리인들은 블록체인의 과중 사용자가 지불하는 거래 수수료도 받게 됩니다.</li></ol><p>시스템의 총 블록 지급은 각 대리인의 스테이크에 비례하게 대리인에게 우선 할당됩니다. 그런 다음 시스템이 자동적으로 검증인의 보상 비율에 기반해 검증인과 대리인에게 블록 지급을 배분합니다.</p><p>검증인의 입장에서, 검증인은 두 자원으로부터 블록 지급을 받습니다. 바로 블록 검증으로 인한 셀프 스테이크 CMT와 네트워크 유지 관리로 인해 대리인에게서 받는 보상입니다.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer4: {
        title: '현재까지 후보자는 누가 있습니까?',
        description: {
          richText: 'true',
          description:
            '<p>이미 여러 명의 유명한 후보자들이 검증인이 되는 것에 대해 관심을 표명했습니다. 몇몇 후보를 소개하자면, Susquehanna International Group, Great Voyage Capital, LinkVC, AlphaCoin Fund, Shuanghua Capital, IDG Capital 등이 있습니다.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer5: {
        title: '위임자가 CMT stake를 취소하면 어떻게 되나요?',
        description: {
          richText: 'true',
          description:
            '<p>CMT 보유자(위임자)가 자신의 CMT를 검증인 후보에게 이미 stake한경우 stake된 CMT는 한동안 거래할 수 없습니다. 위임자는 stake취소를 요청하면 일주일후에야 다시 CMT를 거래할 수 있다는 사실을 기억해 두셔야 합니다.위임취소의 대기 기간 일주일은 “장거리 이중 지출 공격”이라 불리는 것으로부터 보호하기 위함입니다.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer6: {
        title: '일부 검증인들이 잘못된 행동을 하면 어떻게 되나요?',
        description: {
          richText: 'true',
          description:
            '<p>검증인이 유효하지 않게 되거나 나머지 검증인들과 다른 결과를 생성하는 경우 시스템은 10초마다 모든 블록에서 전체 지분의 0.1 %씩 축소 및 제거합니다. 즉, 검증인과  모든 해당 위임자가 CMT를 어느정도 잃게됩니다 12번의 연속된 축소후에 시스템은 해당 검증인을 제거하고 다음 검증인 후보를 검증인으로 승격합니다.</p><p>제거된 검증인은 더 이상 손실을 입지 않지만  투표한 위임자는 누구도 블록보상을 받을 수 없습니다. 해당 위임자들은 스테이크 취소를 요청할 수 있고, 그런 다음 1주의 대기 기간 후 자신의 CMT를 다시 다른곳에 stake할 수 있습니다.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer7: {
        title:
          '블록체인 네트워크에 대한 검증인의 잠재적 공격(예: 51 % 공격)을 어떻게 방지합니까?',
        description: {
          richText: 'true',
          description:
            '<p>사이버마일즈 프로토콜은 하나의 검증인이 너무 커지지 않도록 저지합니다. 단일 검증인의 지분이 네트워크 총 지분의 12 % 이상으로 증가하면 이 검증인은 네트워크의 불안정성을 유발할 수 있습니다. 그래서, 네트워크가 블록보상을 배분할때 모든 해당 위임자들은 12% 한도 금액을 초과하는 스테이크에 대해서는 감소된 블록보상을 받게 됩니다.이 한도금액의 설정은 대량 CMT 보유자가 여러 검증인에게  다각화된 투표를 하도록 유도하며 검증인들이 수용하는 스테이크 최대치를 제한하도록 유도합니다. 각 검증인의 투표권을 계산하는 알고리즘은 다음과 같습니다.</p><ol><li>각 검증인의 지분 금액에 따라 투표권을 배분하지만, 투표권의 상한선은 12 %로 제한됩니다. 예를 들어, 검증인 A가 미결제 지분5 %를 보유하고 있다면, 그는 5 %의 투표권을 얻습니다. 유효성 검사기 B가 지분의 20 %를 보유했다 하더라도 그는 12%의 투표권을 얻게 됩니다.</li><li>12%를 초과하여 투표권이 남은경우 각 검증인의 실제 스테이크 금액을 기반으로 두 번째 비례배분이 이루어집니다.</li></ol>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer8: {
        title:
          '시스템이 검증인을 거부하면 그 다음은 어떻게 되나요?',
        description: {
          richText: 'true',
          description:
            '<p>19 명의 공식 검증인 외에도 staking CMT가 19 위 검증인보다 적은 검증인 후보자들이 있습니다. 그들은 백업 검증인이라 부릅니다. 백업 검증인이 충분한 지분을 확보하여 19위 검증인을 추월할 가능성은 항상 있습니다. 기존의 검증인이 처벌되거나 사퇴하거나 검증인으로서의 기능을 완전히 중단 할 경우 백업검증인이 새로운 검증인이 되고 그 다음 순위에 있던 후보가 백업 검증인이 됩니다.</p><p>백업 검증인은 자신에게 stake된  CMT에 대해 블록보상을 획득하지 못합니다. 그들은 보통 생태계 진입을 바라고 기회를 살피고 있습니다. 재단은 네트워크 의 안정성을 유지하기 위해 백업 검증인 후보를 다수 운영할것입니다.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer9: {
        title: 'CMT큐브는 얼마나 많은 CMT를 수용할수있나요?',
        description: {
          richText: 'true',
          description:
            '<p>하나의 CMT 큐브는 최대 100,000 개의 CMT를 수용 할 수 있으며 최소 수량은 1,000 개입니다.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer10: {
        title: '어디에서 CMT 큐브를 구할 수 있습니까?',
        description: {
          richText: 'true',
          description:
            '<p>현재 일시 품절되었습니다.</p>',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
