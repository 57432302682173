module.exports = {
  title: 'Security Token',
  'meta-description': 'Security Token',
  banner: {
    title: 'Security Token',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: 'Challenges for Traditional E-commerce',
      description: {
        richText: 'null',
        description:
          'A major challenge in all commercial operations, but especially true for fast paced e-commerce, is cash flow management to sustain and grow the operation. Securitization is a key tool for more efficient cash flow management. However, traditional securitization through Wall Street is expensive and inefficient as it requires expensive and slow human intervention in every step.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'What is CyberMiles solution?',
      description: {
        richText: 'null',
        description:
          'The CyberMiles blockchain makes it easy to issue security tokens for e-commerce businesses to manage cash flow and raise operating capital.',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: 'Contact Us',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_shield',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'Supports all popular issuing standards including ERC 1400, ERC 1440, ST-20, R-Token, S3 and more.',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_ofn',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'Close collaboration with exchanges such as the OFN (OpenFinance).',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_rise',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'Optimized for e-commerce related cash flow securitization.',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
