module.exports = {
  title: '证券化token',
  'meta-description': '证券化token',
  banner: {
    title: '证券化token',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '传统电商的挑战',
      description: {
        richText: 'null',
        description:
          '所有商业运营，尤其是快节奏的电子商务，面临的主要挑战都是如何更好的进行现金流管理以维持和发展商业运营。证券化是提高现金流管理效率的关键工具。 然而，华尔街的传统证券化是昂贵并且低效的，因为这其中每一步都有昂贵且缓慢的人为干预。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMiles 解决方案',
      description: {
        richText: 'null',
        description:
          'CyberMiles公链使电商企业发行证券化token变得非常简单，方便其管理现金流，提升运营资本。',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '联系我们',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_shield',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '支持所有主流的发行标准，如ERC1400、ERC1440、ST-20、R-Token、S3等',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_ofn',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '与OFN交易所紧密合作',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_rise',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '针对电商相关的现金流证券化进行了优化',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
