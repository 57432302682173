module.exports = {
  title: 'Venus',
  'meta-description': 'CyberMiles Venus',
  banner: {
    title: 'Venus',
    backgroundImage: 'bannerCMT',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
metamask: {
    number: '1',
    centered0: {
      images: {
        number: '1',
        image0: {
          gif: 'null',
          path: 'metamask',
          link: 'null',
        },
      },
      title: 'CyberMiles를 브라우저로 가져옵니다.',
      description: {
        richText: 'true',
        description:
          `<p>Venus는 오늘날 브라우저의 분산 웹을 방문 할 수있게 해주는 다리입니다. CyberMiles 노드를 실행하지 않고도 브라우저에서 CyberMiles dApp을 실행할 수 있습니다.</p><p>Venus에는 보안 ID 저장소가 포함되어있어 서로 다른 사이트에서 ID를 관리하고 블록 체인 트랜잭션에 서명하는 사용자 인터페이스를 제공합니다.</p><p>Chrome 브라우저에 Venus 애드온을 설치할 수 있습니다. 개발자라면 오늘 Venus로 개발을 시작할 수 있습니다.</p>`,
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          link:
            'https://chrome.google.com/webstore/detail/venus/hmiddckbbijmdkamphkgkelnjjdkicck',
          label: 'Chrome 익스텐션 받기',
          icon: 'null',
        },
      },
    },
  },
  columns: {
    number: '1',
    columns0: {
      number: '3',
      columns: '3',
      title: 'Installation Guide',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      backgroundImage: 'shortLastSection',
      column0: {
        image: {
          gif: 'null',
          path: 'null',
          link: 'null',
        },
        title: '1',
        description: {
          richtext: 'null',
          description: 'Download the “metamask4cmt.crx” file',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        image: {
          gif: 'null',
          path: 'null',
          link: 'null',
        },
        title:
          '2',
        description: {
          richtext: 'null',
          description: 'Open the Chrome browser “Extensions” and enable the developer mode',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        image: {
          gif: 'null',
          path: 'null',
          link: 'null',
        },
        title:
          '3',
        description: {
          richtext: 'null',
          description: 'Drag and drop the “metamask4cmt.crx” file to the extension interface',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
