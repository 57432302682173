module.exports = {
  title: 'Team & Advisors',
  'meta-description': 'The Cybermiles management and advisory team are made up of scientists, developers, and entrepreneurs giving dynamics and years of experience to the company.',
  banner: {
    title: 'Team & Advisors',
    backgroundImage: 'bannerGlobe',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  teamMembers: {
    number: '2',
    teamMembers0: {
      title: 'Team',
      backgroundImage: 'null',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      number: '5',
      teamMember0: {
        image: 'lucas',
        name: 'Lucas Lu, PhD',
        linkedin: 'https://www.linkedin.com/in/sixwings/',
        title: 'Founder',
        description: {
          richtext: 'null',
          description:
            'Lucas received his PHD degree in Partical Physics from SMU in 2005. He worked at CERN when he participated in theoretical and experimental research of Higgs Partical. Dr. Lucas Lu, was previously a co-founder and CTO of Light In the Box, a company which went public on the NYSE, and prior to that, Lucas was the first GM of Alibaba’s Taobao Mobile platform, and was the GM of another Alibaba business unit. Lucas founded 5miles in 2014.',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember1: {
        image: 'michael',
        name: 'Michael Yuan, PhD',
        linkedin: 'https://www.linkedin.com/in/myuan/',
        title: 'Chief Scientist',
        description: {
          richtext: 'null',
          description:
            'Michael received a PhD in Astrophysics from University of Texas at Austin. He is the author of 5 books on software development, published by Prentice Hall, Addison-Wesley, and O’Reilly. Michael was an active code committer in large Open Source projects such as Firefox, Fedora, JBoss, and others. He is an expert on enterprise and mobile software and was a Principle Investigator on multiple research projects funded by the US government.',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember2: {
        image: 'tim',
        name: 'Timothy McCallum',
        linkedin: 'https://www.linkedin.com/in/tim-mccallum-2226413a/',
        title: 'Core Developer',
        description: {
          richtext: 'null',
          description:
            'Timothy McCallum specializes in blockchain implementations for businesses. A specialist in FinTech programming, Timothy’s most recent project was a finance data migration exercise for local government. Timothy was named as an AMP Tomorrow Maker in 2015. His work evolves around finding extraordinary solutions to complex problems. Timothy is a celebrated mentor of the Google Summer of Code and was a guest of Google in 2014, spending time in Silicon Valley collaborating with software developers from around the globe.',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember3: {
        image: 'alex',
        name: 'Alex Lau',
        linkedin: 'https://www.linkedin.com/in/avengermojo/',
        title: 'Core Developer',
        description: {
          richtext: 'null',
          description:
            'AvengerMoJo is Alex’s online nickname. He received his Software Computer Science degree at University of North Texas in 1999. Previously, Alex worked at Nortel Network, before moving to China. He was the R&D Manager for SUSE Linux in Beijing and Taiwan and was the CTO for Symbio Mobile. He has been working as a consultant for ceph distributed filesystem for SUSE and as a Blockchain Consultant for WeBank and 5miles.',
        },
        buttons: {
          number: '0',
        },
      },
      
//       teamMember5: {
//         image: 'garwin',
//         name: 'Garwin Chan',
//         linkedin: 'https://www.linkedin.com/in/garwin-chan-b8378a1/',
//         title: 'CFO',
//         description: {
//           richtext: 'null',
//           description:
//             'Garwin is currently the CFO of 5miles. Previously, he was a Managing Director at Founder H Fund (Private Equity arm of Founder Group). Prior to that, he was a Vice President at Bain Capital, a global leading investment firm. Garwin graduated from Harvard College cum laude in 2003. He also received his MBA degree from Harvard Business School in 2008.',
//         },
//         buttons: {
//           number: '0',
//         },
//       },
      teamMember4: {
        image: 'mark',
        name: 'Mark Brinkerhoff',
        linkedin: 'https://www.linkedin.com/in/thinkbrink/',
        title: 'VP of Communication',
        description: {
          richtext: 'null',
          description:
            'Mark Brinkerhoff is a startup advisor and communications strategist with a contemporary approach to public relations, a passion for building partnerships and storytelling that connects consumers and brands. With more than a dozen years of experience in results-driven PR, Brinkerhoff has become a leader in business-driving marketing and brand communications for innovative, fast-growing startups, particularly consumer technology.',
        },
        buttons: {
          number: '0',
        },
      },
    },
    teamMembers1: {
      title: 'Advisors',
      backgroundImage: 'bannerCube',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      number: '5',
      teamMember0: {
        image: 'garwin',
        name: 'Garwin Chan',
        linkedin: 'https://www.linkedin.com/in/garwin-chan-b8378a1/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'Garwin is the former CFO of 5miles. Previously, he was a Managing Director at Founder H Fund (Private Equity arm of Founder Group). Prior to that, he was a Vice President at Bain Capital, a global leading investment firm. Garwin graduated from Harvard College cum laude in 2003. He also received his MBA degree from Harvard Business School in 2008.',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember1: {
        image: 'marc',
        name: 'Marc Fleury',
        linkedin: 'https://en.wikipedia.org/wiki/Marc_Fleury',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'Dr. Fleury is a pioneer in software freedom and the business of decentralization. He is the creator of JBoss, an open-source Java application server, and an ex-EVP of Red Hat Inc. He is also an early thought leader and investor in cryptocurrencies. Marc holds a degree in mathematics and a doctorate in physics from the École Polytechnique in Paris and a Master in Theoretical Physics from the École Normale Supérieure.',
        },
        buttons: {
          number: '0',
        },
//       },
//       teamMember2: {
//         image: 'malachi',
//         name: 'Malachi Boyuls',
//         linkedin: 'https://www.linkedin.com/in/malachi-boyuls-5b486b90/',
//         title: 'null',
//         description: {
//           richtext: 'null',
//           description:
//             'Malachi is a partner at St. Augustine Capital Partners, a partnership offering consulting services and strategic advice to clients in industries such as technology, energy, real estate, and financial services. Previously, he was an attorney in Dallas at Gibson, Dunn, & Crutcher LLP, where he practiced in the firm’s regulatory groups, including antitrust, energy, securities and intellectual property. He graduated from New York University School of Law.',
//         },
//         buttons: {
//           number: '0',
//         },
      },
      teamMember2: {
        image: 'george',
        name: 'George Chen',
        linkedin: 'https://www.linkedin.com/in/george-chen-ab9106/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'George is the former CMO of 5miles. Prior to joining in 5miles, he was V.P. of Product Development at Allyes, the largest ad platform and digital marketing agency in China. With decades of experience in marketing, particularly digital, he was the principal engineer of DoubleClick, a subsidiary of Google. Chen earned his Master’s Degree in Physics from Rice University and BA at University of Science and Technology of China.',
        },
        buttons: {
          number: '0',
        },
      },
      
      teamMember3: {
        image: 'frank',
        name: 'Frank Lee',
        linkedin: 'https://www.linkedin.com/in/frank-lee-873902106/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'Founder of several start-up companies and Angel Investor. Successfully developed the first Litecoin ASIC miner in the world. Two companies are included in MI eco system. Invested and co-founded companies in various areas, including Game, VR, AR, IOT, etc. Graduated from Tsinghua University with degree in Electrical Engineering.',
        },
        buttons: {
          number: '0',
        },
      },
      teamMember4: {
        image: 'ash',
        name: 'Ash Han',
        linkedin: 'https://www.linkedin.com/in/ashhan/',
        title: 'null',
        description: {
          richtext: 'null',
          description:
            'Ash Han is an angel backer and blockchain evangelist. He is also CEO of Finector, the biggest blockchain/DLT consulting company in South Korea having subscribers of governmental bodies, banks, financial institutions, etc and individuals. He has been dedicated to the blockchain industry since 2012 as an angel investor, co-founder, advisor, community organizer, author and public speaker with a deep understanding of the blockchain economy and technology.',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  partnerBoxes: {
    number: '2',
    boxes0: {
      title: 'Partners',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '8',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      box0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'fivemiles',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'blockchainVentures',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'linkvc',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'collinstar',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box4: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'aware',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box5: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'chainedbox',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box6: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'cybervein',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
      box7: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'hyperpay',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
    },
    boxes1: {
      title: 'Counsel',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '2',
      columns: '2',
      buttons: {
        number: '0',
      },
      backgroundImage: 'shortLastSection',
      box0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'yeewhy',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
//       box1: {
//         images: {
//           number: '1',
//           image0: {
//             gif: 'null',
//             path: 'deloitte',
//             link: 'null',
//           },
//         },
//         title: 'null',
//         description: {
//           richText: 'true',
//           description: 'null',
//         },
//         buttons: {
//           number: '0',
//         },
//       },
      box1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'lockeLord',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description: 'null',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
