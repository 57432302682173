module.exports = {
  title: 'Venus',
  'meta-description': 'CyberMiles Venus',
  banner: {
    title: 'Venus',
    backgroundImage: 'bannerCMT',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
metamask: {
    number: '1',
    centered0: {
      images: {
        number: '1',
        image0: {
          gif: 'null',
          path: 'metamask',
          link: 'null',
        },
      },
      title: 'ブラウザにCyberMilesを統合する',
      description: {
        richText: 'true',
        description:
          `<p>Venusは、既存のブラウザで次世代の分散型Webにアクセスするための架け橋です。 CyberMilesノードを実行せずに、ブラウザでCyberMiles dAppを正しく実行することができます。</p><p>Venusは安全なidentity vaultを含み、さまざまなサイトでのログイン情報を管理し、ブロックチェーントランザクションに署名するためのユーザーインターフェースを提供します。</p><p>VenusはChromeブラウザにインストールできるアドオンです。 Venusを使って開発を進めることもできます。</p>`,
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          link:
            'https://chrome.google.com/webstore/detail/venus/hmiddckbbijmdkamphkgkelnjjdkicck',
          label: 'CHROMEの拡張機能を利用する',
          icon: 'null',
        },
      },
    },
  },
  columns: {
    number: '1',
    columns0: {
      number: '3',
      columns: '3',
      title: 'Installation Guide',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      backgroundImage: 'shortLastSection',
      column0: {
        image: {
          gif: 'null',
          path: 'null',
          link: 'null',
        },
        title: '1',
        description: {
          richtext: 'null',
          description: 'Download the “metamask4cmt.crx” file',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        image: {
          gif: 'null',
          path: 'null',
          link: 'null',
        },
        title:
          '2',
        description: {
          richtext: 'null',
          description: 'Open the Chrome browser “Extensions” and enable the developer mode',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        image: {
          gif: 'null',
          path: 'null',
          link: 'null',
        },
        title:
          '3',
        description: {
          richtext: 'null',
          description: 'Drag and drop the “metamask4cmt.crx” file to the extension interface',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
