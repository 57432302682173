module.exports = {
  title: 'Develop Hub',
  'meta-description': 'Do you want to create smart contracts or contribute to the Cybermiles blockchain in its core development? Join our community and access technical resources.l',
  banner: {
    title: '개발자 포털',
    backgroundImage: 'bannerLity',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '개발자 센터 ',
      description: {
        richText: 'null',
        description:
          'CyberMiles는 안전하고 자유로운 블록 체인 소프트웨어 프로토콜입니다.개발자들이 무료로 사용할수있으며, 스마트하게 분산 응용 프로그램 (DApp)을  구축 할 수있게 합니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '시작하기',
          link: 'https://travis.readthedocs.io/en/latest/getting-started.html',
        },
      },
    },
  },
  indexMenu: {
    number: '2',
    menu0: {
      label: '참고 및 SDK',
      number: '5',
      backgroundImage: 'null',
      links: {
        link0: {
          label: 'CyberMiles getting started',
          link: 'https://travis.readthedocs.io/en/latest/getting-started.html',
        },
        link1: {
          label: 'Lity getting started',
          link: 'https://www.litylang.org/getting_started/',
        },
        link2: {
          label: 'Smart contract hello world',
          link: 'https://www.cybermiles.io/en-us/blog/hello-world/',
        },
        link3: {
          label: 'Web3-cmt',
          link: 'https://cybermiles.github.io/web3-cmt.js/api/#example',
        },
        link4: {
          label: 'Lity or Solidity',
          link: 'https://www.litylang.org/',
        },
      },
    },
    menu1: {
      label: 'Tools',
      number: '5',
      links: {
        link0: {
          label: 'Venus',
          link: '/blockchain-infrastructure/venus/',
        },
        link1: {
          label: 'Europa',
          link: 'http://europa.cybermiles.io',
        },
        link2: {
          label: 'CMT Testnet Faucet',
          link: 'http://travis-faucet.cybermiles.io',
        },
        link3: {
          label: 'CyberMiles App SDK',
          link:
            'https://travis.readthedocs.io/en/latest/cmtwallet-dapp-sdk-doc.html',
        },
        link4: {
          label: 'Saturn',
          link:
            'https://github.com/CyberMiles/truffle/blob/lity/README.md',
        },
//         link4: {
//           label: 'CMT Wallet for Testnet',
//           link:
//             '/',
//         },
      },
    },
  },
  learningResources: {
    title: 'Learning Resources',
    backgroundImage: 'null',
    articles: {
      title: 'Articles',
      buttons: {
        label: 'View More',
        link: '/about-us/blog/',
      },
    },
    videos: {
      title: 'Online Videos',
      buttons: {
        label: 'View More',
        link: '/about-us/videos/',
      },
    },
    meetups: {
      title: 'Meetups',
      buttons: {
        label: 'View More',
        link: '/about-us/meetups/',
      },
    },
  },
  steps: {
    number: '1',
    steps0: {
      number: '4',
      title: 'Learning Path',
      description: {
        richText: 'null',
        description: 'null',
      },
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      step0: {
        step: '1',
        image: {
          gif: 'null',
          path: 'step1',
          link: 'null',
        },
        title: 'Understand CyberMiles Blockchain',
        description: {
          richtext: 'null',
          description:
            'To start developing on the CyberMiles blockchain, the first step is to deploy your own node. The easiest and fastest way to start a node is to use a snapshot. While we highly recommend you to run your own Travis node, you could still directly access RPC services from a node provided by the CyberMiles Foundation.',
        },
        buttons: {
          number: '0',
        },
      },
      step1: {
        step: '2',
        image: {
          gif: 'null',
          path: 'step2',
          link: 'null',
        },
        title: 'Write a Smart Contract',
        description: {
          richtext: 'null',
          description:
            'To build your first smart contract on CyberMiles blockchain, learning about Lity is essential. Lity is a superset of the Solidity language and is more extensible, performant, and safe.',
        },
        buttons: {
          number: '0',
        },
      },
      step2: {
        step: '3',
        image: {
          gif: 'null',
          path: 'step3',
          link: 'null',
        },
        title: 'Create a Simple DApp',
        description: {
          richtext: 'null',
          description:
            'To deploy your first Dapp on CyberMiles blockchain, get Metamask for CMT and Europa up and running in the Chrome. It allows you to run CyberMiles DApp right in your browser without running a CyberMiles node.',
        },
        buttons: {
          number: '0',
        },
      },
      step3: {
        step: '4',
        image: {
          gif: 'null',
          path: 'step4',
          link: 'null',
        },
        title: 'Make a Mobile DApp',
        description: {
          richtext: 'null',
          description:
            'To build a mobile Dapp on CyberMiles blockchain, start by exploring our CyberMiles App. The CyberMiles App DApp browser is fully compatible with Metamask for CMT, you can migrate DApp directly to CyberMiles App without even writing any code.',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  iconColumns: {
    number: '1',
    columns0: {
      title: '개발자 커뮤니티 ',
      description: {
        richText: 'null',
        description:
          '1000+의 개발자와 같이 토론하기', 
      },
      backgroundImage: 'shortLastSection',
      number: '3',
      columns: '3',
      buttons: {
        number: '0',
      },
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'telegram',
            link: 'https://t.me/cybermilestoken',
          },
        },
        title: 'null',
        description: {
          richText: 'null',
          description:
            'Telegram',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'github',
            link: 'https://github.com/cybermiles',
          },
        },
        title: 'null',
        description: {
          richText: 'null',
          description:
            'Github',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'forum',
            link: 'https://forum.cybermiles.io/',
          },
        },
        title: 'null',
        description: {
          richText: 'null',
          description:
            'Forum',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
