module.exports = {
  title: 'Footer',
  'meta-description': 'Footer',
  address:
    "CyberMiles Foundation Limited - 13/F Gloucester Tower, The Landmark, 15 Queen's Road Central, Hong Kong",
  copyright: 'Copyright © 2018. All rights reserved',
  subscribe: {
    label: '订阅',
    title: '获取CyberMiles公链最新信息'
  },
  links: {
    contact: {
      label: '联系我们',
      link: '/about-us/contact-us',
    },
    privacy: {
      label: '隐私',
      link: '/privacy',
    },
    terms: {
      label: '条款',
      link: '/terms',
    },
  },
  iconsTitle: "关注我们"
}
