module.exports = {
  title: '稳定币',
  'meta-description': '稳定币',
  banner: {
    title: '稳定币',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '传统电商的挑战',
      description: {
        richText: 'null',
        description:
          '虽然电子商务是最早，也是最受欢迎的加密货币使用案例，但加密货币价格大幅波动，手续费高和速度缓慢阻碍了其进一步被采用。 在大多数国家地区，消费者要求商品以法定货币定价。 与此同时，BTC和ETH交易费高，确认时间缓慢的特点并不适合电子商务支付，特别是小额支付。 为了充分实现加密货币在电子商务中的使用，我们需要一种新的加密货币，与法定货币挂钩，支持高速交易，并且手续费非常低。这就是稳定币。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMiles解决方案',
      description: {
        richText: 'null',
        description:
          'CyberMiles公链针对稳定币发行和交易进行了优化。 CyberMiles 验证人（又称，超级节点）确保跨链存款和稳定币的兑换是透明有效的。 CyberMiles虚拟机能够满足基于智能合约稳定币的电商大规模支付所需的高性能要求。',
      },
      number: '3',
      columns: '3',
      backgroundImage: 'null',
      buttons: {
        number: '1',
        button0: {
          label: '联系我们',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_symbol',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '每个用例都有多个稳定币与GUSD和BTC挂钩',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_link',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '完全由区块链上可核实的存款支持',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_otc',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '通过链上OTC交易所实现高流动性',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
