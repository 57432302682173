export default {
  title: 'Header',
  items: [
    {
      label: 'CMT',
      link: '#',
      children: [
        {
          disabled: 'false',
          label: '전체보기',
          link: 'cmt/overview/',
          children: [],
        },
        {
          disabled: 'false',
          label: 'CMT큐브',
          link: 'cmt/cmt-cube/',
          children: [],
        },
        {
          disabled: 'false',
          label: '검증인',
          link: 'cmt/validator/',
          children: [],
        },
        {
          disabled: 'false',
          label: '백서',
          link: 'cmt/whitepaper/',
          children: [],
        },
      ],
    },
    {
      label: '전자 상거래 솔루션',
      link: '#',
      children: [
        {
          label: '금융',
          link: '#',
          children: [
            {
              disabled: 'false',
              label: '페이먼트 게이트웨이',
              link: 'ecommerce-solutions/finance/payment-gateway/',
              children: [],
            },
            {
              disabled: 'false',
              label: '시큐리티 토큰',
              link: 'ecommerce-solutions/finance/security-token/',
              children: [],
            },
            {
              disabled: 'false',
              label: '스테이블 코인',
              link: 'ecommerce-solutions/finance/stable-coin/',
              children: [],
            },
          ],
        },
        {
          label: '마케팅',
          link: '#',
          children: [
            {
              disabled: 'false',
              label: '마케팅 추천 프로그램',
              link: 'ecommerce-solutions/marketing/marketing-referral-program/',
              children: [],
            },
            {
              disabled: 'false',
              label: '사용자 참여 솔루션',
              link: 'ecommerce-solutions/marketing/user-engagement-solutions/',
              children: [],
            },
          ],
        },
        // {
        //   label: 'Governance',
        //   link: '#',
        //   children: [
        //     {
        //       disabled: 'true',
        //       label: 'Public Witness',
        //       link: 'ecommerce-solutions/governance/public-witness/',
        //       children: [],
        //     },
        //     {
        //       disabled: 'true',
        //       label: 'Evidence Capture',
        //       link: 'ecommerce-solutions/governance/evidence-capture/',
        //       children: [],
        //     },
        //     {
        //       disabled: 'true',
        //       label: 'Provenance',
        //       link: 'ecommerce-solutions/governance/provenance/',
        //       children: [],
        //     },
        //     {
        //       disabled: 'true',
        //       label: 'Data Marketplace',
        //       link: 'ecommerce-solutions/governance/data-marketplace/',
        //       children: [],
        //     },
        //     {
        //       disabled: 'true',
        //       label: 'Community Governance',
        //       link: 'ecommerce-solutions/governance/community-governance/',
        //       children: [],
        //     },
        //   ],
        // },
      ],
    },
    {
      label: '블록체인 인프라',
      link: '#',
      children: [
        {
          disabled: 'false',
          label: 'CMT Tracking',
          link: 'https://www.cmttracking.io/',
          children: [],
        },
        // {
        //   disabled: 'false',
        //   label: 'CMT TestNet',
        //   link: 'https://testnet.cmttracking.io/',
        //   children: [],
        // },
        {
          disabled: 'false',
          label: 'CMT 월렛',
          link: 'https://app.cybermiles.io',
          children: [],
        },
        {
          disabled: 'false',
          label: 'Venus',
          link: 'blockchain-infrastructure/venus/',
          children: [],
        },
        {
          disabled: 'false',
          label: 'Europa',
          link: ' http://europa.cybermiles.io',
          children: [],
        },
      ],
    },
    {
      label: '개발자 포털',
      link: '#',
      children: [
        {
          disabled: 'false',
          label: '개발자 허브',
          link: 'developer-portal/developer-hub',
          children: [],
        },
        // {
        //   label: 'Documentation',
        //   link: '#',
        //   children: [
        //     {
        //       disabled: 'true',
        //       label: 'Technical Benchmark',
        //       link: 'developer-portal/documentation/technical-benchmark/',
        //       children: [],
        //     },
        //   ],
        // },
      ],
    },
    {
      label: '정보CMT',
      link: '#',
      children: [
        {
          disabled: 'false',
          label: '팀',
          link: 'about-us/team/',
          children: [],
        },
        // {
        //   disabled: 'true',
        //   label: 'FAQ',
        //   link: 'about-us/faq/',
        //   children: [],
        // },
        {
          disabled: 'false',
          label: '블로그',
          link: 'https://blog.cybermiles.io/categories/ko/',
          children: [],
        },
        {
          disabled: 'false',
          label: '로드맵',
          link: 'about-us/roadmap/',
          children: [],
        },
        {
          disabled: 'false',
          label: '연락처',
          link: 'about-us/contact-us/',
          children: [],
        },
      ],
    },
  ],
}
