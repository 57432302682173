module.exports = {
  title: 'CyberMiles(CMT/草莓糖) 白皮书',
  'meta-description': '查看CyberMiles白皮书，了解有关CyberMiles区块链技术的更多信息。',
  banner: {
    title: 'CyberMiles 白皮书',
    backgroundImage: 'bannerCoin',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  documents: {
    number: '3',
    columns0: {
      title: 'DPoS 白皮书',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '2',
      columns: '3',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'EN_DPoS',
            link:
              'https://prismic-io.s3.amazonaws.com/cybermiles%2Fe4cde5e9-6da6-44d1-b275-1c03e3786ecf_en_cybermiles_dpos.pdf',
          },
        },
        title: 'DPoS 白皮书',
        description: {
          richText: 'null',
          description: '英文',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'ZH_DPoS',
            link:
              'https://prismic-io.s3.amazonaws.com/cybermiles%2F0f6a8714-1de5-4be2-9b5f-2a01e94e860e_cn_cybermiles_dpos.pdf',
          },
        },
        title: 'DPoS 白皮书',
        description: {
          richText: 'null',
          description: '中文',
        },
        buttons: {
          number: '0',
        },
      },
      // column2: {
      //   images: {
      //     number: '1',
      //     image0: {
      //       gif: 'null',
      //       path: 'KO_DPoS',
      //       link:
      //         'https://prismic-io.s3.amazonaws.com/cybermiles%2F824620de-0a7b-4642-a850-a1d3c0411465_dpos+white+paper~%E1%84%89%E1%85%AE%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%83%E1%85%AC%E1%86%AB%E1%84%80%E1%85%A5%E1%86%BA.pdf',
      //     },
      //   },
      //   title: 'DPoS Protocol',
      //   description: {
      //     richText: 'null',
      //     description: '韩语',
      //   },
      //   buttons: {
      //     number: '0',
      //   },
      // },
    },
    columns1: {
      title: '项目白皮书',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '6',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'EN_Project_Whitepaper',
            link:
              'https://prismic-io.s3.amazonaws.com/cybermiles%2F3d49b308-207f-42d4-b36d-576625a20cbe_project-white-paper_en-us.pdf',
          },
        },
        title: '项目白皮书',
        description: {
          richText: 'null',
          description: '英文',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'ZH_Project_Whitepaper',
            link:
              'https://prismic-io.s3.amazonaws.com/cybermiles%2F851f62da-21ab-4777-9391-abf7124f4568_projeject-white-paper_zh-tw.pdf',
          },
        },
        title: '项目白皮书',
        description: {
          richText: 'null',
          description: '中文',
        },
        buttons: {
          number: '0',
        },
      },
      // column2: {
      //   images: {
      //     number: '1',
      //     image0: {
      //       gif: 'null',
      //       path: 'JA_Project_Whitepaper',
      //       link:
      //         'https://prismic-io.s3.amazonaws.com/cybermiles%2Ffb9cdd59-5868-428f-9ca5-8ec7e835c7e7_cybermiles-whitepaper-japanese.pdf',
      //     },
      //   },
      //   title: 'Project Whitepaper',
      //   description: {
      //     richText: 'null',
      //     description: 'Japanese',
      //   },
      //   buttons: {
      //     number: '0',
      //   },
      // },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'TR_Project_Whitepaper',
            link:
              'https://prismic-io.s3.amazonaws.com/cybermiles%2Fc08672f6-16c5-40c2-89ef-77d3a13a2159_project-white-paper_tr-tr.pdf',
          },
        },
        title: '项目白皮书',
        description: {
          richText: 'null',
          description: '土耳其语',
        },
        buttons: {
          number: '0',
        },
      },
      column3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'DE_Project_Whitepaper',
            link:
              'https://prismic-io.s3.amazonaws.com/cybermiles%2F49f0dbfd-d143-4b35-9b41-c1119e4a3895_cybermiles-whitepaper-v1.8-german-translation-final.pdf',
          },
        },
        title: '项目白皮书',
        description: {
          richText: 'null',
          description: '德语',
        },
        buttons: {
          number: '0',
        },
      },
      column4: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'KO_Project_Whitepaper',
            link:
              'https://prismic-io.s3.amazonaws.com/cybermiles%2F32b2b31b-eb6c-4801-a6b9-cfe52a09f459_project+white+paper_ko+%E1%84%89%E1%85%AE%E1%84%8C%E1%85%A5%E1%86%BC%E1%84%83%E1%85%AC%E1%86%AB%E1%84%80%E1%85%A5%E1%86%BA.pdf',
          },
        },
        title: '项目白皮书',
        description: {
          richText: 'null',
          description: '韩语',
        },
        buttons: {
          number: '0',
        },
      },
      column5: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'JA_Project_Whitepaper',
            link:
              'https://prismic-io.s3.amazonaws.com/cybermiles%2F485625fc-0192-432f-98a5-08290dc49231_project+whitepaper%28japanese%29.pdf',
          },
        },
        title: '项目白皮书',
        description: {
          richText: 'null',
          description: '日语',
        },
        buttons: {
          number: '0',
        },
      },
    },
    columns2: {
      title: '技术白皮书',
      description: {
        richText: 'null',
        description: 'null',
      },
      number: '3',
      columns: '3',
      buttons: {
        number: '0',
      },
      backgroundImage: 'shortLastSection',
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'EN_Technical_Whitepaper',
            link:
              'https://prismic-io.s3.amazonaws.com/cybermiles%2F1b823012-fd7e-42af-9d8c-fbd0734a3dfe_technical-whitepaper_en-us.pdf',
          },
        },
        title: '技术白皮书',
        description: {
          richText: 'null',
          description: '英文',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'ZH_Technical_Whitepaper',
            link:
              'https://prismic-io.s3.amazonaws.com/cybermiles%2Fb6bcb9bc-8f44-409c-976a-13ef0a087a8e_technical-whitepaper_zh-tw.pdf',
          },
        },
        title: '技术白皮书',
        description: {
          richText: 'null',
          description: '中文',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'IT_Technical_Whitepaper',
            link:
              'https://prismic-io.s3.amazonaws.com/cybermiles%2F9c089a7e-57f2-487e-8b84-e846d80ca7f0_technical-whitepaper-cybermiles-traduzione-ita-pdf.pdf',
          },
        },
        title: '技术白皮书',
        description: {
          richText: 'null',
          description: '意大利语',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
