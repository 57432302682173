module.exports = {
  title: 'CyberMiles路线图',
  'meta-description': '路线图',
  banner: {
    title: '路线图',
    backgroundImage: 'bannerGlobe',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  roadmap: {
    title: 'null',
    pending: '正在更新中，请耐心等待。',
    pendingDesc: '请在下方订阅栏留下您的邮箱，以获取最新消息。',
    description: {
      richtext: 'null',
      description: 'null',
    },
    backgroundImage: 'lastSection',
    buttons: {
      number: '0',
    },
    events: {
      number: '13',
      event0: {
        title: 'Q2',
        outline: `5miles数据上链已经在按照计划实施中，电商DApp探索之路已经开始。同时虚拟机也将迎来功能升级，支持合约创建者支付gas 费，这将大幅降低DApp的使用门槛，让更多的无币用户体验区块链技术。根据社区的意见和建议，我们将调整DPoS协议的激励算法，让CMT社区能够更多地从DPoS协议中受惠。`,
        parent: 'true'
      },
      event1: {
        title: '电商DApp',
        list: {
          size: '2',
          item0: '5miles数据上链',
          item1: '智能合约支持稳定币购买商品'
        }
      },
      event2: {
        title: '虚拟机功能升级',
        list: {
          size: '2',
          item0: '安全的随机数支持',
          item1: '支持合约创建者支付 gas 的机制'
        }
      },
      event3: {
        title: 'CyberMiles App',
        list: {
          size: '2',
          item0: '让新的钱包用户不需要买币就可以体验一些DApp',
          item1: '支持权益投票（采纳社区意见，但结果视社区投票情况而定）'
        }
      },
      event4: {
        title: '主链DPoS协议奖励机制调整',
        list: {
          size: '2',
          item0: '让算法简单，易懂',
          item1: '支持 unstake 期间的收益'
        }
      },
      event5: {
        title: 'Q3',
        outline: `本季度电商DApp将迎来重大进展，发布C2C电商产品，让用户买卖东西变得异常简单，同时也在计划接入去中心化电商商城。虚拟机也将继续进行功能升级，更加符合电商的需求。开源精神是项目之间互相协作，Uniswap是以太坊基金会的明星项目，我们将继续为其提供开发资源，共同发展。`,
        parent: 'true'
      },
      event6: {
        title: '电商DApp',
        list: {
          size: '4',
          item0: '实现去中心的担保交易',
          item1: '搭建CyberMiles 公链搜索引擎，链上买卖更方便',
          item2: '发布C2C电商产品，实现区块链电商零门槛',
          item3: '接入去中心化电商商城'
        }
      },
      event7: {
        title: '虚拟机功能升级',
        list: {
          size: '4',
          item0: '支持复杂数据类型的输入输出',
          item1: '支持 JSON',
          item2: '支持字符串函数库',
          item3: '支持智能合约形式化验证'
        }
      },
      event8: {
        title: '链上去中心化交易所Uniswap',
        list: {
          size: '2',
          item0: '继续为Uniswap提供核心开发资源，完成开发工作',
          item1: '正式在CM链上支持并运营'
        }
      },
      event9: {
        title: 'Q4',
        outline: `如果要为本季度的工作选一个主题词，那么”开源开放”应该是最合适的。本季度我们将发布第一个电商DApp开源标准，将技术贡献给社区。支持以太坊虚拟机，开放权益投票接口，开放CyberMiles App SDK，逐渐扩大技术生态。`,
        parent: 'true'
      },
      event10: {
        title: '电商DApp',
        list: {
          size: '2',
          item0: '形成去中心化电商合约与DApp的开源标准',
          item1: '支持更多去中心化电商平台接入CyberMiles公链'
        }
      },
      event11: {
        title: '虚拟机功能升级',
        list: {
          size: '2',
          item0: '支持Plasma等多个侧链标准',
          item1: '在业界率先支持以太坊2.0虚拟机eWASM标准'
        }
      },
      event12: {
        title: 'CyberMiles App',
        list: {
          size: '2',
          item0: '支持外部第三方钱包权益投票，开放接口',
          item1: '支持 CyberMiles App SDK 让第三方电商应用直接使用 CMT及稳定币',
        }
      }
    }
  }
}
