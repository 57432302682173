module.exports = {
  title: 'Marketing Referral Program',
  'meta-description': 'Marketing Referral Program',
  banner: {
    title: '마케팅 추천 프로그램',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '전통적인 전자 상거래 기회',
      description: {
        richText: 'true',
        description: `입소문 마케팅은 가장 효과적인 마케팅 전략 중 하나입니다. 소셜 미디어의 네트워킹 파워를 활용하여 Dropbox 및 Airbnb와 같은 많은 상장 기업은 추천 캠페인을 통해 대단한 성장을 이루었습니다.
이미 입증 된 마케팅 전략에 블록 체인의 힘을 추가하면 추천 프로그램이 더욱 효과를 발휘합니다 제한된 릴리스 블록 체인 토큰에서 인센티브 보상을 지급하면 사용자에게 네트워크 소유권 효과가 발생하여 경제적 이점이 두 배가됩니다.`,
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMiles 솔루션이란 무엇입니까?',
      description: {
        richText: 'true',
        description: `<p>귀사가 블록 체인 기반 비즈니스 이건 전통적인 웹 기반 애플리케이션 이건간에 CyberMiles의  전문적으로 호스팅된 DPOS 블록체인과 광범위한 스마트 계약 라이브러리를 활용하여 귀사의 추천 프로그램을 쉽게 만들고 게시 할 수 있습니다.</p>
        <p>사용자가 제품이나 서비스를 참조 할 때마다 그는 추가 토큰을받을뿐만 아니라 토큰에 대한 인지도를 높여 이전에 받은 토큰의 가치도 높입니다. 이 두 가지 경제적 인센티브가 귀사의 추천 프로그램에 탄력을 줍니다.</p>`,
      },
      number: '3',
      columns: '3',
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '1',
        button0: {
          label: '연락처',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_api',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: '플러그인과의 쉬운 통합',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_target',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: '다중 레벨 추천 추적',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_medal',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: '즉각적이고 계획된 행동 기반 보상',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
