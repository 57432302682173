module.exports = {
  title: 'CMT Overview',
  'meta-description': 'Our mission is to build a decentralized ecosystem for e-commerce. CyberMiles blockchain aims to be the public blockchain for all e-commerce transactions.',
  script: 'https://files.coinmarketcap.com/static/widget/currency.js',
  banner: {
    title: 'CyberMiles Token',
    backgroundImage: 'bannerCoin',
    description: '어떻게 운영되는가?',
    buttons: {
      number: '0',
    },
  },
  benefits: {
    number: '1',
    columns0: {
      title: 'CMT기술 장점',
      description: {
        richText: 'null',
        description:
          'null',
      },
      number: '4',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'smart',
            link: 'null',
          },
        },
        title: '스마트',
        description: {
          richText: 'null',
          description:
            'CyberMiles는 전자 상거래 애플리케이션을위한 스마트 계약 템플릿 라이브러리를 구축했습니다.',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'fast',
            link: 'null',
          },
        },
        title: '빠르다 ',
        description: {
          richText: 'null',
          description: '토큰 거래 속도만 빠른것 아니라,CyberMiles 가상머신은 비즈니스 요구에 따라 스마트 계약의 계산 속도를 최적화 시킬 수 있습니다.',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'safe',
            link: 'null',
          },
        },
        title: '안전하다 ',
        description: {
          richText: 'null',
          description:
            '블록체인은 확장성, 분산화 및 안전성의 완벽한 조화를 이루었습니다. 또한 CyberMiles는 추가 보호 수단으로 "사전 예방 "및 "추후 복구"메커니즘을 구현했습니다.',
        },
        buttons: {
          number: '0',
        },
      },
      column3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'free',
            link: 'null',
          },
        },
        title: '무료다 ',
        description: {
          richText: 'null',
          description:
            '네트워크의 안전성을 지키면서 대부분의 거래 수수료를 면제합니다',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },

  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '스테이킹으로 더 많은 CMT를 받기',
      description: {
        richText: 'null',
        description:
          'CyberMiles는 DPoS 프로토콜을 실행하여 CMT를 스테이킹함으로써  네트워크의 거버넌스에 참여할  수 있으며, 새로운 블록을 만들 때마다 체계적인 인플레이션과 거래 비용을 통해 CMT를 받게됩니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '2',
        button0: {          
          link: '/cmt/cmt-cube/',
          label: ' CMT Cube로 스테이킹',
          icon: 'null',
        },
        button1: {          
          link: '/cmt/validator/',
          label: '검증인 계획',
          icon: 'null',
        },
      },
    },
  },
  coins: {
    number: '1',
    coins0: {
      images: {
        number: '0',
      },
      title: 'CMT 시장 성과',
      description: {
        richText: 'null',
        description:
          'CMT 시장의 장기적인 건강과 안정성에 전념합니다.',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
      number: '3',
      columns:'3',
      column0: {
        images: {
          number: '0',
        },
        title: '시가 총액',
        description: {
          richText: 'null',
          description:
            'null',
        },
        buttons: {
          number: '0',
        },
        data: {
          key: "market_cap",
          unit: "USD",
        }
      },
      column1: {
        images: {
          number: '0',
        },
        title: '가격',
        description: {
          richText: 'null',
          description:
            'null',
        },
        buttons: {
          number: '0',
        },
        data: {
          key: "price",
          unit: "CMT/USD",
        }
      },
      column2: {
        images: {
          number: '0',
        },
        title: '볼륨 (24 시간)',
        description: {
          richText: 'null',
          description:
            'null',
        },
        buttons: {
          number: '0',
        },
        data: {
          key: "volume",
          unit: "USD",
        }
      }
    },
  },
  boxes: {
    number: '1',
    boxes0: {
      title: 'CMT구매',
      description: {
        richText: 'null',
        description: '이하 거래소에서 CMT를 구매와 거래가 가능합니다',
      },
      number: '12',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'lastSection',
      box0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'cobinhood',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://cobinhood.com/trade/CMT-BTC">BTC</a>, <a href="https://cobinhood.com/trade/CMT-ETH">ETH</a>, <a href="https://cobinhood.com/trade/CMT-USDT">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'binance',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.binance.com/trade.html?symbol=CMT_BTC">BTC</a>, <a href="https://www.binance.com/trade.html?symbol=CMT_ETH">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'lbank',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.lbank.info/exchange.html?asset=cmt&post=btc">BTC</a>, <a href="https://www.lbank.info/exchange.html?asset=cmt&post=eth">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'huobi',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.hbg.com/zh-cn/cmt_btc/exchange/">BTC</a>, <a href="https://www.hbg.com/zh-cn/cmt_eth/exchange/">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box4: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'coinbene',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.coinbene.com/#/?pairId=36">ETH</a>, <a href="https://www.coinbene.com/#/?pairId=24">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box5: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'dragonex',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://dragonex.io/trade/index/cmt_eth">ETH</a>, <a href="https://dragonex.io/zh-hant/trade/index/cmt_usdt">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box6: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'bibox',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.bibox.com/exchange?coinPair=CMT_BTC">BTC</a>, <a href="https://www.bibox.com/exchange?coinPair=CMT_ETH">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box7: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'koinex',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://koinex.in/exchange/inr/cyber_miles">INR</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box8: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'okex',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.okex.com/spot/trade#product=cmt_btc">BTC</a>, <a href="https://www.okex.com/spot/trade#product=cmt_eth">ETH</a>, <a href="https://www.okex.com/spot/trade#product=cmt_usdt">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box9: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'coincoming',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://trade.coincoming.com/deal/pDealcent?coinid=10038">BTC</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box10: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'bithumb',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://en.bithumb.com/trade/order/CMT">KRW</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box11: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'changenow',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://changenow.io/ko/exchange?amount=1&to=cmt">BTC</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
