module.exports = {
  title: 'CMT Overview',
  'meta-description': 'Our mission is to build a decentralized ecosystem for e-commerce. CyberMiles blockchain aims to be the public blockchain for all e-commerce transactions.',
  script: 'https://files.coinmarketcap.com/static/widget/currency.js',
  banner: {
    title: 'CMT 概要',
    backgroundImage: 'bannerCoin',
    description: 'どのような機能を備えているか？',
    buttons: {
      number: '0',
    },
  },
  benefits: {
    number: '1',
    columns0: {
      title: 'CMT技術のメリット',
      description: {
        richText: 'null',
        description:
          'null',
      },
      number: '4',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'smart',
            link: 'null',
          },
        },
        title: 'スマート',
        description: {
          richText: 'null',
          description:
            'CyberMilesは、eコマースアプリケーションにおけるスマートコントラクトの基本ライブラリを構築しました。',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'fast',
            link: 'null',
          },
        },
        title: '高速',
        description: {
          richText: 'null',
          description: '通貨の取引のスピードに加えて、仮想マシンはスマートコントラクトの処理速度が速く、ビジネスに最適です。',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'safe',
            link: 'null',
          },
        },
        title: '安全',
        description: {
          richText: 'null',
          description:
            'ブロックチェーンには、スケーラビリティ、分散化、および安全性が備わっています。 また、CyberMilesは「pre-defense」および「post recovery」のメカニズムを追加実装することにより安全性を強化しました。',
        },
        buttons: {
          number: '0',
        },
      },
      column3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'free',
            link: 'null',
          },
        },
        title: '無料',
        description: {
          richText: 'null',
          description:
            'CyberMilesは、ネットワークのセキュリティを保ちつつ、最も基本的な利用方法に対しての取引手数料を免除します。',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },

  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: 'CMTの獲得方法',
      description: {
        richText: 'null',
        description:
          'CyberMilesはDPoSプロトコルを実行します。このプロトコルでは、CMTをネットワークのガバナンスに参加させる代わりに、生成された新しいブロックごとに、体系的なインフレおよび取引手数料を通じてCMTを得ることができます。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '2',
        button0: {          
          link: '/cmt/cmt-cube/',
          label: 'DデリゲートにCMTの獲得',
          icon: 'null',
        },
        button1: {          
          link: '/cmt/validator/',
          label: 'バリデータプログラム',
          icon: 'null',
        },
      },
    },
  },
  coins: {
    number: '1',
    coins0: {
      images: {
        number: '0',
      },
      title: 'CMTの市場動向',
      description: {
        richText: 'null',
        description:
          '私達はCMT市場の長期的な健全性と安定性に力を尽くしています。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
      number: '3',
      columns:'3',
      column0: {
        images: {
          number: '0',
        },
        title: 'マーケットキャップ',
        description: {
          richText: 'null',
          description:
            'null',
        },
        buttons: {
          number: '0',
        },
        data: {
          key: "market_cap",
          unit: "USD",
        }
      },
      column1: {
        images: {
          number: '0',
        },
        title: '価格',
        description: {
          richText: 'null',
          description:
            'null',
        },
        buttons: {
          number: '0',
        },
        data: {
          key: "price",
          unit: "CMT/USD",
        }
      },
      column2: {
        images: {
          number: '0',
        },
        title: '取引量（24時間）',
        description: {
          richText: 'null',
          description:
            'null',
        },
        buttons: {
          number: '0',
        },
        data: {
          key: "volume",
          unit: "USD",
        }
      }
    },
  },
  boxes: {
    number: '1',
    boxes0: {
      title: 'CMTを購入する',
      description: {
        richText: 'null',
        description: 'CMTは次の取引所で入手することができます。',
      },
      number: '12',
      columns: '4',
      buttons: {
        number: '0',
      },
      backgroundImage: 'lastSection',
      box0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'cobinhood',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://cobinhood.com/trade/CMT-BTC">BTC</a>, <a href="https://cobinhood.com/trade/CMT-ETH">ETH</a>, <a href="https://cobinhood.com/trade/CMT-USDT">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'binance',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.binance.com/trade.html?symbol=CMT_BTC">BTC</a>, <a href="https://www.binance.com/trade.html?symbol=CMT_ETH">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'lbank',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.lbank.info/exchange.html?asset=cmt&post=btc">BTC</a>, <a href="https://www.lbank.info/exchange.html?asset=cmt&post=eth">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'huobi',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.hbg.com/zh-cn/cmt_btc/exchange/">BTC</a>, <a href="https://www.hbg.com/zh-cn/cmt_eth/exchange/">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box4: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'coinbene',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.coinbene.com/#/?pairId=36">ETH</a>, <a href="https://www.coinbene.com/#/?pairId=24">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box5: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'dragonex',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://dragonex.io/trade/index/cmt_eth">ETH</a>, <a href="https://dragonex.io/zh-hant/trade/index/cmt_usdt">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box6: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'bibox',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.bibox.com/exchange?coinPair=CMT_BTC">BTC</a>, <a href="https://www.bibox.com/exchange?coinPair=CMT_ETH">ETH</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box7: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'koinex',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://koinex.in/exchange/inr/cyber_miles">INR</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box8: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'okex',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://www.okex.com/spot/trade#product=cmt_btc">BTC</a>, <a href="https://www.okex.com/spot/trade#product=cmt_eth">ETH</a>, <a href="https://www.okex.com/spot/trade#product=cmt_usdt">USDT</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box9: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'coincoming',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://trade.coincoming.com/deal/pDealcent?coinid=10038">BTC</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box10: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'bithumb',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://en.bithumb.com/trade/order/CMT">KRW</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
      box11: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'changenow',
            link: 'null',
          },
        },
        title: 'null',
        description: {
          richText: 'true',
          description:
            '<p>CMT/<a href="https://changenow.io/ja/exchange?amount=1&to=cmt">BTC</a></p>',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
