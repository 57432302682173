module.exports = {
  title: 'CyberMiles 客户端',
  'meta-description': '移动区块链钱包',
  banner: {
    title: '数字资产钱包',
    backgroundImage: 'bannerCMT',
    description: '移动区块链钱包',
    buttons: {
      number: '0',
    },
  },
  imageTextHero: {
    section0: {
      image: {
        gif: 'null',
        path: 'cmtWalletOne',
      },
      title: '获取移动区块链钱包',
      description: {
        richtext: 'null',
        description: '通过以下方式下载：',
      },
      backgroundImage: 'null',
      imageButtons: {
        number: '3',
        button0: {
          icon: 'appStore',
          link: 'https://itunes.apple.com/us/app/id1418378426',
          qr: 'iosWalletQR',
          qrText: '使用手机浏览器扫描二维码下载',
        },
        button1: {
          icon: 'googlePlay',
          link: 'https://play.google.com/store/apps/details?id=io.cybermiles.cmtwallet',
          qr: 'androidWalletQR',
          qrText: '使用手机浏览器扫描二维码下载',
        },
        button2: {
          icon: 'yingyongbao',
          link: 'https://sj.qq.com/myapp/detail.htm?apkName=io.cybermiles.cmtwallet',
          qr: 'androidWalletQR',
          qrText: '使用手机浏览器扫描二维码下载',
        },
      },
      
      buttons: {
        number: '2',
        button0: {
          label: 'Android 下载',
          link: 'https://s3.cn-north-1.amazonaws.com.cn/cmtwallet/android/CMT-Wallet-Android.apk',
        },
        button1: {
          label: 'IOS 下载',
          link: 'itms-services://?action=download-manifest&url=https://s3.cn-north-1.amazonaws.com.cn/cmtwallet/ios/manifest.plist',
        },
      },
    },
  },
  imageText: {
    section0: {
      image: {
        gif: 'null',
        path: 'cmtWalletTwo',
      },
      title: '安全，方便，功能强大',
      description: {
        richtext: 'null',
        description:
          'CyberMiles APP 致力于为用户提供简单，安全和强大的数字资产管理工具。 用户可以体验自由地创建/导入钱包，安全的数字资产管理，快速的交易执行，实时市场更新，以及探索CyberMiles DApps的潜力。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  features: {
    number: '1',
    columns0: {
      title: '优势特征',
      description: {
        richText: 'null',
        description:
          'null',
      },
      number: '4',
      columns: '2',
      buttons: {
        number: '0',
      },
      backgroundImage: 'null',
      column0: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresKey',
            link: 'null',
          },
        },
        title: '自持私钥，增强安全性',
        description: {
          richText: 'null',
          description:
            '加密的私钥安全地存储在CyberMiles APP的本地沙箱系统中。 提供多个钱包备份选项，以防止资产丢失或被盗。',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresPhone',
            link: 'null',
          },
        },
        title: '简单易用，交易方便',
        description: {
          richText: 'null',
          description: '执行交易到账迅速，免gas 费',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresWorld',
            link: 'null',
          },
        },
        title: '强大的DApp浏览器',
        description: {
          richText: 'null',
          description:
            '采用智能合约技术构建分散/分布式应用程序浏览器。 支持像FairPlay / WeBet / Declare your love / CMT Tracking等DApp。',
        },
        buttons: {
          number: '0',
        },
      },
      column3: {
        images: {
          number: '1',
          image0: {
            gif: 'null',
            path: 'featuresLock',
            link: 'null',
          },
        },
        title: '一站式数字资产管理',
        description: {
          richText: 'null',
          description:
            '可定制包含CMT在内的资产列表。CMT实时市场和资产价值更新。支持从imToken，JAXX，Metamask，CMT Cube导入钱包。 价格与Coinbase，Binance，Huobi，OKex，Bittrex，Kraken，Poloniex等全球主要交易所同步。',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: 'CMT (CyberMiles Token)',
      description: {
        richText: 'true',
        description:
          'CMT的主要目的之一是激励人们共同维护CyberMiles网络。 由于CyberMiles公链专门针对电子商务应用进行了优化，因此CMT的另一个主要功能是解决商业交易问题。 CMT可以在所有主流交易所上交易，包括Huobi，Okex，Binance等等。',
      },
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '1',
        button0: {
          link: '/cmt/overview/',
          label: '了解更多',
          icon: 'null',
        },
      },
    },
  },
}
