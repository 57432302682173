module.exports = {
  title: 'Blog',
  'meta-description': 'Stay tuned and read the latest Cybermiles feature updates, official announcements, blockchain news.',
  banner: {
    title: 'Blog',
    backgroundImage: 'bannerGlobe',
    description:
      'null',
    buttons: {
      number: '0',
    }
  },
}
