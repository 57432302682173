module.exports = {
  title: 'User Engagement Solutions',
  'meta-description': 'User Engagement Solutions',
  banner: {
    title: '사용자 참여 솔루션',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '전통적인 전자 상거래를위한 도전 과제',
      description: {
        richText: 'true',
        description:
          `<p>오늘날의 디지털 비즈니스 환경에서 가장 큰 문제 중 하나는 사용자층의 참여와 관심을 유지하는 것입니다. 블록체인 기반 솔루션에 대한 논의와는 별개로 고객의 브랜드 및 제품에 대한 참여가 비즈니스에 큰 영향을 줍니다.</p>
          <p>블록체인 토큰 기반의 바운티 및 충성도 프로그램은 견인력을 얻고 초기 성장을 유도할 수 있는 강력한 방법입니다. 성장을 위해 원하는 사용자 행동을 직접 유도할 수 있습니다
성공적인 성장의 핵심은 실행입니다.</p>
          <p>보상을 계획, 정확하게 추적 및 배포하는 것은 모든 조직에서 압도적이며 자원 집약적 인 작업이 될 수 있습니다.</p>`,
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMiles는 어떻게 해결하는가?',
      description: {
        richText: 'null',
        description:
          '안전하고 전문적으로 호스팅된 견고한 DPOS 블록체인 및 포괄적인 스마트 계약 템플릿 라이브러리를 사용하면 효과적인 현상금 및 충성도 캠페인을 신속하게 구축하고 실행할 수 있으며 브랜드 및 제품에 대한 활발한 커뮤니티를 구축 할 수 있습니다. 사용자에게 플랫폼 홍보 및 더 많은 콘텐츠 제작에 대한 인센티브를 쉽게 부여 할 수 있습니다.'
      },
      number: '3',
      columns: '3',
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '1',
        button0: {
          label: '연락처',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_stats',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '협업을 정확하게 모니터하고 보상함',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_bookmark',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            '엄청난 작업 자동화',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_api',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description:
            'API 및 플러그인과의 쉬운 통합',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
