module.exports = {
  title: 'CyberMiles验证人计划',
  'meta-description': '验证人计划',
  banner: {
    title: '验证人计划',
    backgroundImage: 'bannerCoin',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '4',
    centered0: {
      images: {
        number: '0',
      },
      title: '验证人',
      description: {
        richText: 'null',
        description:
          '验证人负责维护重要网络基础设施，并代表权益投票人制定变更规则和治理决策。由于CyberMiles公链系统中只有19个验证人，因此验证人必须通过竞争获取权益投票人的选票（即投票的CMT）。这些竞争包括提供能够运行最新的软件的安全服务器，提供足够的计算能力和网络带宽，为CyberMiles公链的全球性区块链网络提供动力。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
    centered1: {
      images: {
        number: '0',
      },
      title: '权益投票人',
      description: {
        richText: 'null',
        description:
          '权益投票人自己通常没有能力或者不愿意运行真正为区块链网络提供动力的计算机服务器。因此，权益投票人通过将CMT 投票给具体的验证人候选人，来雇佣，即选举，其他CMT 持有者，即验证人。验证人负责维护重要网络基础设施，并代表权益投票人制定变更规则和治理决策。在CyberMiles网络正式运营的第一年，只有拥有CMT Cube的人才能权益投票，使用权益投票得到的区块奖励来雇佣验证人。',
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
    centered2: {
      images: {
        number: '0',
      },
      title: 'CyberMiles App',
      description: {
        richText: 'true',
        description:
          `用户可以通过<a href="/blockchain-infrastructure/cybermiles-app/">CyberMiles App</a>给某个超级节点权益投票（Satke），来获得区块奖励。根据现在全网权益投票情况，现在stake 10,000 CMT，报酬比率为30%的情况下，通过Cybermiles APP 权益投票一年，年收益为15%。（该数据统计自区块高度1,948,355）\
          <p>*同等条件下，同等条件下，通过CMT Cube 权益投票比CyberMiles App 的收益高20%</p>`
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
    centered3: {
      images: {
        number: '0',
      },
      title: 'CMT Cube',
      description: {
        richText: 'null',
        description:
          'CMT Cube是一个家庭使用的设备。在CyberMiles网络运行的第一年，其专门设计用于对CyberMiles网络运营进行验证。这是一个硬件设备，同时扮演着CMT钱包(即存储和管理一个CMT帐户)，以及权益投票CMT以选择验证人（验证节点）的角色。用户界面（UI）非常容易使用，显示最新的收益(权益投票人的区块奖励)。家庭硬件钱包比基于网络或基于手机的软件钱包要安全许多，因此它是保持和存储大量CMT的理想选择。',
      },
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '2',
        button0: {
          link: '/cmt/whitepaper/',
          label: 'Cybermiles DPoS Protocol',
          icon: 'null',
        },
        button1: {
          link: 'https://goo.gl/forms/I3cYxGLdJLpX14Hz2',
          label: '申请节点竞选人',
          icon: 'null',
        },
      },
    },
  },
  accordions: {
    number: '1',
    accordion0: {
      images: {
        number: '0',
      },
      title: 'FAQ',
      description: {
        richText: 'null',
        description: 'null',
      },
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '0',
      },
      number: '11',
      drawer0: {
        title: 'What are the benefits to become a CyberMiles’ Validator?',
        description: {
          richText: 'true',
          description:
            '<p>Delegators pay validators with a portion of their block awards. Each validator’s share of the block award is called the validator’s compensation. A validator’s compensation is determined at the time of staking. It is recorded and enforced by the network itself.</p><p>The compensation rate is set by the validator himself, directly in competition with the others”. Last but not least, validators participate in the network governance.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer1: {
        title: 'What are the requirements to become a Validator?',
        description: {
          richText: 'true',
          description:
            '<p>The validators need to run super nodes, which are professionally operated by network servers to ensure the performance and security of the blockchain network.</p><p>In addition to network servers, upon a new candidate declaration, the network immediately takes 10% of the declared maximum amount of stake from the candidate’s own account, and keep it as stake.</p><p>Since there are only 19 validators in the CyberMiles system, validators must compete for delegators’ votes (staked CMTs) in order to be hired by providing secure servers running up-to-date software, sufficient computing power and network bandwidth to power the global blockchain network.</p><p>However, CyberMiles Foundation will also give an additional certification to the candidates that we judge are trustworthy to become one of the 19 Validators.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer2: {
        title: 'How to be a certified candidate for the Validator election?',
        description: {
          richText: 'true',
          description:
            '<p>A validator candidate declares its candidacy to the network. It will give out 3 pieces of information in the declaration:</p><ol><li>Details of its operation and credentials, including jurisdiction, data center location, security setup, and technical setup. The CyberMiles Foundation will evaluate all validator candidates, and publicly indicate the candidates that meet the Foundation’s standards (i.e., information accuracy, technical competency, and hardware / network setup). However, the token holders are free to stake for any candidate regardless whether it meets the Foundation standard or not.</li><li>The maximum amount of CMTs it is willing to accept as stakes. In order to prevent any single validator from growing too big and therefore risking monopoly, the network protocol punishes delegators who stake in very large validators. (see the “B4. Block award and validator’s compensation” section for details)</li><li>The validator’s compensation rate it requires from delegators. For example, a 40% rate means that 40% of the block awards earned by delegators who staked this validator, will be paid to this validator as compensation.</li></ol><p>The CyberMiles Foundation will review the candidate’s information. If the foundation can verify the accuracy of the information, it will denote on-chain that the candidate is “verified”. CMT holders can stake any validator candidate they like, including the unverified ones, but verified candidates from the foundation give CMT holders more confidence to stake.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer3: {
        title: 'Why did CyberMiles choose staking as a voting mechanism?',
        description: {
          richText: 'true',
          description:
            '<p>Once the validator candidate receives enough stake to make the top 19, it becomes a validator, and all its delegators start to receive block award (about every 10 seconds). The block award includes two parts:</p><ol><li>The system has an annual inflation rate of 8%. This inflation is distributed as newly minted CMTs to delegators.</li><li>The delegators also receive transaction fees paid by heavy users of the blockchain.</li></ol><p>The total block award from the system is first assigned to the delegators proportional to each delegator’s stake. The system then automatically distributes the block award to the validators and delegators based on the validator’s compensation rates.</p><p>From a validator side, he will receive block awards from two sources: the self-staked CMTs by validating blocks and its compensation from delegators for maintaining the network.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer4: {
        title: 'Who are the candidates so far?',
        description: {
          richText: 'true',
          description:
            '<p>Already a dozen of high-profile candidates have announced their interests to become a Validator. To name a few: Susquehanna International Group, Great Voyage Capital, LinkVC, AlphaCoin Fund, Shuanghua Capital, IDG Capital.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer5: {
        title: 'What will happen when I stake and unstake my CMTs?',
        description: {
          richText: 'true',
          description:
            '<p>CMT holders (delegators) stake their CMTs to the validator candidate. It is important to note that staked CMTs cannot be traded, and once staked, a delegator must request unstaking and then wait a week before he can trade them again.</p><p>The waiting period of unstaking is to guard against something called “long range double spend attack”.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer6: {
        title: 'What if some Validators misbehave?',
        description: {
          richText: 'true',
          description:
            '<p>When a validator becomes unavailable or produces results that are different from the rest of the validators, the system will slash and burn 0.1% of its total stake (i.e., the validator itself and all its delegators will lose CMTs) every block (every 10s). After 12 consecutive slashes, the system removes the validator and promotes the next validator candidate as a validator.</p><p>The removed validator will no longer suffer slashing loss, but none of its delegators will earn any block awards either. Its delegator can request to unstake from it, and then re-stake their CMTs after the one week unstake waiting period.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer7: {
        title:
          'How do you prevent a potential attack on your blockchain from Validators (eg. 51% attack) ?',
        description: {
          richText: 'true',
          description:
            '<p>The CyberMiles protocol disincentives any validator from growing too big. If a single validator’s stake grows above 12% of total stake of the network, this validator could cause instability in the network. So, when the network assigns the block award, all its delegators will receive reduced block award for stakes that goes above the 12% threshold. This threshold incentivizes large CMT holders to diversify and stake in multiple validators, and it incentives validators to limit their max accepted stake. Algorithm to compute voting power for each validator is as follows.</p><ol><li>Distribute voting power to validators according to each validator’s stake amount, but capped at 12% for each. For example, if validator A has 5% of the outstanding stake, he gets 5% voting power; if validator B has 20% of the stake, he gets 12% voting power.</li><li>If there is remaining voting power, distribute a second round based on actual stake amount of each validator.</li></ol>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer8: {
        title:
          'In the case that the system rejects a validator, what will happen next?',
        description: {
          richText: 'true',
          description:
            '<p>Beyond the 19 official validators, there are validator candidates whose staked CMTs are less than the 19th validator. They are backup validators. There are always possibilities for the backup to accumulate enough stakes in the future to overtake the 19th validator. An existing validator might also be punished or simply withdraw, or stop functioning as a validator completely. In those cases, the backup becomes a validator, and the previous second candidate in line becomes the backup.</p><p>Backup validators do not earn CMT awards for the CMTs staked in them. They are typically new entrants into the ecosystem or opportunistic players. The Foundation will run multiple backup validator candidates in order to maintain the network stability.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer9: {
        title: 'How many CMT can a CMT Cube hold?',
        description: {
          richText: 'true',
          description:
            '<p>One CMT Cube can hold up to 100,000 CMTs and the minimum amount is 1,000 CMTs.</p>',
        },
        buttons: {
          number: '0',
        },
      },
      drawer10: {
        title: 'Where can I get a CMT Cube?',
        description: {
          richText: 'true',
          description:
            '<p>The CMT Cube can be purchased <a href="https://www.blocktonic.io/item/583" style="color: blue">here</a> at 998 CMT starting on May 31. However, CyberMiles Foundation will refund 100% of the cost within 300 days.</p>',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
