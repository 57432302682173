export default {
  title: 'Header',
  items: [
    {
      label: 'CMT',
      link: '#',
      children: [
        {
          disabled: 'false',
          label: 'Overview',
          link: 'cmt/overview/',
          children: [],
        },
        {
          disabled: 'false',
          label: 'CMT Cube',
          link: 'cmt/cmt-cube/',
          children: [],
        },
        {
          disabled: 'false',
          label: 'Validator',
          link: 'cmt/validator/',
          children: [],
        },
        {
          disabled: 'false',
          label: 'Whitepaper',
          link: 'cmt/whitepaper/',
          children: [],
        },
      ],
    },
    {
      label: 'E-commerce Solutions',
      link: '#',
      children: [
        {
          label: 'Finance',
          link: '#',
          children: [
            {
              disabled: 'false',
              label: 'Payment Gateway',
              link: 'ecommerce-solutions/finance/payment-gateway/',
              children: [],
            },
            {
              disabled: 'false',
              label: 'Security Token',
              link: 'ecommerce-solutions/finance/security-token/',
              children: [],
            },
            {
              disabled: 'false',
              label: 'Stable Coin',
              link: 'ecommerce-solutions/finance/stable-coin/',
              children: [],
            },
          ],
        },
        {
          label: 'Marketing',
          link: '#',
          children: [
            {
              disabled: 'false',
              label: 'Marketing Referral Program',
              link: 'ecommerce-solutions/marketing/marketing-referral-program/',
              children: [],
            },
            {
              disabled: 'false',
              label: 'User Engagement Solutions',
              link: 'ecommerce-solutions/marketing/user-engagement-solutions/',
              children: [],
            },
          ],
        },
        // {
        //   label: 'Governance',
        //   link: '#',
        //   children: [
        //     {
        //       disabled: 'true',
        //       label: 'Public Witness',
        //       link: 'ecommerce-solutions/governance/public-witness/',
        //       children: [],
        //     },
        //     {
        //       disabled: 'true',
        //       label: 'Evidence Capture',
        //       link: 'ecommerce-solutions/governance/evidence-capture/',
        //       children: [],
        //     },
        //     {
        //       disabled: 'true',
        //       label: 'Provenance',
        //       link: 'ecommerce-solutions/governance/provenance/',
        //       children: [],
        //     },
        //     {
        //       disabled: 'true',
        //       label: 'Data Marketplace',
        //       link: 'ecommerce-solutions/governance/data-marketplace/',
        //       children: [],
        //     },
        //     {
        //       disabled: 'true',
        //       label: 'Community Governance',
        //       link: 'ecommerce-solutions/governance/community-governance/',
        //       children: [],
        //     },
        //   ],
        // },
      ],
    },
    {
      label: 'Blockchain Infrastructure',
      link: '#',
      children: [
        {
          disabled: 'false',
          label: 'CMT Tracking',
          link: 'https://www.cmttracking.io/',
          children: [],
        },
        // {
        //   disabled: 'false',
        //   label: 'CMT TestNet',
        //   link: 'https://testnet.cmttracking.io/',
        //   children: [],
        // },
        {
          disabled: 'false',
          label: 'CyberMiles APP',
          link: 'https://app.cybermiles.io',
          children: [],
        },
        {
          disabled: 'false',
          label: 'Venus',
          link: 'blockchain-infrastructure/venus/',
          children: [],
        },
        {
          disabled: 'false',
          label: 'Europa',
          link: ' http://europa.cybermiles.io',
          children: [],
        },
      ],
    },
    {
      label: 'Developer Portal',
      link: '#',
      children: [
        {
          disabled: 'false',
          label: 'Developer Hub',
          link: 'developer-portal/developer-hub',
          children: [],
        },
        // {
        //   label: 'Documentation',
        //   link: '#',
        //   children: [
        //     {
        //       disabled: 'true',
        //       label: 'Technical Benchmark',
        //       link: 'developer-portal/documentation/technical-benchmark/',
        //       children: [],
        //     },
        //   ],
        // },
      ],
    },
    {
      label: 'About Us',
      link: '#',
      children: [
        {
          disabled: 'false',
          label: 'Team',
          link: 'about-us/team/',
          children: [],
        },
        // {
        //   disabled: 'true',
        //   label: 'FAQ',
        //   link: 'about-us/faq/',
        //   children: [],
        // },
        {
          disabled: 'false',
          label: 'Blog',
          link: 'https://blog.cybermiles.io/categories/en/',
          children: [],
        },
        {
          disabled: 'false',
          label: 'Roadmap',
          link: 'about-us/roadmap/',
          children: [],
        },
        {
          disabled: 'false',
          label: 'Contact us',
          link: 'about-us/contact-us/',
          children: [],
        },
      ],
    },
  ],
}
