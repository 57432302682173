module.exports = {
  title: '营销推荐机制',
  'meta-description': '营销推荐机制',
  banner: {
    title: '营销推荐机制',
    backgroundImage: 'bannerCube',
    description: 'null',
    buttons: {
      number: '0',
    },
  },
  centered: {
    number: '1',
    centered0: {
      images: {
        number: '0',
      },
      title: '传统电商的机会',
      description: {
        richText: 'true',
        description: `<p>口碑营销是最有效的营销策略之一。利用社交媒体的网络力量，Dropbox和Airbnb等许多标志性公司通过推荐活动实现了双曲线增长。</p>
          <p>将区块链的强大功能添加到这种经过验证的营销策略中，可以将推荐计划提升到全新的水平。当激励奖励以基于区块链发行的有限token支付时，可以为用户释放网络的所有权效应，这是双重经济激励。</p>
         `,
      },
      backgroundImage: 'null',
      buttons: {
        number: '0',
      },
    },
  },
  solutions: {
    number: '1',
    columns0: {
      title: 'CyberMiles解决方案',
      description: {
        richText: 'true',
        description: `<p>无论是基于区块链还是传统的基于互联网的应用程序，都可以通过CyberMiles公链企业级专业托管的DPoS区块链以及广泛的智能合约库，轻松创建和发布自己的推荐机制。</p>
        <p>每当用户推荐您的产品或服务时，不仅会收到额外的token，还会提升对token的认知，从而增加用户之前收到的token的价值。 这种双重经济激励措施可以大大提高推荐机制的效力。</p>
       `,
      },
      number: '3',
      columns: '3',
      backgroundImage: 'shortLastSection',
      buttons: {
        number: '1',
        button0: {
          label: '联系我们',
          link: '/about-us/contact-us',
          icon: 'null',
        },
      },
      column0: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_api',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: 'API及插件易于集成',
        },
        buttons: {
          number: '0',
        },
      },
      column1: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_target',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: '追踪多级推荐',
        },
        buttons: {
          number: '0',
        },
      },
      column2: {
        images: {
          number: '1',
          image0: {
            path: 'gateway_medal',
            gif: 'null',
            link: 'null',
          },
        },
        title: 'null',
        backgroundImage: 'null',
        description: {
          richText: 'null',
          description: '基于即时和规定行为的奖励',
        },
        buttons: {
          number: '0',
        },
      },
    },
  },
}
